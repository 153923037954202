import * as React from "react";
import {
  getDate,
  getProjectDuration,
  getProjectDate,
} from "../../utils/Helper";
import { PencilFill, Trash } from "react-bootstrap-icons";
import { ListGroupItem, Collapse } from "react-bootstrap";
import "./Career.scss";
import { removeHTML } from "../../utils/Helper";
import EditJob from "./EditJob";
import DeleteJob from "./DeleteJob";
import SearchRedirect from '../../components/SearchRedirect';

const Job = (props) => {
  const [open, setOpen] = React.useState(false);
  const [job, setJob] = React.useState(props.data);
  const [showModal, setShowModal] = React.useState(false);
  const [showEnsureDialog, setShowEnsureDialog] = React.useState(false);
  const handleUpdate = React.useCallback(
    (val) => {
      setJob(val);     
    },
    [job]
  );   
  return (
    job && (
      <>
        <ListGroupItem key={job.id}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="subheaderNoPadding" >
             <SearchRedirect
                index={1}
                searchKey={'WorkProjectRoleTitle'}
                keyword={job.jobTitle}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                isTagSingle={true}    
              />
            </div>
            {props.edit && (
              <div className="job-actions-container">
                <PencilFill
                  className="icon-default"
                  onClick={() => setShowModal(!showModal)}
                />
                <Trash
                  className="icon-remove"
                  onClick={() => setShowEnsureDialog(!showEnsureDialog)}
                />
              </div>
            )}
          </div>
          <p style={{ marginTop:"8px",marginBottom:"8px"}}>
            {job.company && (
             <SearchRedirect
                index={1}
                searchKey={'WorkProjectTitle'}
                keyword={job.company}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                isTagSingle={false}    
              /> 
            )}   
            {getDate(job.startDate) !== "Invalid Date" &&
              `${getDate(job.startDate)} ${
                 (getDate(job.endDate) !== "Invalid Date" && getDate(job.endDate)) ? `- ${getDate(job.endDate)}` : ` `
              }`
            }            
          </p>
          {job.asset.length ||
          job.location.length ||
          job.capitalProject.length||job.role.length ? (
            <p>
              <span style={{ marginRight: "1rem" }}>
                {job.asset &&(
                  <SearchRedirect
                    index={1}
                    searchKey={'WorkAsset'}
                    keyword={job.asset}
                    redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                    isTagSingle={false}    
                  /> 
                )}
                {job.role&&(
                  <SearchRedirect
                    index={1}
                    searchKey={'WorkProjectPivotalRole'}
                    keyword={job.role}
                    redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                    isTagSingle={false}    
                  /> 
                )}               
                {job.location && (
                  <SearchRedirect
                    index={1}
                    searchKey={'WorkProjectLocation'}
                    keyword={job.location}
                    redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                    isTagSingle={false}    
                  />
                )}
              </span>
              {job.capitalProject.length ? (
                <span className="project-badge">
                  Capital Project : 
                  <strong>
                    <SearchRedirect
                      index={1}
                      searchKey={'WorkCapitalProject'}
                      keyword={job.capitalProject}
                      redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                      isTagSingle={true}    
                    />
                  </strong>
                </span>
              ) : (
                ``
              )}
            </p>
          ) : null}
          {removeHTML(job.description).length ? (
            <>
              <Collapse in={open}>
                <div id={job.id}>{removeHTML(job.description)}</div>
              </Collapse>
              <a className="showLink"                
                onClick={() => setOpen(!open)}
              >
                {!open ? "View description" : "Hide description"}
              </a>
            </>
          ) : null}
        </ListGroupItem>
        {showModal && (
          <EditJob
            props={job}
            show={showModal}
            setShow={setShowModal}
            onUpdate={handleUpdate} 
            onSort={props.sortHandler}           
            isCurrentJob={false}
          />
        )}

        {showEnsureDialog && (
          <DeleteJob
            props={job}
            show={showEnsureDialog}
            setShow={setShowEnsureDialog}
            onUpdate={props.deleteHandler}
          />
        )}
      </>
    )
  );
};

export default Job;
