import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

import ViewCard from "../../components/ViewCard/ViewCard";
import Edit from "./EditAskMeAbout";
import SearchRedirect from '../../components/SearchRedirect';
/** Bootstrap Presentation */
import "../../components/ViewCard/ViewCard.scss";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Utils */
import helper from "../../utils/Helper";

export const Askmeabout: React.FC<ICardComponentProps> = ({
  header,
  tooltip,
  edit,
  percentage
}) => {
  const { state, dispatch } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(
    [EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout],
    userProps
  );
  const [showModal, setShowModal] = React.useState(false);
  const [askMeAbout, setAskMeAbout] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [askMeAbout]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setAskMeAbout(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const handleUpdate = React.useCallback(
    (val) => {
      setAskMeAbout(val);
    },
    [askMeAbout]
  );

  const updateSection = () => {
    !askMeAbout[EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout] &&
    askMeAbout[EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout] === "" &&
    !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
          type: "ADD_SECTION",
          payload: {
            title: `${header}`,
            showPercentage:true,
            handler: () => setShowModal(!showModal),
          },
        })
      : askMeAbout[EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout] &&
        askMeAbout[EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout] !== "" &&
        dispatch({
          type: "REMOVE_SECTION",
          payload: { 
            title: `${header}`, 
            showPercentage:false,
          },
        });
  };

  const main: JSX.Element = askMeAbout &&
    askMeAbout[EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout] &&
    askMeAbout[EDIT_CARD_PROPS.AskMeAboutCard.askMeAbout] !== "" && (
      <>
        {askMeAbout["SPS-Responsibility"].split("|").map((askAbt, i) => (         
          <SearchRedirect
            index={i}
            searchKey={'Responsibilities'}
            keyword={askAbt}
            redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
            isTagSingle={false}    
          />
        ))}
      </>
    );

  return showModal ? (
    <Edit
      header={header}
      props={askMeAbout}
      show={showModal}
      setShow={setShowModal}
      onUpdate={handleUpdate}
    />
  ) : main ? (   
      <ViewCard
        header={header}
        body={main}
        edit={edit}
        toolTip={tooltip}
        loading={loading}
        editHandler={() => setShowModal(!showModal)}
        percentage={percentage}
      />
  ) : null;
};

export default Askmeabout;
