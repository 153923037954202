import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";




export const ToolTip: React.FC<{
  tooltipMessage: string | React.ReactNode | null;
}> = ({ tooltipMessage }) => (
  <OverlayTrigger
    placement="right-start"
    delay={{ show: 250, hide: 1000 }}
    overlay={
      <Tooltip
        id="info-tooltip"
        style={{
          maxWidth: "20em",
          whiteSpace: "normal",
          wordBreak: "break-word",         
         
        }}
      >
        {tooltipMessage}
      </Tooltip>
    }
  >
    <InfoCircle className="info-circle-tooltip-icon" />
  </OverlayTrigger>
);

export default ToolTip;
