import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

import ViewCard from "../../components/ViewCard/ViewCard";
import Edit from "./EditAccreditation";
import SearchRedirect from '../../components/SearchRedirect';
/** Bootstrap Presentation */
import "../../components/ViewCard/ViewCard.scss";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

/** Utils */
import helper from "../../utils/Helper";

export const Accreditation: React.FC<ICardComponentProps> = ({
  header,
  tooltip,
  edit,
  percentage
}) => {
  const { state, dispatch } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(
    [EDIT_CARD_PROPS.AccreditationCard.accreditation],
    userProps
  );
  const [showModal, setShowModal] = React.useState(false);
  const [accreditations, setAccreditations] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [accreditations]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setAccreditations(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const updateSection = () => {
    !accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation] &&
      accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation] === "" &&
      !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
        type: "ADD_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
          handler: () => setShowModal(!showModal),
        },
      })
      : accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation] &&
      accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation] !==
      "" &&
      dispatch({
        type: "REMOVE_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
        },
      });
  };

  const handleUpdate = React.useCallback(
    (val) => {
      setAccreditations(val);
    },
    [accreditations]
  );

  const main: JSX.Element = accreditations &&
    accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation] &&
    accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation] !== "" && (
      <>
        {accreditations[EDIT_CARD_PROPS.AccreditationCard.accreditation]
          .split("|")
          .map((accreditation, i) => (
            <SearchRedirect
              index={i}
              searchKey={'Certifications'}
              keyword={accreditation}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          ))}
      </>
    );

  return showModal ? (
    <div className="bottomBorder">
      <Edit
        header={header}
        props={accreditations}
        show={showModal}
        setShow={setShowModal}
        onUpdate={handleUpdate}
      />
    </div>
  ) : main ? (
    <div className="bottomBorder">
      <ViewCard
        header={header}
        body={main}
        edit={edit}
        toolTip={tooltip}
        loading={loading}
        editHandler={() => setShowModal(!showModal)}
        percentage={percentage}
      />
    </div>
  ) : null;
};

export default Accreditation;
