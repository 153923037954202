import * as React from "react";

export const SearchRedirect = (props) => {
  const { index, searchKey, keyword, redirectUrl, isTagSingle, searchKeyword} = props;
  
  return (    
    <span>
      {index > 100 && keyword.length > 0 ?       
        <a
          key={index}
          className={"tag tag-comma"}
          href={`${redirectUrl}?k=${searchKey}:"${searchKey.includes("ExpertRoleTitle")?searchKeyword:keyword}"`}
          title={keyword}
          target="_blank"
          style={searchKey.includes("ExpertiseArea")?{fontWeight:"bolder"}:{}}
        >
        {keyword}
      </a>:
       <a
          key={index}
          className={isTagSingle?"tag tag-single":"tag"}
          href={`${redirectUrl}?k=${searchKey}:"${searchKey.includes("ExpertRoleTitle")?searchKeyword:keyword}"`}
          target="_blank"
          title={keyword}
          style={searchKey.includes("ExpertiseArea")?{fontWeight:"bolder"}:{}}
        >
        {keyword}
      </a>
      }
    </span>
    
  );
};

export default SearchRedirect;
