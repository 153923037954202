import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

/** Presentation */
import ViewCard from "../../components/ViewCard/ViewCard";
import SearchRedirect from '../../components/SearchRedirect';

import "./RolesCerts.scss";

/** card resize handler */
import helper, { getFolksonomySet } from "../../utils/Helper";

/** Constants */
import { ROLES } from "../../utils/Consts";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

export const Roles: React.FC<ICardComponentProps> = ({ tooltip }) => {
  const { state } = React.useContext(Context);
  const { userProps, showError } = state;  
  const { getCardProps } = useCardHandler(ROLES, userProps);
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  const onLoad = async () => {
      try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        let consolidatedRoles = await getFolksonomySet(process.env.REACT_APP_EXPERT_ROLES_URL);
        if(consolidatedRoles!==null && consolidatedRoles.length>0){
          consolidatedRoles = consolidatedRoles[0].split(",\r\n");
        }
        else{
          consolidatedRoles = [];
        }
        const rolesData = [];
        const propCount = 6;
        for (let i = 1; i <= ROLES.length / propCount; i++) {
          const temp: any = {};
          if (props[`EF-DE-RoleTitle${i}`] !== "") {
            temp.title = props[`EF-DE-RoleTitle${i}`];
            temp.RoleTitle=temp.title;
            //capture the full form for the role Acronym
            if(consolidatedRoles.length>0){
              const role = consolidatedRoles.filter((role) => String(role).includes("("+temp.RoleTitle+")"));
              if(role.length>0){
                temp.RoleTitle=role;
              }
            }
            temp.discipline = props[`EF-DE-Discipline${i}`];
            temp.expertArea = props[`EF-DE-ExpertArea${i}`];
            temp.region = props[`EF-DE-Region${i}`];
            temp.location = props[`EF-DE-Location${i}`];
            temp.country = props[`EF-DE-Country${i}`];
            rolesData.push(temp);
          }
        }
        setRoles(rolesData);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
 
    // finally {
    //   setLoading(false);
    // }
  };
  const main: JSX.Element = (
    <ul className="bulletPoint">
      {roles.map((role,index) => ( 
        <li>
          {(role.title &&
              <SearchRedirect                
                index={101}
                searchKey={'ExpertRoleTitle'}
                searchKeyword={role.title}
                keyword={role.RoleTitle}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                isTagSingle={true}    
              />
            )}
            {( role.expertArea &&              
              <SearchRedirect
                index={index}
                searchKey={'ExpertRoleArea'}
                keyword={role.expertArea}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT} 
                isTagSingle={true}        
              />
            )}
            {(role.discipline && role.discipline !== role.expertArea &&
            <span style={{paddingLeft:"5px"}}>
                 (
              <SearchRedirect
                index={index}
                searchKey={'ExpertRoleDiscipline'}
                keyword={role.discipline}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}   
                isTagSingle={true}    
              />
              )
            </span>
            )}
            {(role.region &&
             <span style={{paddingLeft:"5px"}}>
                 (
                <SearchRedirect
                  index={index}
                  searchKey={'ExpertRoleRegion'}
                  keyword={role.region}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT} 
                  isTagSingle={true}      
                />
                )
              </span>
            )}
            {(role.location &&              
            <span style={{paddingLeft:"5px"}}>
                (
                <SearchRedirect
                  index={index}
                  searchKey={'ExpertRoleLocation'}
                  keyword={role.location}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                  isTagSingle={true}       
                />
              )
            </span>
            )}
            {(role.country &&
              <span style={{paddingLeft:"5px"}}>
                  (
                  <SearchRedirect
                    index={index}
                    searchKey={'ExpertRoleCountry'}
                    keyword={role.country}
                    redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT} 
                    isTagSingle={true}      
                  />
                )
              </span>
            )}          
        </li>
      ))}
    </ul>
  );

  const toolTip = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="info-tooltip">
          Appointed role(s); accountable for the quality of knowledge on defined
          expertise or specialist subject areas.
        </Tooltip>
      }
    >
      <InfoCircle className="info-circle-tooltip-icon" />
    </OverlayTrigger>
  );

  return (
    roles.length > 0 && (
      <div className="bottomBorder">
        <ViewCard
          loading={loading}
          header="Formal Expert Roles"
          body={main}
          toolTip={tooltip}
        />
      </div>      
    )
  );
};

export default Roles;
