import * as React from 'react'

/** Presentation */
import Spinner from 'react-bootstrap/Spinner'
import { Button, Modal } from "react-bootstrap";

/** Custom types*/
import {
    IUpdateCardProps
} from '../../custom-types'

const DeleteEducation: React.FC<IUpdateCardProps> = ({
    props,
    show,
	setShow,
	onUpdate
}: IUpdateCardProps) => {

    const [loading, setLoading] = React.useState(false)
    
    const deleteEducation = (education) => {
        setLoading(true)
        onUpdate({
            ...education,
            degree: '',
            school: '',
            country: ''
        })
        setLoading(false)
        setShow(!show)
    }

    const cancelHandler = () => {
		setShow(false)
	}

	return <Modal
                centered
                show={show}
                onHide={cancelHandler}
                backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title style={{
						fontFamily: 'ShellBook',
						fontSize: 'medium',
					}}>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p className="text-left m-3">   
                   {!loading ? `Are you sure, you want to delete your Education ?`: <div className="p-5 text-center">
				<Spinner animation="border" variant="info" />
			</div>}
                </p> 
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-cancel-menu" size="sm" variant="secondary" onClick={cancelHandler}> Cancel </Button>
                <Button variant="primary" className="btn-danger" size="sm" disabled={loading} onClick={() => deleteEducation(props)}> Delete </Button>
            </Modal.Footer>
        </Modal>
}

export default DeleteEducation
