import * as React from "react";

import ViewCard from "../../components/ViewCard/ViewCard";

/** Context */
import { Context } from "../../contexts/Context";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Utils */
import helpers, {
  searchLFEActivities,
  searchLFEPlaylistActivities,
  searchReplications,
  searchEmphasizes,
  searchSignActivities,
  searchSignPostActivities,
} from "../../utils/Helper";

/** Presentation */
import "../../components/ViewCard/ViewCard.scss";

const Activities: React.FC<ICardComponentProps> = ({
  header,
  edit,
  tooltip,
}) => {
  const [activity, setActivity] = React.useState([]);
  const { state } = React.useContext(Context);
  const [loading, setLoading] = React.useState(true);
  const { currentUser } = state;
  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (currentUser && helpers.IsObj(currentUser))
      try {
        let Activities = await searchLFEActivities(currentUser),
          signActivities = await searchSignActivities(currentUser);
        const PlaylistActivities = await searchLFEPlaylistActivities(currentUser),
          Replications = await searchReplications(currentUser),
          Emphasizes = await searchEmphasizes(currentUser),
          signPostActivities = await searchSignPostActivities(currentUser);

        Activities = Activities.concat(
          PlaylistActivities,
          Replications,
          Emphasizes
        );

        Activities.sort((a, b) => {
          return new Date(b.Created).valueOf() - new Date(a.Created).valueOf();
        });

        Activities = Activities.slice(0, 3);

        signActivities = signActivities.concat(signPostActivities);

        signActivities.sort((a, b) => {
          return new Date(b.Created).valueOf() - new Date(a.Created).valueOf();
        });

        signActivities = signActivities.slice(0, 3);

        Activities = Activities.concat(signActivities);

        Activities.sort((a, b) => {
          return new Date(b.Created).valueOf() - new Date(a.Created).valueOf();
        });
        setActivity(Activities);
      } catch (err) {
        console.log({ err });
      } finally {
        setLoading(false);
      }
  };

  const main: JSX.Element =
    activity && activity.length > 0 ? (
      <ul className="bulletPoint" style={{ wordBreak: "break-all", paddingLeft: "0px", listStyle: "none" }}>
        {activity.slice(0, 6).map((i) => (
          <li key={`activity-${i.Title}`}>
            {i.ActivityText}{" "}
            <a className="tag tag-single" style={{color:"#000FFF"}} href={i.link} target="_blank">
              "{i.Title}"
            </a>
          </li>
        ))}

      </ul>

    ) : null;


  return main ? (
    <div className="bottomBorder">
      <ViewCard loading={loading} header={header} toolTip={tooltip} body={main} />
    </div>
  ) : null;
};

export default Activities;
