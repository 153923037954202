import * as React from 'react'
import Modal from 'react-bootstrap/Modal'

const ErrorMessageContainer: React.FC<{ errorMessage: string | null }> = ({
	errorMessage,
}) => {
	return (
		<Modal show={true}>
			<Modal.Body>
				<span style={{color: 'red'}}>{errorMessage}</span>
			</Modal.Body>
		</Modal>
	)
}
export default ErrorMessageContainer
