import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

import ViewCard from "../../components/ViewCard/ViewCard";
import Edit from "./EditInterests";
import SearchRedirect from '../../components/SearchRedirect';

/** Bootstrap Presentation */
import "../../components/ViewCard/ViewCard.scss";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Utils */
import helper from "../../utils/Helper";

export const Interests: React.FC<ICardComponentProps> = ({
  header,
  edit,
  tooltip,
  percentage
}) => {
  const { state, dispatch } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(
    [EDIT_CARD_PROPS.InterestsCard.interests],
    userProps
  );
  const [showModal, setShowModal] = React.useState(false);
  const [interests, setInterests] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [interests]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setInterests(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const handleUpdate = React.useCallback(
    (val) => {
      setInterests(val);
    },
    [interests]
  );

  const updateSection = () => {
    !interests[EDIT_CARD_PROPS.InterestsCard.interests] &&
    interests[EDIT_CARD_PROPS.InterestsCard.interests] === "" &&
    !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
          type: "ADD_SECTION",
          payload: {
            title: `${header}`,
            showPercentage:true,
            handler: () => setShowModal(!showModal),
          },
        })
      : interests[EDIT_CARD_PROPS.InterestsCard.interests] &&
        interests[EDIT_CARD_PROPS.InterestsCard.interests] !== "" &&
        dispatch({
          type: "REMOVE_SECTION",
          payload: { 
            title: `${header}`,
            showPercentage:false,
        },
        });
  };

  const main: JSX.Element = interests &&
    interests[EDIT_CARD_PROPS.InterestsCard.interests] &&
    interests[EDIT_CARD_PROPS.InterestsCard.interests] !== "" && (
      <>
        {interests[EDIT_CARD_PROPS.InterestsCard.interests]
          .split("|")
          .map((interest, i) => (            
            <SearchRedirect
              index={i}
              searchKey={'Interests'}
              keyword={interest}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
              isTagSingle={false}    
            />
          ))}
      </>
    );

  return showModal ? (
    <Edit
      header={header}
      props={interests}
      show={showModal}
      setShow={setShowModal}
      onUpdate={handleUpdate}
    />
  ) : main ? (
    <ViewCard
      header={header}
      body={main}
      edit={edit}
      loading={loading}
      editHandler={() => setShowModal(!showModal)}
      toolTip={tooltip}
      percentage={percentage}
    />
  ) : null;
};

export default Interests;
