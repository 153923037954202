import React, { createContext, useReducer } from "react";
import { useParams, useLocation } from "react-router-dom";
/** Utils */
import helpers from "../utils/Helper";
/** Custom Hooks */
import useErrorHandler from "../utils/hooks/ErrorHandler";
import useSuccessHandler from "../utils/hooks/SuccessHandler";
import useLoginHandler from "../utils/hooks/LoginHandler";
import useFetchHandler from "../utils/hooks/FetchHandler";
import useCardHandler from "../utils/hooks/CardHandler";
import { DELEGATE } from "../utils/Consts";

import {
  IState,
  IContextInterface,
  Action,
} from "../custom-types";

const initialState: IState = {
  currentUser: {},
  loggedInUser:{},
  sectionProps: [],
  error: "",
  success: "",
  percentage:0,
  contactPercentage:{},
  updated:false,
  isInvalidTerm:false,
  showSuccess: () => {},
  showError: () => {},
  userProps: {},
  config:{}
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const reducer: React.Reducer<IState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "ADD_SECTION":
      return {
        ...state,
        sectionProps: [...state.sectionProps, action.payload],
      };

    case "REMOVE_SECTION":
      const removedSection = state.sectionProps.filter(
        (section) => section.title !== action.payload.title
      );
      return {
        ...state,
        sectionProps: removedSection,
      };

      case "Contact":
        return {
          ...state,
          contactPercentage: [state.contactPercentage, action.payload],
        };
    default:
      return state;
  }
};

export const Context = createContext<IContextInterface>({
  state: { ...initialState },
  dispatch: () => {},
  id: "",
  edit:false,
  showDelegate:false
});

const { Provider } = Context;

const ContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const query = useQuery();
  let id = query.get("accountname");
  if (id) {
    id = decodeURIComponent(id);
    const lastIndex = id.lastIndexOf("|");
    id = id.substring(lastIndex + 1);
  }
 
  let edit:boolean = false;
  let showDelegate:boolean=false
  const [state, dispatch] = useReducer(reducer, initialState);
  const { success, showSuccess } = useSuccessHandler(null);
  const { error, showError } = useErrorHandler(null);
  const currentUser = useLoginHandler(id);  
  const { userProps,config } = useFetchHandler(id);  
  const loggedInUser = useLoginHandler("");   
    if(currentUser["Email"] === loggedInUser["Email"]){
      edit = true;
      showDelegate = true;
    } 
  state.success = success;
  state.showSuccess = showSuccess;
  state.error = error;
  state.showError = showError;
  state.currentUser = currentUser;
  state.loggedInUser = loggedInUser;
  state.userProps = userProps;
  state.config=config;  
  return (
    <Provider
      value={{
        state,
        dispatch,
        id,
        edit,
        showDelegate,
      }}
    >
      {children}
    </Provider>
  );
};

export default ContextProvider;
