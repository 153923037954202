import * as React from "react";

/** Bootstrap Presentation */
import "../../components/ViewCard/ViewCard.scss";

/** Context */
import { Context } from "../../contexts/Context";
import ViewCard from "../../components/ViewCard/ViewCard";
import Edit from "./EditMembership";
import SearchRedirect from '../../components/SearchRedirect';
/** Utils */
import helper from "../../utils/Helper";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

export const Membership: React.FC<ICardComponentProps> = ({
  header,
  edit,
  tooltip,
  percentage
}) => {
  const { state, dispatch } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(
    [EDIT_CARD_PROPS.MembershipCard.membership],
    userProps
  );
  const [showModal, setShowModal] = React.useState(false);
  const [memberships, setMemberships] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [memberships]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setMemberships(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const handleUpdate = React.useCallback(
    (val) => {
      setMemberships(val);
    },
    [memberships]
  );

  const updateSection = () => {
    !memberships[EDIT_CARD_PROPS.MembershipCard.membership] &&
      memberships[EDIT_CARD_PROPS.MembershipCard.membership] === "" &&
      !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
        type: "ADD_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
          handler: () => setShowModal(!showModal),
        },
      })
      : memberships[EDIT_CARD_PROPS.MembershipCard.membership] &&
      memberships[EDIT_CARD_PROPS.MembershipCard.membership] !== "" &&
      dispatch({
        type: "REMOVE_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
        },
      });
  };

  const main: JSX.Element = memberships[
    EDIT_CARD_PROPS.MembershipCard.membership
  ] &&
    memberships[EDIT_CARD_PROPS.MembershipCard.membership] !== "" && (
      <>
        {memberships[EDIT_CARD_PROPS.MembershipCard.membership]
          .split("|")
          .map((membership, i) => (
            <SearchRedirect
              index={i}
              searchKey={'ProfessionalMemberships'}
              keyword={membership}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          ))}
      </>
    );

  return showModal ? (
    <Edit
      header={header}
      props={memberships}
      show={showModal}
      setShow={setShowModal}
      onUpdate={handleUpdate}
    />
  ) : main ? (
    <ViewCard
      header={header}
      body={main}
      edit={edit}
      loading={loading}
      editHandler={() => setShowModal(!showModal)}
      toolTip={tooltip}
      percentage={percentage}
    />
  ) : null;
};

export default Membership;
