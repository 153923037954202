import * as React from "react";
/** Presentation */
import Header from "./Header";
import CurrentJob from "./Career/CurrentJob";
import Career from "./Career/JobHistory";
import Profile from "./Contact/Contact";
import Banner from "./VideoBanner/VideoBanner";
import Roles from "./RolesandCertifications/Roles";
import Certifications from "./RolesandCertifications/Certifications";
import ExpertiseArea from "./Business/ExpertArea";
import BusinessInfo from "./Business/BusinessInfo";
import Mentor from "./Mentor/Mentor";
import AboutMe from "./AboutMe/AboutMe";
import OrgChart from "./OrgChart/OrgChart";
import Skills from "./Skills/Skills";
import FeaturedContent from "./FeaturedContent/FeaturedContent";
import Activities from "./Activities/Activities";
import Activitity from "./Activities/Yammer";
import Membership from "./Membership/Membership";
import Interest from "./Interests/Interests";
import Language from "./KnownLanguage/KnownLanguage";
import AskMeAbout from "./AskMeAbout/AskMeAbout";
import { Accreditation } from "./Accreditation/Accreditation";

import { Educations } from "./Education/Educations";
import Card from "react-bootstrap/Card";
/** Components */
import SuccessMessage from "../components/SuccessMessage";
import ErrorMessage from "../components/ErrorMessage";
import PageLoader from "../components/PageLoader/PageLoader";
import { EDIT_CARD_PROPS, UpdateProperty } from "../utils/Consts";
/** Utils */
import helpers, {
  updateProfile,
  updateProperties,
  updateProperty
} from "../utils/Helper";
/** Context */
import { Context } from "../contexts/Context";
import Modal from "react-bootstrap/Modal";
import Tour from "reactour";
//import Tour from 'bootstrap-tour'
// REST API to the backned -

const RootContainer: React.FC<{}> = () => {
  const { state, id, edit, showDelegate } = React.useContext(Context);
  const { userProps, currentUser, config, loggedInUser } = state;
  const [tourOpen, setTourOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [showInfo, setShowInfo] = React.useState(false);   
  const handleClose = () => setShowInfo(false);  
  React.useEffect(() => {
    userProps && helpers.IsObj(userProps) && currentUser && helpers.IsObj(currentUser) && setLoader(false);
    if (userProps && helpers.IsObj(userProps)) { 
      if (edit && !userProps.d.UserProfileProperties.results.filter(obj => obj.Key === "EF-Mentoring")[0].Value.includes("EFTour")) {
        setTourOpen(true);
      }
    }
  }, [userProps, id, edit, currentUser, config]);

  const _Roles = id ? (
    <Roles header="Formal Expert Roles" />
  ) : (
    <Roles
      header="Formal Expert Roles"
      tooltip={
        <span>
          These are Appointed role(s), as listed in the Master List (EFML) that
          are accountable for the quality of knowledge on&nbsp;
          <a
            target="_blank"
            href={`https://eu001-sp.shell.com/sites/AAAAB1732/Pages/EFML-FSD-Home-Page.aspx`}
          >
            defined expertise or specialist subject areas.
          </a>
        </span>
      }
    />
  );

  const _Certifications = id ? (
    <Certifications header="Expertise Certification" />
  ) : (
    <Certifications
      header="Expertise Certification"
      tooltip="Certified by Shell Commercial Academy, Shell Project Academy, Maritime and Legal Jurisdiction."
    />
  );

  const _Mentor = !edit || tourOpen ? (
    <Mentor header="Available To Mentor" />
  ) : (
    <Mentor header="Available To Mentor" edit />
  );

  const _AboutMe = !edit || tourOpen  ? (
    <AboutMe header="About Me" />
  ) : (
    <AboutMe
      header="About Me"
      tooltip="Provide a brief professional profile."
      edit
      percentage
    />
  );

  const _AskMeAbout = !edit || tourOpen ? (
    <AskMeAbout header="Ask Me About" />
  ) : (
    <AskMeAbout
      header="Ask Me About"
      tooltip="Include subjects from your expertise that you can help people with or topics you are passionate about. Max 25 entries."
      edit
      percentage

    />
  );

  const _Skills = !edit || tourOpen ? (
    <Skills header="Skills" />
  ) : (
    <Skills
      header="Skills"
      tooltip="Include abilities needed to perform a job; acquired either in your current or from previous job experiences. Tip: Refer to Competence Management Framework(s), where applicable. Max 25 entries."
      edit
      percentage
    />
  );

  const _CurrentJob = !edit || tourOpen ? (
    <CurrentJob header="Current Job" />
  ) : (
    <CurrentJob header="Current Job" edit percentage />
  );

  const _Career = !edit || tourOpen ? (
    <Career header="Job History" />
  ) : (
    <Career
      header="Job History"
      tooltip="Add up to maximum of 9 work assignments (Shell or External Experience)."
      edit
      percentage

    />
  );

  const _Accreditation = !edit || tourOpen ? (
    <Accreditation header="Accreditation & Other Certification" />
  ) : (
    <Accreditation
      header="Accreditation & Other Certification"
      tooltip="List any professional certifications or accreditations. Max 3600 characters (incl. spaces)."
      edit
    />
  );

  const _Membership = !edit || tourOpen ? (
    <Membership header="Industry Body & Professional Membership" />
  ) : (
    <Membership
      header="Industry Body & Professional Membership"
      tooltip="List any industry body representation or professional association memberships you belong to. Max 3600 characters (incl. spaces)."
      edit
    />
  );

  const _ExpertiseArea = !edit||tourOpen? (
    <ExpertiseArea header="Expertise Areas" />
  ) : (
    <ExpertiseArea
      header="Expertise Areas"
      tooltip="These are your knowledge areas. Select the Function, and where applicable, your Professional Community/Discipline, Principal and Sub Expertise areas you belong to."
      edit
      percentage
    />
  );
  const _BusinessInfo = <BusinessInfo header="Business Information" />;

  const _OrgChart = <OrgChart header="Org Chart" />;

  const _Activities = !edit || tourOpen ? (
    <Activities header=" Knowledge Sharing" />
  ) : (
    <Activities
      edit
      header=" Knowledge Sharing"
      tooltip="Collaboration Activity feed from SIGN, LFE and Yammer applications."
    />
  );

  const _Activitiy = !edit || tourOpen ? (
    <Activitity header="Yammer" />
  ) : (
    <Activitity
      edit
      header="Yammer"
    />
  );

  const _FeaturedContent = !edit || tourOpen ? (
    <FeaturedContent header="Featured Content" />
  ) : (
    <FeaturedContent
      header="Featured Content"
      tooltip={
        <span>
          Highlight your knowledge sources e.g. provide a link to your
          publications from the Shell Global Library, internal media content
          (posts, documents and videos) Please 
          <a
            target="_blank"
            href={`https://eu001-sp.shell.com/sites/AAFAA4985/IT/Information_Risk_Management/Pages/classify_and_manage_information.aspx?cid=9a790a92-4bfc-4df0-b6b8-ad06426c33e1`}
          >
            Think Secure.
          </a>
           Max 3600 characters (incl. spaces).
        </span>
      }
      edit
    />
  );

  const _Educations = !edit || tourOpen  ? (
    <Educations header="Education Details" />
  ) : (
    <Educations
      header="Education Details"
      tooltip="List the qualification attained, school attended & country."
      edit
    />
  );

  const _Interest = !edit || tourOpen ? (
    <Interest header="Interests" />
  ) : (
    <Interest
      header="Interests"
      tooltip="Share your personal interests. Max 25 entries"
      edit
      percentage
    />
  );

  const _Language = !edit || tourOpen ? (
    <Language header="Language Spoken" />
  ) : (
    <Language
      header="Language Spoken"
      tooltip="Enter the languages you have a verbal proficient level of ability in."     
      edit
      percentage
      isLanguageEdit
    />
  );

  const steps = [
    {
      selector: "#appTitle",
      content: () => <div >
        <div className="editInfoSubHeader">Welcome to the upgraded Expertise Finder.</div>
        <div>This upgrade provides :</div>
        <div>
          <ul className="bulletPoint">
            <li>Better visibility of your skills, experience and expertise</li>
            <li>Enhanced Search Functionality to build and expand your network</li>
            <li>Improved User interface and experience</li>
          </ul>
        </div>
        <div>
          <div className="editInfoSubHeader">Information to Note:</div>
          <div>The Term Store Management Tool (hosting a global set of key words and enabling you to
            populate your profiles, by suggesting registered terms to be used) has recently exceeded its limit.
            This global set of key words is now being rationalized. Consequently,
            some key words related to particular sections in your previous profile (e.g. Skills, Ask Me About)
            may not have been migrated to your new profile page during the upgrade.
            The consequence of this data loss is a reduction in your profile completion rate.
            We kindly ask you to review the ‘Add profile section’ and update your profile with missing
            information to ensure there are no gaps. An attempt was made to rebuild the list and your help is
            needed to complete this. Please, kindly use the “Add missing term(s)” or Feedback tab to request a
            missing term. <br /><br /> Thank you.</div>
        </div>
      </div>,
    },
    {
      selector: "#searchBoxTour",
      content: "Search for other profiles here.",
    },
    {
      selector: "#expertiseAreaCard",
      content: () => <div>
        <div className="editInfoSubHeader">Expertise Areas</div>
        <div>Provides you the option to declare your professional community, functional and discipline areas. Select Function, and as applicable your Discipline, Community, Process and Sub Expertise/Process areas.</div>
      </div>,
    },
    {
      selector: "#aboutMeCard",
      content: () => <div>
        <div className="editInfoSubHeader">About Me</div>
        <div>Provide a brief professional profile summarizing your experience.</div>
      </div>,
    },
    {
      selector: "#career-and-history-card",
      content: () => <div>
        <div className="editInfoSubHeader">Current Job & Job History</div>
        <div>Current job section contains information about your current Role/ Responsibility / Assignment in Shell.<br /><br /> Job History section contains information about your previous assignments / experiences. You can add upto 9 of your records.</div>
      </div>,
    },
    {
      selector: "#educationsCard",
      content: () => <div>
        <div className="editInfoSubHeader">Education Details</div>
        <div>Update your education details.</div>
      </div>,
    }
  ];

  function closeTour() {
    setTourOpen(false);
    setShowInfo(true);
    isShowTour();
  }
  function openTour() {
    setTourOpen(true);
    
  }
  const isShowTour = React.useCallback(async () => {
    const tourFlag = "EFTour";
    if (window.location.href.toLowerCase().indexOf("localhost") === -1) {

      const updateShowTour: SP.ResponseInfo = await updateProfile(
        helpers.appweburl + UpdateProperty.single,
        updateProperty(
          currentUser.LoginName,
          EDIT_CARD_PROPS.IsTourViewed,
          JSON.stringify(tourFlag)
        )
      );
    }
  }, [currentUser]);

  return !loader ? (
    <React.Fragment>
      <Tour
        onRequestClose={closeTour}
        steps={steps}
        isOpen={tourOpen}
        className="efTour"
        startAt={0}
      />
      <Modal show={showInfo} onHide={handleClose}>
        <Modal.Header closeButton className="editInfoMain" >
          <div className="editInfoBody">
            <div>
              <div>Changes to note:</div>
              <div className="editInfoSubHeader">1. New Current Job</div>
              <div className="editInfoBodyText">Step 1: The migration of your Work Assignment data from the old version of
                Expertise Finder may place your current Job information in the Job History section.
                Delete the current job information from the job history card.</div>
              <div className="editInfoBodyText">Step 2: Update the Current Job section with a brief summary of your current role.</div>
              <div className="editInfoSubHeader">2. New Education</div>
              <div className="editInfoBodyText">There is a new format for your education information to enhance search results.
                Please complete this section.</div>
              <div className="divCenter"><button className="btnProceedTo" onClick={() => setShowInfo(false)}>End the tour</button></div>
            </div>
          </div>
        </Modal.Header>

      </Modal>
      <div>
        {state.success && <SuccessMessage successMessage={state.success} />}
        {state.error && <ErrorMessage errorMessage={state.error} />}
      </div>
      <div className="wrapper">
        <div className="header topbar-header">
          <div role="banner">
            <Header openTour={openTour} edit={edit} showDelegate={showDelegate} />
          </div>
          <div role="main" id="contactCard">
            {!edit ? (
              <Profile />
            ) : (
              <Profile
                header="&#37;"
                tooltip={
                  <p>
                    Update your contact card with current work location and
                    availability.
                    <br />
                    Your updated profile picture may take up to 72 hrs to
                    reflect.
                    <br/>
                    Click on the link icon to copy the profile link.
                  </p>
                }
                edit
              />
            )}
          </div>
        </div>

<div>
<Banner/>

</div>
        <div role="contentinfo" className="cards-container" style={{paddingBottom:"200px"}}>
          <div>
            {(_Roles || _Certifications) && (
              <Card>
                {_Roles}
                {_Certifications}
              </Card>
            )}
            {(_Mentor || _AboutMe || _AskMeAbout) && (
              <Card>
                {_Mentor}
                <span id="aboutMeCard">{_AboutMe}</span>
                {_AskMeAbout}
              </Card>
            )}
            {_Skills && <Card>{_Skills}</Card>}
            {(_CurrentJob || _Career) && (
              <Card id="career-and-history-card">
                {_CurrentJob}
                {_Career}
              </Card>
            )}
            {(_Accreditation || _Membership) && (
              <Card>
                {_Accreditation}
                {_Membership}
              </Card>
            )}
          </div>
          <div>
            {(_ExpertiseArea || _BusinessInfo) && (
              <Card id="expertiseAreaCard">
                {_ExpertiseArea}
                {_BusinessInfo}
              </Card>
            )}
            {_OrgChart && <Card>{_OrgChart}</Card>}
            {(_Activities || _Activitiy) &&
              (<Card>
                {_Activities}
                {_Activitiy}
              </Card>
              )}
            {_FeaturedContent && <Card style={{width:"100%"}}>{_FeaturedContent}</Card>}
            {_Educations && <Card id="educationsCard">{_Educations}</Card>}
            {_Interest && <Card>{_Interest}</Card>}
            {_Language && <Card>{_Language}</Card>}
          </div>
        </div>
      </div>{" "}
    </React.Fragment>
  ) : (
    <PageLoader profile={id} />
  );
};

export default RootContainer;
