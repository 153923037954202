import { useCallback } from "react";

const useCardHandler = (card: string[], userProps: any) => {
  const getCardProps = useCallback(async (): Promise<Array<string>> => {
    if (Object.keys(userProps).length) {
      let properties = userProps.d.UserProfileProperties.results;
      properties = Object.assign(
        {},
        ...properties
          .filter((i) => card.includes(i.Key))
          .map((item) => ({[item.Key]: item.Value }))
      );
      return Promise.resolve(properties);
    }
  }, [userProps]);

  return { getCardProps };
};

export default useCardHandler;
