import * as React from "react";

/** Presentation */
import ViewCard from "../../components/ViewCard/JobCard";
import { ListGroup } from "react-bootstrap";

/** Child component */
import Job from "./Job";

import AddJob from "../../containers/Career/AddJob";
import { getDateFormat } from "../../utils/Helper";
/** Context */
import { Context } from "../../contexts/Context";
import Spinner from "react-bootstrap/Spinner";


/** Constants */
import {
  EDIT_CARD_PROPS,
  NOTIFICATION_MESSAGE,
  UpdateProperty,
} from "../../utils/Consts";

/** Constants */
import { PROJECTS } from "../../utils/Consts";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Utils */
import helper, {
  updateProfile,
  updateProperty,
  getDate,
} from "../../utils/Helper";

import useCardHandler from "../../utils/hooks/CardHandler";

import Container from "react-bootstrap/Container";
import Skeleton from "react-loading-skeleton";
import { TopHeader } from "../../components/Styles";

export const JobHistory: React.FC<ICardComponentProps> = ({
  header,
  tooltip,
  edit,
  percentage
}) => {
  const { state, dispatch } = React.useContext(Context);
  const [loading, setLoading] = React.useState(true);
  const { userProps, showError, showSuccess, currentUser } = state;
  const { getCardProps } = useCardHandler(PROJECTS, userProps);
  const [jobs, setJobs] = React.useState([]);
  const [jobToAdd, setJobToAdd] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [jobs]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        const jobData = [],
          noJobData = [];        
        for (let i = 1; i <= 9; i++) {
          const temp: any = {};
          if (props[`EF-PA-ProjectTitle${i}`] !== "") {
            temp.id = i;
            temp.company = props[`EF-PA-ProjectTitle${i}`];
            temp.jobTitle = props[`EF-PA-ProjectJobTitle${i}`];
            temp.description = props[`EF-PA-ResponsibilityArea${i}`];
            temp.location = props[`EF-PA-ProjectLocation${i}`];
            temp.startDate = new Date(getDateFormat(props[`EF-PA-ProjectStartDate${i}`]));
            temp.endDate = new Date(getDateFormat(props[`EF-PA-ProjectEndDate${i}`])) || "";
            temp.asset = props[`EF-PA-Asset${i}`];
            temp.capitalProject = props[`EF-PA-CapitalProjects${i}`];
            temp.role = props[`EF-PA-PivotalRole${i}`];
            jobData.push(temp);
          } else {
            temp.id = i;
            temp.company = props[`EF-PA-ProjectTitle${i}`];
            temp.jobTitle = props[`EF-PA-ProjectJobTitle${i}`];
            temp.description = props[`EF-PA-ResponsibilityArea${i}`];
            temp.location = props[`EF-PA-ProjectLocation${i}`];
            temp.startDate = new Date(props[`EF-PA-ProjectStartDate${i}`]);
            temp.endDate = new Date(props[`EF-PA-ProjectEndDate${i}`]) || "";
            temp.asset = props[`EF-PA-Asset${i}`];
            temp.capitalProject = props[`EF-PA-CapitalProjects${i}`];
            temp.role = props[`EF-PA-PivotalRole${i}`];
            noJobData.push(temp);
          }
        }
        const sorted = jobData.slice().sort((a, b) => b.startDate - a.startDate);

        setJobs(sorted);
        setJobToAdd(noJobData);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const updateSection = () => {
    !jobs.length &&
      !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
        type: "ADD_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: true,
          handler: () => setShowModal(!showModal),
        },
      })
      : jobs.length &&
      state.sectionProps.find((section) => section.title === header) &&
      dispatch({
        type: "REMOVE_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
        },
      });
  };

  const deleteJob = React.useCallback(
    async (job) => {
      const { id } = job;
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helper.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              new Date().toLocaleString()
            )
          );

          const updateCompanyResponse: SP.ResponseInfo = await updateProfile(
            helper.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.company.concat(id),
              ""
            )
          );

          const updateStartDateResponse: SP.ResponseInfo = await updateProfile(
            helper.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.startDate.concat(id),
              ""
            )
          );

          const updateDescriptionResponse: SP.ResponseInfo = await updateProfile(
            helper.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.description.concat(id),
              ""
            )
          );

          const updateAssetResponse: SP.ResponseInfo = await updateProfile(
            helper.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.asset.concat(id),
              ""
            )
          );

          const updateCountryResponse: SP.ResponseInfo = await updateProfile(
            helper.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.country.concat(id),
              ""
            )
          );

          if (
            updateCompanyResponse.statusCode === 200 &&
            updateStartDateResponse.statusCode === 200 &&
            updateDescriptionResponse.statusCode === 200 &&
            updateAssetResponse.statusCode === 200 &&
            updateCountryResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            setJobs(jobs.filter((job) => job.id !== id));
            setJobToAdd([...jobToAdd, job]);
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          setJobs(jobs.filter((job) => job.id !== id));
          setJobToAdd([...jobToAdd, job]);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showError(NOTIFICATION_MESSAGE.PreferenceError);
      }
    },
    [jobs, jobToAdd]
  );

  const addJob = React.useCallback(
    (val) => {
      const tempJobs=jobs;
      val.startDate=new Date(val.startDate);
      const filteredJobs  = tempJobs.filter(job=>job.id!==val.id);
      filteredJobs.push(val);
      const sorted=filteredJobs.slice().sort((a, b) => b.startDate - a.startDate); 
      //setJobs([val, ...jobs].slice().sort((a, b) => b.startDate - a.startDate));
      setJobs(sorted);
      setJobToAdd(jobToAdd.filter((job) => job.id !== val.id));
      
    },
    [jobs, jobToAdd]
  );

  const main: JSX.Element = loading ? (
    <Container className="loading-container">
      <Skeleton count={5} />
    </Container>
  ) : (
    <ListGroup className="list-group-flush">
      {jobs.map(
        (o) =>
          o.id && (
            <Job
              edit={edit}
              key={`job-${o.id}`}
              data={o}
              deleteHandler={deleteJob}
              sortHandler={addJob}            
            />
          )
      )}
    </ListGroup>
  );

  return showModal ? (
    <AddJob
      header={header}
      props={jobToAdd.find((noJob) =>
        jobs.filter((job) => job.id !== noJob.id)
      )}
      show={showModal}
      setShow={setShowModal}
      onUpdate={addJob}
    />
  ) : loading ? (
    <div className="p-5 text-center">
      <Spinner animation="border" variant="info" />
    </div>
  ) : jobs.length ? (
    <ViewCard
      header={header}
      loading={loading}
      toolTip={tooltip}
      edit={edit}
      body={main}
      job={jobToAdd.find((noJob) => jobs.filter((job) => job.id !== noJob.id))}
      addHandler={addJob}
      percentage={percentage}

    />
  ) : null;
};

export default JobHistory;
