import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { ToolTip } from "../Tooltip";
import "./AddSection.scss";
import { ReactComponent as Yourssvg } from "../../assets/images/percentage.svg";
/** Section Context */
import { Context } from "../../contexts/Context";

export const AddSection: React.FC<{}> = () => {
  const { state } = React.useContext(Context);
  const toolTipText = `Make a selection to add to your profile.`;
  React.useEffect(() => {
    state.sectionProps.length &&
      state.sectionProps.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });
  }, [state.sectionProps]);

  const sections =
    state.sectionProps.length > 0 &&
    state.sectionProps.map(({ title, handler, showPercentage }, idx) => (
      <>
        <Dropdown.Item
          style={{ minWidth: "9rem" }}
          key={idx}
          eventKey={`section${idx}`}
          onClick={handler}
        >
          {title} <span>{showPercentage && (<Yourssvg />)}</span>
        </Dropdown.Item>
        {state.sectionProps.length !== idx + 1 && <Dropdown.Divider />}
      </>
    ));

  return (
    sections && (
      <div>
        <SplitButton
          className="btn-add-section btn-add btn-xs" // class name changed before :btn-add-section btn btn-xs
          size="sm"
          id="split-btn"
          variant="info"
          title="Add profile section"
        >
          {sections}
        </SplitButton>
        {toolTipText && <ToolTip tooltipMessage={toolTipText} />}
      </div>
    )
  );
};
