import * as React from "react";

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import EditCard from "../../components/EditCard";

import AsyncSelect from "react-select/async";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom hooks */
import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import {
  NOTIFICATION_MESSAGE,
  EDIT_CARD_PROPS,
  UpdateProperty,
} from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperties,
  updateProperty,
  getDefaultOptions,
  getTodaysDateTimeString
} from "../../utils/Helper";

const EditLangauge: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const { getLanguage } = useTaxonomyHandler();
  const [languages, setLanguages] = React.useState(
    props[EDIT_CARD_PROPS.LanguageCard.languages]
      ? props
      : { [EDIT_CARD_PROPS.LanguageCard.languages]: "" }
  );
  const [defaultLanguages, setDefaultLanguages] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  const LoadingMessage = (props) => {
    console.log(props.selectProps.inputValue);
    return (
      <div
        {...props.innerProps}
        style={props.getStyles("loadingMessage", props)}
      >
        {props.selectProps.inputValue.length >= 2
          ? "Loading languages..."
          : `Please enter ${
              2 - props.selectProps.inputValue.length
            } more character`}
      </div>
    );
  };

  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      const languages = await getLanguage();
      setDefaultLanguages(Object.keys(languages).sort());
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  };

  const updateLanguages = React.useCallback(async () => {
    if (
      props[EDIT_CARD_PROPS.LanguageCard.languages] !==
      languages[EDIT_CARD_PROPS.LanguageCard.languages]
    ) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const languagesUpdateResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.multiple,
            updateProperties(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LanguageCard.languages,
              languages[EDIT_CARD_PROPS.LanguageCard.languages].split("|")
            )
          );

          if (
            languagesUpdateResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate(languages);
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);
            setLanguages(props);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          onUpdate(languages);
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);
        setLanguages(props);
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else setShow(false);
  }, [languages]);

  const options = (inputValue: string) =>
    getDefaultOptions(
      defaultLanguages
        .filter((i) => i.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 20)
    );

  const promiseOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(options(inputValue));
        }, 1000);
      });
    }
  };

  const cancelHandler = () => {
    setShow(false);
    setLanguages(props);
  };

  const main: JSX.Element = (
    <Container role="main">
      {loading && (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Form.Group className="mb-4" controlId="languages">
        <Form.Label>Enter languages based your proficiency</Form.Label>
        <AsyncSelect
          isMulti
          autoFocus
          components={{ LoadingMessage }}
          onChange={(e) =>
            e
              ? setLanguages({
                  [EDIT_CARD_PROPS.LanguageCard.languages]: e
                    .map((language) => language.value)
                    .join("|"),
                })
              : setLanguages({ [EDIT_CARD_PROPS.LanguageCard.languages]: "" })
          }
          isClearable
          placeholder="Search languages.."
          defaultValue={
            languages[EDIT_CARD_PROPS.LanguageCard.languages] &&
            languages[EDIT_CARD_PROPS.LanguageCard.languages] !== ""
              ? getDefaultOptions(
                  languages[EDIT_CARD_PROPS.LanguageCard.languages].split("|")
                )
              : ""
          }
          cacheOptions
          options
          loadOptions={promiseOptions}
        />
      </Form.Group>
    </Container>
  );

  return (
    <EditCard
      header={
        props[EDIT_CARD_PROPS.LanguageCard.languages]
          ? `Edit ${header}`
          : `Add ${header}`
      }
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateLanguages}
      body={main}
    />
  );
};

export default EditLangauge;
