import * as React from "react";

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import EditCard from "../../components/EditCard";

import Select, { components } from "react-select";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom hooks */
import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

import { NOTIFICATION_MESSAGE, UpdateProperty } from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  getDefaultOptions,
  updateProperty,
  getSelectedOption,
  getTodaysDateTimeString
} from "../../utils/Helper";

import "./EditContact.scss";

const EditContact: React.FC<IUpdateCardProps> = ({
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser, loggedInUser} = state;
  const { getOfficeLocations } = useTaxonomyHandler();
  const [contact, setContact] = React.useState(props);
  const [loading, setLoading] = React.useState(true);
  const [noNumber, setNoNumber] = React.useState(false);
  const [locations, loadLocations] = React.useState<string[]>([]);

  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      const locations = await getOfficeLocations();
      loadLocations(Object.keys(locations).sort());
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  };

  const checkPhoneNumber=(e)=>{
    //const re = /^[0-9\b]+$/;    
    const re =  /^[0-9~@#!$^*()_+=[\]{}|\\,.?: -]*$/;
    if (e.target.value === '' || re.test(e.target.value)) {

       setNoNumber(false);
       setContact({
        ...contact,
        [EDIT_CARD_PROPS.ContactCard.phone]:e.target.value,
      })
    }

    else{
setNoNumber(true);

    }

  } 

  const main: JSX.Element =
    contact[EDIT_CARD_PROPS.ContactCard.WorkingDays] &&
    contact[EDIT_CARD_PROPS.ContactCard.WorkingDays] !== "" &&
    locations.length ? (
      <Container role="main">
        <Form.Group className="mb-4" controlId="location">
          <Form.Label
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
          >
            Work Location
          </Form.Label>
          <Select
            aria-label="Work Location"
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
            options={getDefaultOptions(locations)}
            value={
              contact[EDIT_CARD_PROPS.ContactCard.OfficeLocation] &&
              getSelectedOption(
                contact[EDIT_CARD_PROPS.ContactCard.OfficeLocation]
              )
            }
            onChange={(val) =>
              val
                ? setContact({
                    ...contact,
                    [EDIT_CARD_PROPS.ContactCard.OfficeLocation]: val["value"],
                  })
                : setContact({
                    ...contact,
                    [EDIT_CARD_PROPS.ContactCard.OfficeLocation]: "",
                  })
            }
            isClearable
            isSearchable
            components={{
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Search location here"}
                  </components.Placeholder>
                );
              },
            }}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="workdays">
          <Form.Label
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
            className="custom-control pl-0 custom-label"
          >
            Working Days
          </Form.Label>

          {Object.keys(
            JSON.parse(contact[EDIT_CARD_PROPS.ContactCard.WorkingDays])
          ).map((day) => (
            <div className="custom-control-inline pl-2">
              <Form.Check
                style={{
                  fontFamily: "ShellBook",
                  fontSize: "small",
                }}
                type="checkbox"
                key={day}
                id={day}
                value={day}
                checked={
                  JSON.parse(contact[EDIT_CARD_PROPS.ContactCard.WorkingDays])[
                    day
                  ]
                }
                label={day}
                onChange={(e) => handleCheckEvent(e)}
              />
            </div>
          ))}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Contact Number 
          </Form.Label>
          <Form.Control
            type="input"
            value={ contact[EDIT_CARD_PROPS.ContactCard.phone]}
             style={{width:"30%"}}
            onChange={(e) => checkPhoneNumber(e)}
          />
           <Form.Text className="required-message">
              {noNumber ? `Please provide valid contact number` : ``}
            </Form.Text>          
          
        </Form.Group>
        
      </Container>
    ) : (
      <div className="p-5 text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );

  const updateContact = React.useCallback(async () => {
    if(!noNumber){
    try {
     
      setLoading(true);
      if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
        const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            EDIT_CARD_PROPS.LastUpdated,
            getTodaysDateTimeString()
          )
        );

        const officeLocationUpdateResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            EDIT_CARD_PROPS.ContactCard.OfficeLocation,
            contact[EDIT_CARD_PROPS.ContactCard.OfficeLocation]
          )
        );

        const workingDaysUpdateResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            EDIT_CARD_PROPS.ContactCard.WorkingDays,
            contact[EDIT_CARD_PROPS.ContactCard.WorkingDays]
          )
        );
        const phoneUpdateResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            EDIT_CARD_PROPS.ContactCard.phone,
            contact[EDIT_CARD_PROPS.ContactCard.phone]
          )
        );
      

        if (
          officeLocationUpdateResponse.statusCode === 200 &&
          workingDaysUpdateResponse.statusCode === 200 &&
          phoneUpdateResponse.statusCode==200 &&
          lastUpdatedResponse.statusCode === 200
                 ) {
          setLoading(false);
          setShow(false);
          onUpdate(contact);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        } else {
          setLoading(false);
          setShow(false);
          setContact(props);
          showError(NOTIFICATION_MESSAGE.PreferenceError);
        }
      } else {
        onUpdate(contact);
        setLoading(false);
        setShow(false);
        showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
      }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setContact(props);
      showError(NOTIFICATION_MESSAGE.Error);
    }
    }
  }, [contact]);

  const cancelHandler = () => {
    setShow(false);
    setContact(props);
  };

  const handleCheckEvent = (e) => {
    const { id, checked } = e.target;
    setContact({
      ...contact,
      [EDIT_CARD_PROPS.ContactCard.WorkingDays]: JSON.stringify({
        ...JSON.parse(contact[EDIT_CARD_PROPS.ContactCard.WorkingDays]),
        [id]: checked,
      }),
    });
  };

  return (
    <EditCard
      header="Edit Contact Details"
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateContact}
      body={main}
    />
  );
};

export default EditContact;
