import * as React from "react";

/** Presentation */
import ViewCard from "../../components/ViewCard/ViewCard";

/** Custom types*/
import { ICardComponentProps, IUpdateCardProps } from "../../custom-types";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

import { NOTIFICATION_MESSAGE, UpdateProperty } from "../../utils/Consts";

/** Utils */
import helpers, { updateProfile, updateProperty, getTodaysDateTimeString } from "../../utils/Helper";

/** Context */
import { Context } from "../../contexts/Context";

import useCardHandler from "../../utils/hooks/CardHandler";
import { maxHeaderSize } from "http";

export const Mentor: React.FC<ICardComponentProps> = ({ header, edit }) => {
  const { state } = React.useContext(Context);
  const [loading, setLoading] = React.useState(true);
  const { userProps, showSuccess, showError, currentUser } = state;
  const { getCardProps } = useCardHandler(
    [EDIT_CARD_PROPS.AboutMeCard.isMentoring],
    userProps
  );
  const [mentoring, setMentoring] = React.useState({});

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  const onLoad = async () => {
    try {
      if (helpers.IsObj(userProps)) {
        const props = await getCardProps();
        setMentoring(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const update = React.useCallback(
    async (mentoringState) => {
      try {
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const mentorResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.AboutMeCard.isMentoring,
              JSON.stringify(mentoringState)
            )
          );

          if (
            mentorResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            setMentoring({
              [EDIT_CARD_PROPS.AboutMeCard.isMentoring]: JSON.stringify(
                mentoringState
              ),
            });
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          setMentoring({
            [EDIT_CARD_PROPS.AboutMeCard.isMentoring]: JSON.stringify(
              mentoringState
            ),
          });
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        showError(NOTIFICATION_MESSAGE.Error);
      }
    },
    [mentoring]
  );

  const isMentor: boolean =
    mentoring[EDIT_CARD_PROPS.AboutMeCard.isMentoring] &&
    mentoring[EDIT_CARD_PROPS.AboutMeCard.isMentoring].length > 0
      ? mentoring[EDIT_CARD_PROPS.AboutMeCard.isMentoring].toLowerCase() ===
        "true"
        ? true
        : false
      : null;

  return edit ? (
    <div className="bottomBorder">
      <ViewCard
        header={header}
        mentor
        loading={loading}
        isMentor={isMentor}
        toggleHandler={update}
      />
    </div>
  ) : isMentor ? (
     <div className="bottomBorder">
      <ViewCard
        header={header}
        loading={loading}
        isMentor={isMentor}
        toggleHandler={update}
      />
    </div>
  ) : null;
};

export default Mentor;
