import * as React from "react";
import "./AboutMe.scss";

import Edit from "./EditAboutMe";

/** Presentation */
import ViewCard from "../../components/ViewCard/ViewCard";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Context */
import { Context } from "../../contexts/Context";

/** Utils */
import helpers, { getCharLength, removeHTML } from "../../utils/Helper";

import useCardHandler from "../../utils/hooks/CardHandler";

export const AboutMe: React.FC<ICardComponentProps> = ({
  header,
  tooltip,
  edit,
  percentage  
}) => {
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const [loading, setLoading] = React.useState(true);
  const { userProps, showError } = state;
  const [showModal, setShowModal] = React.useState(false);
  const { getCardProps } = useCardHandler(["AboutMe"], state.userProps);
  const [aboutMe, setAboutMe] = React.useState({});

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [aboutMe]);

  const onLoad = async () => {
    try {
      if (helpers.IsObj(userProps)) {
        const props = await getCardProps();
        setAboutMe(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };
  const handleUpdate = React.useCallback(
    (val) => {
      setAboutMe(val);
    },
    [aboutMe]
  );

  const updateSection = () => {
    !aboutMe["AboutMe"] &&
    aboutMe["AboutMe"] === "" &&
    !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
          type: "ADD_SECTION",
          payload: {
            title: `${header}`,
            showPercentage:true,
            handler: () => setShowModal(!showModal),
          },
        })
      : aboutMe["AboutMe"] &&
        removeHTML(aboutMe["AboutMe"]) !== "" &&
        dispatch({
          type: "REMOVE_SECTION",
          payload: { 
          title: `${header}`,
          showPercentage:false,
        },
        });
  };

  const main: JSX.Element = aboutMe &&
    aboutMe["AboutMe"] &&
    aboutMe["AboutMe"] !== "" &&
    removeHTML(aboutMe["AboutMe"]) !== "" && (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: aboutMe["AboutMe"],
          }}
          className={
            (getCharLength(aboutMe["AboutMe"], 512).length > 512 ||
              getCharLength(aboutMe["AboutMe"], 512).split("\n").length > 5) &&
            open
              ? "text-reveal-panel show-all"
              : "text-reveal-panel"
          }
        ></div>
        {(getCharLength(aboutMe["AboutMe"], 512).length > 512 ||
          getCharLength(aboutMe["AboutMe"], 512).split("\n").length > 5) && (
          <a
            className={
              open
                ? "text-reveal-action text-reveal-active"
                : "text-reveal-action"
            }
            onClick={() => setOpen(!open)}
          >
            {!open ? "Show more" : "Show less"}
          </a>
        )}
      </>
    );

  return showModal ? (
    <div className="bottomBorder">
      <Edit
        header={header}
        props={aboutMe}
        show={showModal}
        setShow={setShowModal}
        onUpdate={handleUpdate}
      />
    </div>
  ) : main ? (
    <div className="bottomBorder">
      <ViewCard
        header={header}
        edit={edit}
        loading={loading}
        editHandler={() => setShowModal(!showModal)}
        body={main}
        toolTip={tooltip}
        percentage={percentage}
        
      />
    </div>
  ) : null;
};

export default AboutMe;
