import * as React from "react";

/** Presentation */
import CamIcon from "../../assets/icons/Camera";
import Skeleton from "react-loading-skeleton";
import "./Contact.scss";
import SearchRedirect from '../../components/SearchRedirect';
import { IWindowFeatures } from "react-new-window";
/** Containers  */
import EditContact from "./EditContact";

/** hyperlnk banner  */
import Banner from "../VideoBanner/VideoBanner";


/** Custom types */
import { DaysOfWeek, ICardComponentProps } from "../../custom-types";

/** Constants */
import { CONTACT,CONST_CONTACT, NOTIFICATION_MESSAGE,UpdateProperty } from "../../utils/Consts";

/** Custom imports */
import NewWindow from "react-new-window";

import { AddSection } from "../../components/AddSection/AddSection";
import { Percentage} from "../../components/AddSection/Percentage";

import {ReactComponent as Yourssvg} from "../../assets/images/percentage.svg";
import {ReactComponent as Yourssvg2} from "../../assets/images/Mobile.svg";
import {ReactComponent as Yourssvg3} from "../../assets/images/phone.svg";
import {ReactComponent as Yourssvg4} from "../../assets/images/MsTeams.svg";
import {ReactComponent as Yourssvg5} from "../../assets/images/Skype.svg";


/** Context */
import { Context } from "../../contexts/Context";

/** Utils */
import helper, {
  helpers,
  wrappedText,
  getDate,
  getWorkingDays,
  getProfileViews,
  updateProfile,

  updateProperty,
} from "../../utils/Helper";
import useCardHandler from "../../utils/hooks/CardHandler";
import { PencilFill, TelephoneFill, EnvelopeFill, Link} from "react-bootstrap-icons";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { Views } from "sp-pnp-js/lib/sharepoint/views";
const Contact: React.FC<ICardComponentProps> = ({ tooltip, edit }) => {
  const { state, dispatch } = React.useContext(Context); 
  const [loading, setLoading] = React.useState(true);
  const { userProps, showSuccess, showError,currentUser } = state;
  const [showPopup, setShowPopup] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [viewsIn7Days, setViewsIn7Days] = React.useState(null);
  const [viewsIn30Days, setViewsIn30Days] = React.useState(null);
  const { getCardProps } = useCardHandler(CONTACT, state.userProps);
  const [contact, setContact] = React.useState({});
  const DEFAULT_WORKING_DAYS: DaysOfWeek = {
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
  };

  const popupProps: IWindowFeatures = {
    width: 600,
    height: 400,
    left: 300,
    top: 150,
  };

  React.useEffect(() => {
    contactHandler();
  }, [state.userProps]);

  
  

  React.useEffect(() => updateSection(), [contact]);

 

  const contactHandler = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        props[CONST_CONTACT.EF_WORKDAYS] = props[CONST_CONTACT.EF_WORKDAYS].length
          ? props[CONST_CONTACT.EF_WORKDAYS]
          : JSON.stringify(DEFAULT_WORKING_DAYS);
        setContact(props);
        setLoading(false);
        
        const profileViews=await getProfileViews(props[CONST_CONTACT.WORK_EMAIL],"false");

        if(profileViews==null){
          setViewsIn7Days(0);
          setViewsIn30Days(0);
        }
        else
        {
       const  viewsWithUserNames=profileViews.split(";") ;

       const userNames=[];
       const views=[];
       viewsWithUserNames.map((viewWithName)=>
       {
       const viewsArray=viewWithName.split(":");
      if(!userNames.includes(viewsArray[1]))
      {
        views.push(viewsArray[0]);
        userNames.push(viewsArray[1]);

      }

       }
       
       )


      
       const dateBefore7Days=new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US");
       const dateBefore30Days=new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US");
     const viewsIn7Days=(views.filter((date) => new Date(date)>=new Date(dateBefore7Days))).length;
       const viewsIn30Days=(views.filter((date) => new Date(date)>=new Date(dateBefore30Days))).length;
       
      setViewsIn7Days(viewsIn7Days);
      setViewsIn30Days(viewsIn30Days);
        

       
      }
    }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

 


  const handleUpdate = React.useCallback(
    (val) => {
      setContact(val);
    },
    [contact]
  );

  const copy= ()=> {
    const el = document.createElement('input');
    el.value = window.location.href + "?accountname=".concat(encodeURIComponent("i:0#.f|membership|")).concat(encodeURIComponent(contact[CONST_CONTACT.WORK_EMAIL]));
    el.id = "url-input";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    el.remove();
    showSuccess(NOTIFICATION_MESSAGE.LinkCopied);
  }
  const OpenOutlook= ()=> {
    window.open("https://mymail.shell.com/owa/shell.com/", "_blank");
  }

  const handleProfilePhotoUpload = () => {
    setShowPopup(false);
  };

  const updateSection = () => {
    if(contact[CONST_CONTACT.EF_WORKDAYS]|| contact[CONST_CONTACT.SPS_LOCATION])  {      
      if(contact[CONST_CONTACT.SPS_LOCATION]!=="" ||Object.values(JSON.parse(contact[CONST_CONTACT.EF_WORKDAYS])).includes(true))
      {
        dispatch({
          type: "Contact",
          payload: 
          {
            value:1
          }          
        })
      }
      else
      {        
        dispatch({
          type: "Contact",
          payload: 
          {
            value:0
          }          
        })
      }
    } 
  };
let projectLocation:string = null
  if  (contact[CONST_CONTACT.OFFICE] !== "")  {
    projectLocation = contact[CONST_CONTACT.COUNTRY] + ", " +  contact[CONST_CONTACT.OFFICE]
  }  else if(contact[CONST_CONTACT.COUNTRY] !== "" && contact[CONST_CONTACT.OFFICE] === "") {
    projectLocation = contact[CONST_CONTACT.COUNTRY]
  } else {
    projectLocation = contact[CONST_CONTACT.OFFICE]
  }       
  
  if(contact[CONST_CONTACT.SPS_LOCATION] !== "") {               
    projectLocation = projectLocation + " " + contact[CONST_CONTACT.SPS_LOCATION];
  }
                  
  return helper.IsObj(contact) && !loading ? (
    <>
      {showModal && (
        <EditContact
          props={contact}
          show={showModal}
          setShow={setShowModal}
          onUpdate={handleUpdate}
        />
      )}   

      
      <div className="contact">
        <div className="contactCard">
        <div className="contactCol1">
          <div className="image">
            <img
              src={`https://eu001-sp.shell.com/sites/AAAAB0586/_layouts/15/userphoto.aspx?size=L&accountname=${contact[CONST_CONTACT.WORK_EMAIL]}`}                    
              alt={contact[CONST_CONTACT.PREFERRED_NAME]} 
            />
            {edit && (
              <div className="upload">
                <div
                  className="icon"
                  onClick={OpenOutlook}
                >
                  <CamIcon title="You can only change your profile picture via your outlook.com profile; after changing it there it will automatically reflect here within 24 hours. Clicking this link will bring you to outlook, where you need to further navigate to your profile picture and update it." />
                </div>
              </div>
            )} 
          </div>
        </div>
        <div className="contactCol2">
           <div className="contactName">{contact[CONST_CONTACT.PREFERRED_NAME]}</div> 
           <div className="contactTitle">                 
               <a
                  key={1}
                  className="tag tag-single"
                  href={`${process.env.REACT_APP_SEARCH_API_ENDPOINT}?k=${'WorkProjectTitle'}:"${contact[CONST_CONTACT.TITLE]}"`}
                  target="_blank"
              >
                <span style={{fontSize:"18px",fontWeight:"bold"}}>{contact[CONST_CONTACT.TITLE]}</span>
              </a>          
            </div>
           <div className="locationFlex">  
            <div>                                
                {contact[CONST_CONTACT.SPS_LOCATION] &&
                  <SearchRedirect
                      index={2}
                      searchKey={'BaseOfficeLocation'}
                      keyword={contact[CONST_CONTACT.SPS_LOCATION]}
                      redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                      isTagSingle={true}    
                    />                 
                }  
                {(contact[CONST_CONTACT.SPS_LOCATION] && contact[CONST_CONTACT.OFFICE]) && 
                <span>, </span>
                }           
                <SearchRedirect
                  index={1}
                  searchKey={'OfficeNumber'}
                  keyword={contact[CONST_CONTACT.OFFICE]}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}  
                  isTagSingle={true}    
                />   
                {edit && (
                  <Yourssvg/> 
                )} 
                {edit &&
                  (<Link className="info-circle-tooltip-icon" style={{cursor:"pointer"}} onClick={copy}/>)}
                {edit && (<span>  </span>)}
                {tooltip && (
                    <OverlayTrigger
                      placement="right-start"
                      delay={{ show: 250, hide: 1000 }}
                      overlay={
                        <Tooltip
                          id="info-tooltip"
                          style={{
                            maxWidth: "20em",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {tooltip}
                        </Tooltip>
                      }
                    >
                      <InfoCircle className="info-circle-tooltip-icon" />
                    </OverlayTrigger>
                  )}
                                
               </div>
               
            </div>            
        </div>
        
        <div className="contactCol3">          
          {edit && (
            <div className="contactEdit">
              <PencilFill                
                onClick={() => setShowModal(!showModal)}
              />
            </div>
          )}   
          <div style={{marginLeft:"6.5%",marginBottom:"2%"}}>
          <div className="contactPhone">
            {( contact[CONST_CONTACT.WORK_PHONE] !== "") && (
              <div style={{display:"flex"}}>
              
                <div style={{width:"100%"}}>
                <Yourssvg3 style={{float:"left"}}/>  
                {contact[CONST_CONTACT.WORK_PHONE] !== "" &&
                    <div style={{marginLeft:"14%"}}>
                      {contact[CONST_CONTACT.WORK_PHONE]}
                    </div> 
                  }
                  </div>                 
                         
              </div>
              )
            }
          </div> 
          <div className="contactPhone">
            {(contact[CONST_CONTACT.CELL_PHONE] !== "" ) && (
              <div style={{display:"flex"}}>
              
              <div style={{width:"100%"}}>
               <Yourssvg2 style={{float:"left"}}/>  
               {contact[CONST_CONTACT.CELL_PHONE] !== "" &&
                    <div style={{marginLeft:"14%"}}>
                      {contact[CONST_CONTACT.CELL_PHONE]}
                    </div>
                  }
               
                </div>
               
                 
                         
              </div>
              )
            }
          </div>   
          </div>                                              
          <div className="contactEmail">
            {contact[CONST_CONTACT.WORK_EMAIL] !== "" && (
              <div >
                <EnvelopeFill className="icon-defaultNoLink" />                  
                  {edit ? (
                      <span style={{fontSize:"16px", paddingLeft:"10px"}}>
                        {`${contact[CONST_CONTACT.WORK_EMAIL].toLowerCase()}`}
                      </span>
                    ) : (
                          <a style={{fontSize:"16px", paddingLeft:"10px"}} 
                          className="showLink" 
                          href={"mailto:"+contact[CONST_CONTACT.WORK_EMAIL].toLowerCase()}
                          >
                            {contact[CONST_CONTACT.WORK_EMAIL].toLowerCase()}
                        </a>  
                    )}
              </div>
            )}
          </div>
          {!edit && (

          <div className="Chat">
          <a href={"im:<sip:"+contact[CONST_CONTACT.WORK_EMAIL].toLowerCase()+">"} target="_blank"><Yourssvg5></Yourssvg5></a>
          <a href={"https://teams.microsoft.com/l/chat/0/0?users="+contact[CONST_CONTACT.WORK_EMAIL].toLowerCase()} target="_blank" style={{marginLeft:"5%"}}><Yourssvg4></Yourssvg4></a>


            </div>   
          )} 
        </div>
      </div> 
        <div className="addFlex">
          {edit && <AddSection key="ContactAdd" />}
        </div>
        <div className="Profile-content">
        <div className="Profile-completion">
        <div style={{color:"#404040", alignItems: "baseline", fontSize:"14px", paddingTop:"2px",float:"left",width:"47%"}}>
        {contact[CONST_CONTACT.EF_LASTUPDATED] !== "" && (
            <div>
              {`Updated on ${contact[CONST_CONTACT.EF_LASTUPDATED]
                .split(" ")[0]
                .replace(",", "")}`}
            </div>
          )}   

           {
               
`Unique Profile Visits: ${viewsIn7Days} (Last 7 days), ${viewsIn30Days} (Last 30 days)`
                }    
                          
        </div>
        
        <div className="workingDays" style={{float:"left"}}>
       
          <div className="profileText" style={{fontSize:"14px", color:"#404040", paddingTop:"2px"}}>
          {contact[CONST_CONTACT.EF_LASTUPDATED] !== "" && (
            <div>
              {`Profile completion ${state.percentage}`}
              <Yourssvg/>
            </div>
          )}
          {contact[CONST_CONTACT.EF_LASTUPDATED] === "" && (
            <div>
              {`Profile completion ${state.percentage}  `}
              <Yourssvg/>
            </div>
          )}
          
          <Percentage/>


            {contact[CONST_CONTACT.EF_WORKDAYS] !== ""
              ? Object.values(
                  JSON.parse(contact[CONST_CONTACT.EF_WORKDAYS])
                ).includes(true)
                ? `Working days (${getWorkingDays(
                    contact[CONST_CONTACT.EF_WORKDAYS]
                  )})`
                : `Working Days Not Specified `
              : `Working Days Not Specified `}
                <Yourssvg/> 
               <br></br>
               
          </div>
        </div>
      
      </div>
    </div>
        {showPopup && (
          <NewWindow
            url="https://pod51133.outlook.com/ecp/PersonalSettings/EditAccount.aspx?chgPhoto=1&exsvurl=1"            
            onUnload={handleProfilePhotoUpload}
            center="screen"
            title="Edit Photo"
            name="Edit Photo"
            features={popupProps} 
          />
        )}
      </div>
    </>
  ) : (
    <div className="contact loading-contact">
      <div className="loading-contact-grid">
        <div className="loading-contact-item">
          <div className="contact-card-wrapper">
            <div className="profile-photo">
              <Skeleton circle={true} height={100} width={100} />
            </div>
            <div className="profile-info">
              <Skeleton count={5} />
            </div>
          </div>
          <div></div>
        </div>
        <div className="loading-contact-item">
          <Skeleton count={2} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
