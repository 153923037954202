import * as React from "react";

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import EditCard from "../../components/EditCard";
import DatePicker from "react-datepicker";
import { Calendar, InfoCircle } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import "react-datepicker/dist/react-datepicker.css";
import "./Career.scss";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Custom hooks */
import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

import { EDIT_CARD_PROPS, NOTIFICATION_MESSAGE, UpdateProperty } from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperty,
  getDefaultOptions,
  getSelectedOption,
  getTodaysDateTimeString
} from "../../utils/Helper";

const AddJob: React.FC<IUpdateCardProps> = ({
  header,
  props,
  show,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const { id } = props;
  const [jobTitle, setJobTitle] = React.useState<string>("");  
  const [company, setCompany] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [descLength, setDescLength] = React.useState(0);
  const [country, setCountry] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [asset, setAsset] = React.useState<string>("");
  const [capitalProject, setCapitalProject] = React.useState<string>("");
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state; 
  const { getAssets, getCapitalProjects,getCountry,getRoles } = useTaxonomyHandler(); 
  const [loading, setLoading] = React.useState(true);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [noJobTitle, setNoJobTitle] = React.useState<boolean>(false);
  const [noCompany, setNoCompany] = React.useState<boolean>(false);
  const [noStartDate, setNoStartDate] = React.useState<boolean>(false);
  const [assets, loadAssets] = React.useState<string[]>([]);
  const [capitalProjects, loadCapitalProjects] = React.useState<string[]>([]);
  const [countries, loadCountries] = React.useState<string[]>([]);
  const [role, setRole] = React.useState<string>("");
  const [roles, setRoles] = React.useState<string[]>([]);
  
  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      const assets = await getAssets();    
      const capitalProjects = await getCapitalProjects();     
      const countries = await getCountry();
      const roles = await getRoles();
      loadAssets(Object.keys(assets).sort());
      loadCapitalProjects(Object.keys(capitalProjects).sort());
      loadCountries(Object.keys(countries).sort())     
      setRoles(roles);
      setLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const folksonomyOptionsRoles = (inputValue: string) =>
      getDefaultOptions(        
          roles.filter((i) => i.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 30).map((j)=>j.slice(0,-1))
        );

    const promiseOptionsRoles = (inputValue) => {
      if (inputValue.length >= 2) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(folksonomyOptionsRoles(inputValue));
          }, 1000);
        });
      }
    };
    const LoadingMessage = (props) => {
      console.log(props.selectProps.inputValue);
      return (
        <div
          {...props.innerProps}
          style={props.getStyles("loadingMessage", props)}
        >
          {props.selectProps.inputValue.length >= 2
            ? "Loading job titles..."
            : `Please enter ${
                2 - props.selectProps.inputValue.length
              } more character(s)`}
        </div>
      );
    }; 

  const renderTooltip = (props) => (
    <Tooltip id="info-tooltip" {...props}>
      Capital Projects Job Description in the following template:
      <br />
      {`1. Name of project and year(s)`}
      <br />
      {`2. Your project role (e.g., BOM, FEDM, PM, Deal Lead, 
                        Engineering Manager, Discipline Engineer, 
                        Construction, etc.)`}
      <br />
      {`3. Main scope elements (e.g., FPSO, LNG, CCS, GTL, 
                        etc)`}
      <br />
      {`4. Directorate, Line of Business (e.g., Upstream, 
                        Deepwater, etc)`}
      <br />
      {`5. Location and Asset (if applicable)`}
    </Tooltip>
  );

  const main: JSX.Element =
    !loading && assets.length ? (
      <Container role="main" id="date-picker-container">
        <Form.Group>
          <Form.Label>
            Job Title <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="input"
            value={jobTitle}
            required
            isValid={jobTitle && !noJobTitle}
            isInvalid={!jobTitle && noJobTitle}
            onChange={(e) => setJobTitle(e.currentTarget.value)}
          />        
          <Form.Control.Feedback type="invalid">
            Please provide Job Title
          </Form.Control.Feedback>
          <Form.Text className="textMuted">
          Avoid the use of acronyms only e.g. type FEDM as Front End Development Manager FEDM. Correct spelling as required.
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Company Name <span className="required">*</span>
          </Form.Label>
          <Form.Control
            aria-label="Company Name"
            type="input"
            value={company}
            required
            isValid={company && !noCompany}            
            onChange={(e) =>{setCompany(e.currentTarget.value);setNoCompany(false)}}
          />
          <Form.Text className="required-message">
              {noCompany ? ` Please provide Company name` : ``}
            </Form.Text>  
       
          <Form.Text className="textMuted">
            Company in for which you did the assignment
          </Form.Text>
        </Form.Group>

        <div style={{ display: "flex" }}>
          <Form.Group className="date-picker-form-group">
            <Form.Label>
              Start Date <span className="required">*</span>
            </Form.Label>
            <DatePicker
              id="date-picker-start"
              placeholderText="MM/dd/yyyy"
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              scrollableMonthYearDropdown
              selected={
                startDate
                  ? !isNaN(Date.parse(startDate))
                    ? new Date(startDate)
                    : ""
                  : ""
              }
              maxDate={endDate ? new Date(endDate) : new Date()}
              showDisabledMonthNavigation
              onChange={(date) => {setStartDate(date);setNoStartDate(false);}}
              onFocus={(date)=>{setStartDate("");}}
            />
            <Calendar
              className="calendar icon-default"
              onClick={(e) => openStartDatePicker(e)}
            />
            <Form.Text className="required-message">
              {noStartDate ? `Please provide Start Date` : ``}
            </Form.Text>
          </Form.Group>

          <Form.Group className="date-picker-form-group">
            <Form.Label>End Date</Form.Label>
            <DatePicker
              id="date-picker-end"
              placeholderText="MM/dd/yyyy"
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              scrollableMonthYearDropdown
              selected={
                endDate
                  ? !isNaN(Date.parse(endDate))
                    ? new Date(endDate)
                    : ""
                  : ""
              }
              minDate={new Date(startDate)}
              maxDate={new Date()}
              showDisabledMonthNavigation
              onChange={(date) => setEndDate(date)}
              onFocus={(date)=>{setEndDate("");}}
            />
            <Calendar
              className="calendar icon-default"
              onClick={(e) => openEndDatePicker(e)}
            />
          </Form.Group>
        </div>
        <div style={{ display: "flex",gap: "10%" }}>
          <Form.Group style={{ width: "50%" }}>
            <Form.Label>Capital Project</Form.Label>
            <Select
              aria-label="Capital Project"
              style={{
                fontFamily: "ShellBook",
                fontSize: "small",
              }}
              options={getDefaultOptions(capitalProjects)}
              value={capitalProject && getSelectedOption(capitalProject)}
              onChange={(e) =>
                e ? setCapitalProject(e.value) : setCapitalProject("")
              }
              isClearable
              isSearchable
              components={{
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      {<div style={{color:"#1c1c1c"}}>Search Capital Project here</div>}
                    </components.Placeholder>
                  );
                },
              }}
            />
          </Form.Group>
          <Form.Group style={{ width: "50%" }}>
          <Form.Label>Role on Project</Form.Label>         
          <AsyncSelect
            aria-label="Role on Project"
            noOptionsMessage={() => 'No options'}
            components={{ LoadingMessage }}
            onChange={(e) => {setRole(e.value)}}
            placeholder="Search Roles.."
            defaultValue={getDefaultOptions([role])}
            cacheOptions           
            folksonomyOptionsRoles            
            loadOptions={promiseOptionsRoles}
          />    
        
          <Form.Text className="textMuted">
          Select your role on this project. Leave blank if your job title and project role are the same
          </Form.Text>
        </Form.Group>


        </div>

        <div style={{ display: "flex", gap: "10%" }}>
          <Form.Group style={{ width: "50%" }}>
            <Form.Label>Asset</Form.Label>
            <Select
              aria-label="Asset"
              style={{
                fontFamily: "ShellBook",
                fontSize: "small",
              }}
              options={getDefaultOptions(assets)}
              value={asset && getSelectedOption(asset)}
              onChange={(e) => (e ? setAsset(e.value) : setAsset(""))}
              isClearable
              isSearchable
              components={{
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                       {<div style={{color:"#1c1c1c"}}>Search asset here</div>}
                    </components.Placeholder>
                  );
                },
              }}
            />
          </Form.Group>

          <Form.Group style={{ width: "50%" }}>
            <Form.Label>Country</Form.Label>
            <Select
             aria-label="Country"
              style={{
                fontFamily: "ShellBook",
                fontSize: "small",
              }}
              options={getDefaultOptions(countries)}
              value={country && getSelectedOption(country)}
              onChange={(e) => (e ? setCountry(e.value) : setCountry(""))}
              isClearable
              isSearchable
              components={{
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      {<div style={{color:"#1c1c1c"}}>Search country here</div>}
                    </components.Placeholder>
                  );
                },
              }}
            />
          </Form.Group>
        </div>

        <Form.Group>
          <Form.Label>
            Job Description{" "}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <InfoCircle />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            required
            placeholder="Briefly describe your area(s) of responsiblities in this project/assignment"
            // pattern="^[a-zA-Z1-9].*"
            value={description}
            type="input"
            maxLength={512}
            onChange={(e) => {
              setDescription(e.currentTarget.value);
              setDescLength(e.currentTarget.value.length);
            }}
          ></Form.Control>
          <Form.Text className="textMuted">
            {descLength
              ? "" + (512 - descLength) + " characters remaining"
              : "512 characters max"}
          </Form.Text>
        </Form.Group>        
      </Container>
    ) : (
      <div className="p-5 text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );

  const addNewJob = React.useCallback(async () => {
    let isValidated:boolean = true;
    setNoJobTitle(false);
    setNoCompany(false);
    setNoStartDate(false);
    setValidated(false);
    if (!jobTitle || jobTitle.trim() === "") {
      setValidated(true);
      setNoJobTitle(true);
      isValidated =false;      
    }
    if (!company || company.trim() === "") {
      setValidated(true);
      setNoCompany(true);
      isValidated =false;      
    }
    if (!startDate || startDate === "" || isNaN(Date.parse(startDate))) {
      setValidated(true);
      setNoStartDate(true);
      isValidated =false;      
    } 
    if(isValidated) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
               getTodaysDateTimeString()
            )
          );

          const updateJobTitleResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.jobTitle.concat(id),
              jobTitle
            )
          );

          const updateEndDateResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.endDate.concat(id),
              endDate
                ? !isNaN(Date.parse(endDate))
                  ? new Date(endDate).toLocaleDateString("en-US")
                  : ""
                : ""
            )
          );

          const updateCompanyResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.company.concat(id),
              company
            )
          );

          const updateStartDateResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.startDate.concat(id),
              new Date(startDate).toLocaleDateString("en-US")
            )
          );

          const updateDescriptionResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.description.concat(id),
              description || ""
            )
          ); 

          const updateAssetResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.asset.concat(id),
              asset || ""
            )
          );

          const updateRoleResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.role.concat(id),
              role || ""
            )
          );

          const updateCapitalProjectResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.capitalProject.concat(id),
              capitalProject || ""
            )
          );

          const updateCountryResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.JobCard.country.concat(id),
              country || ""
            )
          );

          if (
            updateCompanyResponse.statusCode === 200 &&
            updateJobTitleResponse.statusCode === 200 &&
            updateEndDateResponse.statusCode === 200 &&
            updateStartDateResponse.statusCode === 200 &&
            updateDescriptionResponse.statusCode === 200 &&
            updateAssetResponse.statusCode === 200 &&
            updateRoleResponse.statusCode === 200 &&
            updateCapitalProjectResponse.statusCode === 200 &&
            updateCountryResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate({
              ...props,
              jobTitle: jobTitle,
              company: company,
              startDate: new Date(startDate).toLocaleDateString("en-US"),
              endDate: endDate
                ? !isNaN(Date.parse(endDate))
                  ? new Date(endDate).toLocaleDateString("en-US")
                  : ""
                : "",
              description: description || "",
              asset: asset || "",
              capitalProject: capitalProject || "",
              location: country || "",
              role:role||"",
            });
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setJobTitle("");
            setCompany("");
            setStartDate("");
            setEndDate("");
            setDescription("");
            setAsset("");
            setCapitalProject("");
            setCountry("");
            setRole("");
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          onUpdate({
            ...props,
            jobTitle: jobTitle,
            company: company,
            startDate: new Date(startDate).toLocaleDateString("en-US"),
            endDate: endDate
              ? !isNaN(Date.parse(endDate))
                ? new Date(endDate).toLocaleDateString("en-US")
                : ""
              : "",
            description: description || "",
            asset: asset || "",
            capitalProject: capitalProject || "",
            location: country || "",
          });
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setJobTitle("");
        setCompany("");
        setStartDate("");
        setEndDate("");
        setDescription("");
        setAsset("");
        setCapitalProject("");
        setCountry("");
        setRole("");
        showError(NOTIFICATION_MESSAGE.PreferenceError);
      }
    }
  }, [
    company,
    jobTitle,
    startDate,
    endDate,
    description,
    asset,
    capitalProject,
    country,
    role
  ]);

  const cancelHandler = () => {
    setShow(false);
    setJobTitle("");
    setCompany("");
    setStartDate("");
    setEndDate("");
    setDescription("");
    setAsset("");
    setCapitalProject("");
    setCountry("");
    setRole("");
  };

  const openStartDatePicker = (e) => {
    e.preventDefault();
    document.getElementById("date-picker-start").click();
  };

  const openEndDatePicker = (e) => {
    e.preventDefault();
    document.getElementById("date-picker-end").click();
  };

  return (
    <EditCard
      header={`Add a Project to ${header}`}
      show
      loading={loading}
      update={addNewJob}
      onHide={cancelHandler}
      body={main}
      showLarge={true}
    />
  );
};

export default AddJob;
