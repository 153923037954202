import React from 'react';

function Delete(props) {
	const title = props.title || "Remove the additional function";

	return (
		<svg height="18" width="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#E58271">
		<path d="M9 1.5c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5z m3.75 8.25h-7.5v-1.5h7.5v1.5z"/>
	</g>
</svg>
	);
};

export default Delete;