import * as React from "react";

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import EditCard from "../../components/EditCard";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

import { NOTIFICATION_MESSAGE, UpdateProperty } from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperty,
  removeHTML,
  getTodaysDateTimeString
} from "../../utils/Helper";

import { EditorState, RichUtils, Modifier, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditAboutMe: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const setDefaultState = () => {
    const html = props.AboutMe;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  };

  const onContentStateChange = (state) => {
    setEditorState(state);
  };

  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const [loading, setLoading] = React.useState(false);
  const [aboutMe, setAboutMe] = React.useState(props);
  const [editorState, setEditorState] = React.useState(setDefaultState());
  const editorStateRef = React.useRef(setDefaultState());
  editorStateRef.current = editorState;

  const updateAboutMe = React.useCallback(async () => {
    var html = { ...aboutMe };
    html.AboutMe = draftToHtml(
      convertToRaw(editorStateRef.current.getCurrentContent())
    ).trim();
    if (removeHTML(html.AboutMe) === "") html.AboutMe = "";
    if (removeHTML(props.AboutMe) !== removeHTML(html.AboutMe)) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const aboutMeUpdateResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.AboutMeCard.aboutMe,
              html.AboutMe
            )
          );

          if (
            aboutMeUpdateResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate(html);

            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);
            setAboutMe(props);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          onUpdate(html);
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);
        setAboutMe(props);
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else setShow(false);
  }, [aboutMe]);

  const main: JSX.Element = (
    <Container role="main" style={{paddingBottom:"20px"}}>
      {loading && (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Form.Group>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onContentStateChange}
          toolbar={{
            options: [
              "inline",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "link",
            ],
            inline: {
              options: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
              ],
            },
            fontFamily: {
              options: ["ShellBook"],
              className: "shellbook",
              component: undefined,
              dropdownClassName: undefined,
            },
          }}
        />
      </Form.Group>
    </Container>
  );

  const cancelHandler = () => {
    setShow(false);
    setAboutMe(props);
  };

  const clearHandler =(e) =>{
     var eState = EditorState.push(editorState, ContentState.createFromText(''),'remove-range');
     setEditorState(eState);
  }

 return (
    <EditCard
      header={props.AboutMe ? `Edit ${header}` : `Add ${header}`}
      show      
      loading={loading}
      onHide={cancelHandler}
      update={updateAboutMe}
      body={main}
      onClear={clearHandler}
      showClear ={true}
    />
  );
};

export default EditAboutMe;
