import * as React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Skeleton from "react-loading-skeleton";
import { ViewCardProps } from "../../custom-types";
import { Toggle } from "../Toggle";
import { ToolTip } from "../Tooltip";
import { Mentoring } from "../Styles";
import "./ViewCard.scss";
import { PencilFill } from "react-bootstrap-icons";

import {ReactComponent as Yourssvg} from "../../assets/images/percentage.svg";

export const ProfileCard: React.FC<ViewCardProps> = ({
  loading,
  header,
  subheader,
  body,
  toolTip,
  edit,
  editHandler,
  toggleHandler,
  mentor,
  isMentor,
  percentage,
  isLanguageEdit
}) => (
  <>
    <Card.Body>
      {loading ? (
        <Container className="loading-container">
          <Skeleton count={5} />
        </Container>
      ) : (
        <>
          <Card.Title className="header">
            {!mentor &&!isLanguageEdit ? (
              <div>              
                {header}
                {percentage&&(<Yourssvg/>)}
                {toolTip && <ToolTip tooltipMessage={toolTip} />}              
              </div>
            ) : (
              isLanguageEdit?(
                <div>              
               <span style={{width:"38%",float:"left"}}> {header}</span>
                {percentage&&(<Yourssvg/>)}
                {toolTip && <ToolTip tooltipMessage={toolTip} />}              
              </div> 
              ):(
              <Mentoring isMentor={isMentor}>Available to Mentor</Mentoring>
              )
            )}
          </Card.Title>
          {edit ? (
            
            <PencilFill className="edit icon-default" onClick={editHandler} />
        
          ) : (
            mentor && <Toggle  checked={isMentor} setToggle={toggleHandler} /> 
          )}
          {subheader && (
            <Card.Subtitle style={{fontSize:"14px"}}>{subheader}</Card.Subtitle>
          )}
          <Card.Text
            style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
          >
            {/* dangerouslySetInnerHTML={{__html: props.body}} */}
            {body}
          </Card.Text>
        </>
      )}
    </Card.Body>
  </>
);

export default ProfileCard;
