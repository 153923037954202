import * as React from "react";

import DropDownSelector from "../../components/DropDownSelector";
import CreatableSelect from 'react-select/creatable';

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import EditCard from "../../components/EditCard";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import {
  NOTIFICATION_MESSAGE,
  EDIT_CARD_PROPS,
  UpdateProperty,
} from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperties,
  updateProperty,
  getDefaultOptions,
  getTodaysDateTimeString,
  saveNewTerms
} from "../../utils/Helper";

const EditSkills: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const [loading, setLoading] = React.useState(false);
  const [invalidTerm, setInvalid]=React.useState(state.isInvalidTerm?state.isInvalidTerm:false);
  const [skills, setSkills] = React.useState(
    props[EDIT_CARD_PROPS.SkillsCard.skills]
      ? props
      : { [EDIT_CARD_PROPS.SkillsCard.skills]: "" }
  );  
  const [terms, setNewTerms] = React.useState("");

  const [termRequest, setTermRequest] = React.useState(false);

  const updateSkills = React.useCallback(async () => {
    if(!invalidTerm){
    if (
      props[EDIT_CARD_PROPS.SkillsCard.skills] !==
      skills[EDIT_CARD_PROPS.SkillsCard.skills]
    ) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const skillsUpdateResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.multiple,
            updateProperties(
              currentUser.LoginName,
              EDIT_CARD_PROPS.SkillsCard.skills,
              skills[EDIT_CARD_PROPS.SkillsCard.skills].split("|")
            )
          );

        
          if (
            skillsUpdateResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate(skills);
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);
            setSkills(props);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }

          if(terms!=""){

            await saveNewTerms({Title:currentUser.Email,Skills:terms});
            }
  
        } else {
          onUpdate(skills);
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);
        setSkills(props);
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else 
    {setShow(false);
      if(terms!=""){
        const UpdateResponse: SP.ResponseInfo =  await saveNewTerms({Title:currentUser.Email,Skills:terms});

       if( UpdateResponse.statusCode === 201 )
       {
    showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
       }
      }
    }
  }else{
    setInvalid(true); 
  }
  }, [skills,terms]);

  const onChange = (e) => {
    invalidateTerm(e);
    e
      ? setSkills({
        [EDIT_CARD_PROPS.SkillsCard.skills]: e
          .map((skill) => skill.value)
          .join("|"),
      })
      : setSkills({ [EDIT_CARD_PROPS.SkillsCard.skills]: "" });
  };

  const handleTerm= (e) => {

    setNewTerms(e.map((term) => term.value).join("|"));
  };



  const invalidateTerm = (options) => {
    setInvalid(false); 
    if(options) {
        options.forEach((o) => {
        if (o.color && o.color == 'red'){  
          setInvalid(true);
        }
      });
    }
  }
  const cancelHandler = () => {
    setShow(false);
    setSkills(props);
  };

  const main: JSX.Element = (
    <Container role="main">
      {loading && (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Form.Group className="mb-4">
        <DropDownSelector
          title={header}
          defaultValue={
            skills[EDIT_CARD_PROPS.SkillsCard.skills] &&
              skills[EDIT_CARD_PROPS.SkillsCard.skills] !== ""
              ? getDefaultOptions(
                skills[EDIT_CARD_PROPS.SkillsCard.skills].split("|")
              )
              : ""
          }
          setProp={(e) => onChange(e)}
        />
        <div style={{ marginTop: "10px", fontSize:"14px" }}>
        Please delete highlighted entries, reselect the term from the dropdown list and save. If preferred term is unavailable, please <a className="addTermSetLink" title="Request new terms"  href="#" onClick={(e)=>setTermRequest(true)} >Request new terms</a> to be added to your profile. You will receive a confirmation email upon completion of your request.
          {/* <a className="addTermSetLink" title="Request to add Skills Term(s)" href="mailto:KM-Support@shell.com?subject=Request to add Skills Term(s)&body=Please add the following term(s):">Request to add Skills Term(s)</a> */}
        </div>

 

        <div style={{ marginTop: "5%", fontSize:"14px", }}>


{termRequest&&
<div>

<CreatableSelect
 noOptionsMessage={() => null}
placeholder="Please type your new term here..."
components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null,Menu: () => null }}
isMulti
onChange={handleTerm}
style={{marginTop: "1%"}}
isClearable
/>
        
        </div>
}
          
        </div>



        
      </Form.Group>
    </Container>
  );

  return (
    <EditCard
      header={
        props[EDIT_CARD_PROPS.SkillsCard.skills]
          ? `Edit ${header}`
          : `Add ${header}`
      }
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateSkills}
      body={main}
      showLarge={false}
      newTermRequest={termRequest}
    />
  );
};

export default EditSkills;
