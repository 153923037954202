import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import logo from "../../assets/images/logo.png";
import "./PageLoader.scss";
import { useLocation, useHistory } from "react-router-dom";

const PageLoader = ({ profile }) => {
  const [message, setMessage] = useState<string>(
    profile
      ? `Fetching Profile details for ${profile}...`
      : "Loading Enterprise Finder 2.0..."
  );
  const loadingMessages = profile
    ? ["Just a moment..."]
    : [
        "Please wait...",
        "Authenticating...",
        "Fetching your profile details...",
        "Almost done...",
      ];

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location.pathname.includes("/")) {
      history.push("/");
    }
    startLoadingMessages();
  }, []);

  return (
    <div id="page-loader-wrapper">
      <div id="page-loader">
        <img src={logo} />
        <div className="p-5 text-center">
          <Spinner animation="border" />
        </div>
        <div className="message">{message}</div>
      </div>
    </div>
  );

  function startLoadingMessages() {
    loadingMessages.map((o, i) => {
      setTimeout(
        () => {
          setMessage(loadingMessages[i]);
        },
        i === 0 ? 3000 : i * 3000
      );
    });
  }
};

export default PageLoader;
