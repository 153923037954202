import React from "react";

import Switch from "react-switch";

interface ToggleProps {
  checked: boolean;
  setToggle: (checked: boolean) => {};
}

export const Toggle: React.FC<ToggleProps> = ({ checked, setToggle }) => (
  <div className="edit" style={{ right: "2.5rem" }}>
    <Switch
      aria-label="Available to Mentor"
      onColor="#0099BA"
      handleDiameter={15}
      onHandleColor="#FFFFFF"
      checked={checked}
      onChange={(checked) => setToggle(checked)}
      height={22.5}
      width={45}
      uncheckedIcon={
        <div 
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "white",
            fontFamily: "Arial",
            fontSize: 12,
            paddingRight: 1,
          }}
        >
           Off
        </div>
      }
      checkedIcon={
        <div 
            style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "white",
            fontFamily: "Arial",
            fontSize: 12,
            paddingRight: 1,
          }}
        >
          On
        </div>
      }
      className="react-switch"
      id="icon-switch"
    />
  </div>
);
