import * as React from "react";
import { Link } from "react-router-dom";
/** Context */
import { Context } from "../../contexts/Context";
/** Utils */
import helpers from "../../utils/Helper";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Presentation */
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ViewCard from "../../components/ViewCard/ViewCard";
import "./OrgChart.scss";

/** Bootstrap Presentation */
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { IUser } from "../../custom-types";
import { type } from "os";

const OrgChart: React.FC<ICardComponentProps> = ({ header }) => {
  const [loading, setLoading] = React.useState(true);
  const { state } = React.useContext(Context);
  const { currentUser, userProps, showError } = state;
  const { Email } = currentUser;
  const [lineManager, setLineManager] = React.useState<IUser>(null);

  const [reportees, setResportees] = React.useState<IUser[]>(null);
  const [peers, setPeers] = React.useState<IUser[]>(null);
  const [extendedManagers, setExtendedManagers] = React.useState<IUser[]>(null);
  const [show, setShow] = React.useState(false);
  const [peersValues,showPeers] = React.useState(true);
  const [repoteesValues,showReportees] = React.useState(true);
  
  const [mangerValues,showManager] = React.useState(true);

  React.useEffect(() => {
    loadBasicOrgChart();
  }, [userProps]);

  React.useEffect(() => {
    if(show===false)
    {
      showPeers(false)
      showReportees(false)
    }
  }, [show]);
 
  function toggleHeader(value){
    if(value==="Peers"){
      showPeers(!peersValues)
    }
    if(value==="Reportees"){
      showReportees(!repoteesValues)
    }
  } 

  const showOrgChart = async () => {
    setShow(true);
    showPeers(true);
    showReportees(true);
    setLoading(true);
    try {
      if (helpers.IsObj(userProps) && helpers.IsObj(currentUser)) {
        let directReports: IUser[] = [];
        let peers: IUser[] = [];
        let extendedMangers:IUser[] = [];
        if (userProps.d.DirectReports.results.length) {
          const result = userProps.d.DirectReports.results.map(async (acc) => {
            try {
              const user = await helpers.getUser(acc);
              const { Title, Email ,Id} = user.data,
                Img = `${helpers.hostweburl}/_layouts/15/userphoto.aspx?size=L&accountname=${Email}`,
                LoginName = encodeURIComponent(user.data.LoginName);
                const name=Title.length>30?Title.slice(0,30).concat("..."):Title;              
                const jobTitle=await helpers.getUserInfo(Id)  ;   
              return { name, LoginName, Email, Img,jobTitle };
            } catch (error) {
              const e = new ErrorEvent("error", {
                message: error,
              });
              window.dispatchEvent(e);
            }

            
          });
          directReports = await Promise.all(result);
         
          setResportees(directReports);
        } else {
          setResportees([]);
        }

        if (userProps.d.Peers.results.length) {
          const resultPeers = userProps.d.Peers.results.map(async (acc) => {
            try {
              const user = await helpers.getUser(acc);
              const { Title, Email,Id } = user.data,
                Img = `${helpers.hostweburl}/_layouts/15/userphoto.aspx?size=L&accountname=${Email}`,
                LoginName = encodeURIComponent(user.data.LoginName);
                const name=Title.length>30?Title.slice(0,30).concat("..."):Title;
                const jobTitle=await helpers.getUserInfo(Id)  ;         
                  return { name, LoginName, Email, Img,jobTitle };
            } catch (error) {
              const e = new ErrorEvent("error", {
                message: error,
              });
              window.dispatchEvent(e);
              
            }

            
          });


          peers = await Promise.all(resultPeers);
         
         peers.push(

          {
Title:currentUser["Title"],
LoginName:encodeURIComponent(currentUser["LoginName"]),
Email:currentUser["Email"],
Img:`${helpers.hostweburl}/_layouts/15/userphoto.aspx?size=L&accountname=${currentUser["Email"]}`,
jobTitle:await helpers.getUserInfo(currentUser["Id"]),
name:currentUser["Title"]



          }         )
        
          setPeers(peers);
        } else {
          setPeers([]);
        }

        if (userProps.d.ExtendedManagers.results.length) {
          const resultExtendedMangers = userProps.d.ExtendedManagers.results.map(async (acc) => {
            try {
              const user = await helpers.getUser(acc);
              const { Title, Email,Id } = user.data,
                Img = `${helpers.hostweburl}/_layouts/15/userphoto.aspx?size=L&accountname=${Email}`,
                LoginName = encodeURIComponent(user.data.LoginName);
                const name=Title.length>30?Title.slice(0,30).concat("..."):Title;
                const jobTitle=await helpers.getUserInfo(Id)  ;         
                  return { name, LoginName, Email, Img,jobTitle };
            } catch (error) {
              const e = new ErrorEvent("error", {
                message: error,
              });
              window.dispatchEvent(e);
              
            }

            
          });
          extendedMangers = await Promise.all(resultExtendedMangers);

          
         
         
          setExtendedManagers(extendedMangers);
        } else {
          setExtendedManagers([]);
        }
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const loadBasicOrgChart = async () => {
    try {
      if (helpers.IsObj(userProps)) {
        let LoginName = userProps.d.UserProfileProperties.results.filter(
            (prop) => prop.Key === "Manager"
          )[0].Value;
        const accountProps = await helpers.getUser(LoginName),
         { Title, Email,Id } = accountProps.data,
          Img = `${helpers.hostweburl}/_layouts/15/userphoto.aspx?size=L&accountname=${Email}`;
        LoginName = encodeURIComponent(LoginName);
       const name=Title.length>30?Title.slice(0,30).concat("..."):Title;
        const jobTitle=await helpers.getUserInfo(Id); 
        setLineManager({ Title, Email, LoginName, Img, jobTitle,name});
        setLoading(false);
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  const basicOrgChart: JSX.Element = !loading && lineManager && (
    <Container role="main">
      <Row style={{ marginTop: "20px" }}>
        <Col xs={"auto"}>
          <Image
            style={{ width: "50px", height: "50px" ,float:"left"}}
            src={lineManager.Img}
            alt={lineManager.Title}
            
            roundedCircle
          />
        </Col>
        <Col>
          <Link
            className="sme-name sme-underline"
            target="_blank"
            to={`?accountname=${lineManager.LoginName}`}
          >
            {lineManager.name}
          </Link>

          <br/>                        
                        <span >{lineManager.jobTitle}</span>

        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ marginTop: "3vh", marginBottom: "0" }}>
            <a className="showLink"
              onClick={showOrgChart}
            >
              Show more
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );

  const cancelHandler = () => {
    setShow(false);
  };

  return (
    <>
      <ViewCard
        loading={loading}
        header={"Org Chart"}
        subheader={"Supervisor"}
        body={basicOrgChart}
      />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={true}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        role="dialog"
        aria-label="Organization Chart"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "ShellBook",
              fontSize: "medium",
            }}
          >
            <h1 style={{fontSize:"16px", lineHeight:"1.2", marginTop:"2px"}}>Organization Chart</h1>
          </Modal.Title>
        </Modal.Header>
        {loading && (
          <div className="skeleton-loading">
            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
              <div className="col-8 sme-details-wrapper">
                <div className="sme-details">
                  <span className="star-img">
                    <Skeleton height={50} width={500}></Skeleton>
                  </span>
                </div>
              </div>
            </SkeletonTheme>
            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
              <div
                className="col-8 sme-details-wrapper"
                style={{ marginLeft: "10%" }}
              >
                <div className="sme-details">
                  <span className="star-img">
                    <Skeleton height={50} width={500}></Skeleton>
                  </span>
                </div>
              </div>
            </SkeletonTheme>
            <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
              <div
                className="col-8 sme-details-wrapper"
                style={{ marginLeft: "20%" }}
              >
                <div className="sme-details">
                  <span className="star-img">
                    <Skeleton height={50} width={500}></Skeleton>
                  </span>
                </div>
              </div>
            </SkeletonTheme>
          </div>
        )}
        {!loading && reportees && (
          <div>
            <Modal.Body>            

              <div
                className=" sme-details-wrapper"
                style={{ marginLeft: "25%",width:"50%"}}
              >
               
              {extendedManagers &&(
               extendedManagers.filter(Boolean).map((z: any, index: any) => {
                    return ( 
                      <div style={{width:"100%" }}>
                        <div key={index} className="sme-details" style={{maxWidth:"100%",width:"100%",marginBottom:"0px",border:"1px solid",float:"left"}}>
                         
                        <span className="star-img"></span>
                        <img src={z.Img} alt={z.Title} style={{float:"left"}} />
                        <Link
                          className="sme-name sme-underline"
                          target="_blank"
                          to={`?accountname=${z.LoginName}`}
                          style={{fontFamily: "ShellMedium", fontSize: "16px"}}
                        >
                          {z.name}
                        </Link>
                        <br/>
                    
                        
                        <span style={{fontFamily: "Arial", fontSize: "14px",float:"left"}} >{z.jobTitle}</span>
                       
                      </div>

                      <div className="connector" style={{float:"left"}}>

</div>

                      </div>

                    );
                  })
                )}


              </div>
              <div
                className=" sme-details-wrapper"
                style={{border:"1px solid",paddingLeft:"1%"  }}
              >
                  <div className="sme-details sme-header">
<span style={{fontWeight:"bolder"}}>{currentUser["Title"]}'s Peers ({peers.length})</span>

                  </div>
                
              {peersValues &&(
               peers.filter(Boolean).map((z: any, index: any) => {
                    return ( 

                      (currentUser["Title"]==z.name?
                        <div key={index} className="sme-details" style={{border:"1px solid",backgroundColor:"rgba(0, 151, 187, 0.1)"}}>
                         
                        <span className="star-img"></span>
                        <img src={z.Img} alt={z.Title} style={{float:"left"}} />
                        <Link
                          className="sme-name sme-underline"
                          target="_blank"
                          to={`?accountname=${z.LoginName}`}
                          style={{fontFamily: "ShellMedium", fontSize: "16px"}}
                        >
                          {z.name}
                        </Link>
                        <br/>
                    
                        
                        <span style={{fontFamily: "Arial", fontSize: "14px",float:"left"}} >{z.jobTitle}</span>
                      </div>
                      :  <div key={index} className="sme-details" style={{border:"1px solid"}}>
                         
                      <span className="star-img"></span>
                      <img src={z.Img} alt={z.Title} style={{float:"left"}} />
                      <Link
                        className="sme-name sme-underline"
                        target="_blank"
                        to={`?accountname=${z.LoginName}`}
                        style={{fontFamily: "ShellMedium", fontSize: "16px"}}
                      >
                        {z.name}
                      </Link>
                      <br/>
                  
                      
                      <span style={{fontFamily: "Arial", fontSize: "14px",float:"left"}} >{z.jobTitle}</span>
                    </div>
                      )
                    );
                  })
                )}
              </div>

              <div className="connector" style={{float:"left"}}>

</div>       
              <div
                  className=" sme-details-wrapper"
                  style={{ border:"1px solid",paddingLeft:"1%" }}
                >
                  <div className="sme-details sme-header">
<span style={{fontWeight:"bolder"}} >{currentUser["Title"]}'s Direct Reports ({reportees.length})</span>

                  </div>
                   
              {!loading && reportees && reportees.length ? (                
                 repoteesValues && (
                  reportees.filter(Boolean).map((z: any, index: any) => {
                    return (
                      
                      <div key={index} className="sme-details" style={{border:"1px solid"}}>
                        
                        <span className="star-img"></span>
                        <img src={z.Img} alt={z.Title} style={{float:"left"}} />
                        <Link
                          className="sme-name sme-underline"
                          target="_blank"
                          to={`?accountname=${z.LoginName}`}
                          style={{fontFamily: "ShellMedium", fontSize: "16px",}}
                        >
                          {z.name}
                        </Link>
                        
                        <br/>
                        <span style={{fontFamily: "Arial", fontSize: "14px",float:"left"}} >{z.jobTitle}</span>
                      </div>
                      
                    );
                    
                  })

                 )
                  
               
              ) : (
                repoteesValues && (
                  <div className="sme-details">No direct reports</div>
                )
              
              )}
               </div>
            </Modal.Body>
          </div>
        )}
        <Modal.Footer style={{paddingBottom:"35px" }}>
          <Button
            variant="outline-secondary"
            size="lg"
            style={{ margin: "auto"}}
            onClick={() => cancelHandler()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrgChart;
