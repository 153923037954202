import React from "react";
import AsyncSelect from "react-select/async";
import useTaxonomyHandler from "../utils/hooks/TaxonomyHandler";
/** Context */
import { Context } from "../contexts/Context";

import helpers, { 
  getDefaultOptions,
} from "../utils/Helper";


interface DropdownProps {
  title: string;
  setProp: (e) => void;
  defaultValue: any;  
}

export default function DropDownSelector({
  title,
  setProp,
  defaultValue  
}: DropdownProps) {

  const { getSkills,getCred,getInterests,getProf,getResp,getSchools } = useTaxonomyHandler();
  const [skills, setFolksonomy] = React.useState<string[]>([]);   
  const [taxonomy, setTaxonomy] = React.useState(false);   
  const { state } = React.useContext(Context);


  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      state.isInvalidTerm = false;  
      if(title==="Skills")
      {
      const folksonomyData = await getSkills();
      setInvalidTerm(folksonomyData);
      setFolksonomy(Object.keys(folksonomyData).sort());
      setTaxonomy(true);
      }
      if(title==="interests")
      {
      const folksonomyData = await getInterests();
      setInvalidTerm(folksonomyData);
      setFolksonomy(Object.keys(folksonomyData).sort());
      setTaxonomy(true);
      }

      if(title==="Education")
      {
      const folksonomyData = await getSchools();      
      setFolksonomy(folksonomyData);
      setTaxonomy(false);
      }

      if(title==="responsibilities")
      {
        const folksonomyData = await getResp();
        setInvalidTerm(folksonomyData);
        setFolksonomy(Object.keys(folksonomyData).sort());
        setTaxonomy(true);
      }

      if(title==="Industry Body and Professional Membership")
      {
      const folksonomyData = await getProf();
      setInvalidTerm(folksonomyData);
      setFolksonomy(Object.keys(folksonomyData).sort());
      setTaxonomy(true);
      }

      if(title==="Accreditation and Certification")
      {
      const folksonomyData = await getCred();
      setInvalidTerm(folksonomyData);
      setFolksonomy(Object.keys(folksonomyData).sort());
      setTaxonomy(true);
      }     
    } catch (err) {
      console.log({ err });
    }
  };

  const setInvalidTerm = (folksonomyData)=>{
    if(folksonomyData && defaultValue){          
      defaultValue.forEach((o) => {
        if (o){
          var term = Object.keys(folksonomyData).includes(o.label);
          if(term == null || term == undefined || term == false){
                o.color='red';  
                state.isInvalidTerm = true;         
          }
        }
      });
    }
  }

  const LoadingMessage = (props) => {
    console.log(props.selectProps.inputValue);
    return (
      <div
        {...props.innerProps}
        style={props.getStyles("loadingMessage", props)}
      >
        {props.selectProps.inputValue.length >= 2
          ? `Loading ${title}...`
          : `Please enter ${
              2 - props.selectProps.inputValue.length
            } more character(s)`}
      </div>
    );
  };

  const options = (input: string) =>   
      getDefaultOptions(
        skills
          .filter((i) => i.toLowerCase().includes(input.toLowerCase()))
          .slice(0, 30).map((j)=>j.slice(0,-1))
      );
      const taxonomyOptions = (input: string) =>   
      getDefaultOptions(
        skills
          .filter((i) => i.toLowerCase().includes(input.toLowerCase()))
          .slice(0, 30)
      );
  const promiseOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        resolve(options(inputValue));        
      });
    } 
  };

  const taxonomyPromiseOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        resolve(taxonomyOptions(inputValue));        
      });
    } 
  };

  const colourStyles = {
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color? data.color: 'red',
    }),
  };
  return (
    taxonomy?(
    <AsyncSelect
      aria-label={title}
      noOptionsMessage={() => 'No options'}
      isMulti
      autoFocus
      components={{ LoadingMessage }}
      onChange={(e) => setProp(e)}
      isClearable
      placeholder={`Search ${title}...`}
      defaultValue={defaultValue}
      cacheOptions
      options
      styles={colourStyles}      
      loadOptions={taxonomyPromiseOptions}
    />)
    :
    (
      <AsyncSelect
        aria-label={title}
        isMulti
        autoFocus
        styles={colourStyles}
        components={{ LoadingMessage }}
        onChange={(e) => setProp(e)}
        isClearable
        placeholder={`Search ${title}...`}
        defaultValue={defaultValue}
        cacheOptions
        options
        loadOptions={promiseOptions}
      />)
  );
}
