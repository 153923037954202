import * as React from 'react'
const useSuccessHandler = (initialState: string | null) => {
	const [success, setSuccess] = React.useState(initialState)
	const showSuccess = (successMessage: string | null) => {
		setSuccess(successMessage)
		window.setTimeout(() => {
			setSuccess(null)
		}, 3000)
	}
	return { success, showSuccess }
}
export default useSuccessHandler
