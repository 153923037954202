import * as React from "react";
/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";
/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
/** Context */
import { Context } from "../../contexts/Context";
import EditCard from "../../components/EditCard";
import { NOTIFICATION_MESSAGE, UpdateProperty,EDIT_CARD_PROPS, DELEGATE } from "../../utils/Consts";
/** Utils */
import helpers, {updateProfile, updateProperty,getTodaysDateTimeString} from "../../utils/Helper";
const EditDelegate: React.FC<IUpdateCardProps> = ({
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
const { state } = React.useContext(Context);
const [loading, setLoading] = React.useState(false);
const { showSuccess, showError, currentUser } = state;
const [delegate, setDelegate] = React.useState(props);
const [isInvalid, setInvalid] = React.useState(false);

const updateContact = React.useCallback(async () => {
    try {
      if(delegate.includes("@shell.com")){
        setLoading(true);  
        let delegateUser = "";   
        if(delegate != "") 
          delegateUser = "i:0#.f|membership|" + delegate;
        else
          delegateUser = "";
        const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            EDIT_CARD_PROPS.LastUpdated,
            getTodaysDateTimeString()
          )
        );
        const delegateUpdateResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            EDIT_CARD_PROPS.Delegate,
            delegateUser
          )
        );
        if (delegateUpdateResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200 ) {
          onUpdate(delegate);
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        } else {
          onUpdate(props);
          setLoading(false);
          setInvalid(true);
          setDelegate(props);         
        }
      }else{
         setInvalid(true);
      }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setDelegate(props);
      onUpdate(props);
      showError(NOTIFICATION_MESSAGE.Error);
    }
  }, [delegate]);

  const cancelHandler = () => {
    setShow(false);
    setDelegate(props);
  };
   const main: JSX.Element = 
    <Container>
      <Form.Group>
        <Form.Label style={{fontFamily: "ShellBook",fontSize: "small"}}>
        Please enter the delegate user email.
        </Form.Label>
        <Form.Control 
          type="input" 
          value={delegate}
          onChange={(e) =>{setDelegate(e.target.value)}}  
          isValid={!isInvalid}
          isInvalid={isInvalid}        
        />
        <Form.Control.Feedback type="invalid">
          Please enter the valid delegate user email.
        </Form.Control.Feedback>                
      </Form.Group>
    </Container>
return (
    <EditCard
      header="Add/Edit Delegate User"
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateContact}
      body={main}
    />
  );
}

export default EditDelegate;