import * as React from "react";

import "../Skills/Skills.scss";
import Edit from "./EditActivities";

/** Context */
import { Context } from "../../contexts/Context";



import ViewCard from "../../components/ViewCard/ViewCard";

/** Constants */
import { EDIT_CARD_PROPS,CONTACT } from "../../utils/Consts";

/** Utils */
import helper from "../../utils/Helper";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

export const Yammer: React.FC<ICardComponentProps> = ({
    header,
    tooltip,
    edit,
    percentage
  }) => {
    const { state, dispatch } = React.useContext(Context);
    const { userProps, showError } = state;
    const [showModal, setShowModal] = React.useState(false);
    const { getCardProps } = useCardHandler(CONTACT, userProps);
  const [yammerID, setyammerID] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  const handleUpdate = React.useCallback(
    (val) => {
      setyammerID(val);
    },
    [yammerID]
  );

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setyammerID(props["EF-MyYammerId"]);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };
  React.useEffect(() => updateSection(), [yammerID]);
  const updateSection = () => {
    !yammerID.length &&
    !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
          type: "ADD_SECTION",
          payload: {
            title: `${header}`,
            showPercentage:false,
            handler: () => setShowModal(!showModal),
          },
        })
      : yammerID.length &&
        state.sectionProps.find((section) => section.title === header) &&
        dispatch({
          type: "REMOVE_SECTION",
          payload: { 
            title: `${header}`,
             showPercentage:false,
            },
        });
  };
  const main:JSX.Element=yammerID && yammerID !== ""&&(
      <>
        <div className="yammerId">                 
          <a
            key={1}
            className="tag tag-single"
            href={yammerID}
            target="_blank"
          >
          <span style={{fontSize:"16px",color:"#000FFF"}}>Yammer Discussions</span>
        </a>          
      </div> 
      </>  
  );


    return showModal ? (
        <Edit
          header={header}
          props={yammerID}
          show={showModal}
          setShow={setShowModal}
          onUpdate={handleUpdate}
    
        />
      ) : main ? (
        <ViewCard
          header={""}
          body={main}
          edit={edit}
          loading={loading}
          editHandler={() => setShowModal(!showModal)}         
          
        />
      ) : null;
    };
    
    export default Yammer;