import * as React from "react";

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import EditCard from "../../components/EditCard";
import { EditorState, RichUtils, Modifier, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import {
  NOTIFICATION_MESSAGE,
  EDIT_CARD_PROPS,
  UpdateProperty,
} from "../../utils/Consts";

/** Utils */
import helpers, { updateProfile, updateProperty, getTodaysDateTimeString,removeHTML } from "../../utils/Helper";

const EditFeaturedContent: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {

  const setDefaultState = () => {
    const html = props[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent];
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  };

  const onContentStateChange = (state) => {
    setEditorState(state);
  };
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const [loading, setLoading] = React.useState(false);
  const [featuredContent, setFeaturedContent] = React.useState(
    props[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]
      ? props
      : { [EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]: "" }
  );

  const [editorState, setEditorState] = React.useState(setDefaultState());
  const editorStateRef = React.useRef(setDefaultState());
  editorStateRef.current = editorState;

  const updateFeaturedContent = React.useCallback(async () => {

    var html = { ...featuredContent };
    html[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] = draftToHtml(
      convertToRaw(editorStateRef.current.getCurrentContent())
    ).trim();
    
    if (removeHTML(html[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]) === "") html[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] = "";
    if (removeHTML(props[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]) !== removeHTML(html[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent])) {
    
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const featuredContentUpdateResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.FeaturedContentCard.featuredContent,
              html[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]
            )
          );

          if (
            featuredContentUpdateResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate(html);
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);
            setFeaturedContent(props);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          onUpdate(html);
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);
        setFeaturedContent(props);
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else setShow(false);
  }, [featuredContent]);

  const cancelHandler = () => {
    setShow(false);
  };

  const main: JSX.Element = (
    <Container role="main">
      {loading && (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Form.Group>
      <Form.Text>
          Enter your work(s) in publication(s).To add link,please click on the link button below.
        </Form.Text>
        <Editor        
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onContentStateChange}
          
              
          toolbar={{
            options: [              
              "link"
            ]
          
            
          }}
        />
        <Form.Text>
        Separate multiple items with ; (e.g. https://eu001-sp.shell.com/sites/aaaab1732/ ;https://lfe.shell.com/)
        </Form.Text>


      </Form.Group>
   
    </Container>
  );

  return (
    <EditCard
      header={
        props[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]
          ? `Edit ${header}`
          : `Add ${header}`
      }
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateFeaturedContent}
      body={main}
    />
  );
};

export default EditFeaturedContent;
