import * as React from "react";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from 'history';


import helpers,{checkProfileViews} from "../Helper";

const useLoginHandler = (id: string) => {
  const [currentUser, setCurrentUser] = React.useState({});
  let appInsightsLoad=true;

  React.useEffect(() => {
    getUser();
  }, [id]);

  const getUser = async () => {
    const currentUser = id
      ? await helpers.getProfileUser(id)
      : await helpers.getLoggedInUser();
      console.log(currentUser);
    setCurrentUser(currentUser);

    const loggedInUser=await helpers.getLoggedInUser();

    if(id)
    {
   await checkProfileViews(id,loggedInUser["Id"],"false");
  
    }

     //tracking user information in application insight
     if(appInsightsLoad){

   const reactPlugin = new ReactPlugin();

   const appInsights = new ApplicationInsights({
   
     config: {
   //acceptance
      instrumentationKey: "80d12c14-b0ca-46cc-bc83-39bde64e26dd",
//prod
   //instrumentationKey: "0de68d4c-b7ef-469e-8653-e601a1b44c8f",

       extensions: [reactPlugin],
   
       extensionConfig: {
   
         [reactPlugin.identifier]: { history: createBrowserHistory },
   
       },
   
     },
   
   });
   
   appInsights.loadAppInsights();
   
   appInsights.trackEvent({
   
     name:"Expertise Finder.",
   
     properties: {
   
       ApplicationName:"Expertise Finder",
   
       Component:"EF Profile",
   
       PageUrl: window.location.href,
   
       UserName: id?id:loggedInUser["Email"]
   
     }
   
   });

   appInsightsLoad=false;
   
  }
      /*AppInsights.downloadAndSetup({ instrumentationKey: helpers.appinsightsinstrumentationKey, maxBatchSizeInBytes: 0 });
   
      AppInsights.trackPageView(
       document.title,
       loggedInUser["Email"],
       { userEmail: id},
       null,
       );*/
      
  };

  return currentUser;
};

export default useLoginHandler;
