import React from 'react';

function Add(props) {
	const title = props.title || "Add an additional Function";

	return (
		<svg height="18" width="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="#17a2b8">
		<path d="M9 1.5c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5z m3.75 8.25h-3v3h-1.5v-3h-3v-1.5h3v-3h1.5v3h3v1.5z"/>
	</g>
</svg>
	);
};

export default Add;