import * as React from "react";
import CreatableSelect from 'react-select/creatable';
import { EDIT_CARD_PROPS,NOTIFICATION_MESSAGE, UpdateProperty } from "../../utils/Consts";
/** Context */
import { Context } from "../../contexts/Context";
import EditCard from "../../components/EditCard";
import AsyncSelect from "react-select/async";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler"; 
 
/** Utils */
import helpers, { 
    updateProfile,
    updateProperty,
    getDefaultOptions,
    getTodaysDateTimeString,
    saveNewTerms
} from "../../utils/Helper";
const AddEducation = ({
    header,
    props,
    show,
    setShow,
    onUpdate,
}) => {
    const { id } = props;
    const {getSchools, getCountry,getDegree } = useTaxonomyHandler();
    const { state } = React.useContext(Context);
    const { showSuccess, showError, currentUser } = state;    
    const [folksonomy, setFolksonomy] = React.useState<string[]>([]); 
    const [folksonomyDegree, setFolksonomyDegree] = React.useState<string[]>([]); 
    const [loading, setLoading] = React.useState(false);
    const [degreeValue, setDegreeValue] = React.useState<string>("");
    const [schoolValue, setSchoolValue] = React.useState<string>("");
    const [countryValue, setCountryValue] = React.useState<string>("");
    const [countries, loadCountries] = React.useState<string[]>([]);
    const [termsDegree, setNewTermsDegree] = React.useState("");
    const [termsSchool, setNewTermsSchool] = React.useState("");

  const [termRequest, setTermRequest] = React.useState(false);
    React.useEffect(() => {
        onLoad();
    }, []);
   const onLoad = async () => {
    try {     
        const folksonomyData = await getSchools();
        const folksonomyDataDegree = await getDegree();
        const countries=await getCountry();
        loadCountries(Object.keys(countries).sort())
        setFolksonomy(folksonomyData);
        setFolksonomyDegree(folksonomyDataDegree);
        } catch (err) {
        console.log({ err });
        }
    };
  const LoadingMessage = (props) => {    
    return (
      <div
        {...props.innerProps}
        style={props.getStyles("loadingMessage", props)}
      >
        {props.selectProps.inputValue.length >= 2
          ? "Loading education..."
          : `Please enter ${
              2 - props.selectProps.inputValue.length
            } more character(s)`}
      </div>
    );
  };
  const folksonomyOptions = (inputValue: string) => 
      getDefaultOptions( 
      folksonomy
          .filter((i) =>i.toLowerCase().includes(inputValue.toLowerCase()))
          .slice(0, 30).map((j)=>j.slice(0,-1))
  );
  
  const promiseOptions = (inputValue) => {
        if (inputValue.length >= 2) {
        return new Promise((resolve) => {
            setTimeout(() => {
            resolve(folksonomyOptions(inputValue));
            }, 1000);
        });
        } 
    };

    const folksonomyOptionsDegree = (inputValue: string) => 
    getDefaultOptions( 
    folksonomyDegree
        .filter((i) =>i.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 30).map((j)=>j.slice(0,-1))
);

const promiseOptionsDegree = (inputValue) => {
      if (inputValue.length >= 2) {
      return new Promise((resolve) => {
          setTimeout(() => {
          resolve(folksonomyOptionsDegree(inputValue));
          }, 1000);
      });
      } 
  };
    
    const CountryOptions = (inputValue: string) =>
    getDefaultOptions(
      countries
        .filter((i) => i.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 30)
    );
    const promiseCountryOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(CountryOptions(inputValue));
        }, 1000);
      });
    } 
  };

   const handleTermDegree= (e) => {

    setNewTermsDegree(e.map((term) => term.value).join("|"));
  };
  const handleTermSchool= (e) => {

    setNewTermsSchool(e.map((term) => term.value).join("|"));
  };
    const main: JSX.Element = !loading ? (
    <Container role="main">
      <Form.Group className="mb-4" controlId="Education">
        <Form.Label aria-label="Degree" className="textSmall">
          Degree
        </Form.Label>
        <AsyncSelect  
          aria-label="Degree"     
          autoFocus
          noOptionsMessage={() => 'No options'}
          components={{ LoadingMessage }}
          onChange={(e) => setDegreeValue(e.value)}
          placeholder={<div style={{color:"#1c1c1c"}}>Search Degree..</div>}
          cacheOptions
          folksonomyOptionsDegree
          loadOptions={promiseOptionsDegree}
        />
        <Form.Text>
          <b>Search Tip:</b> Use the field of study to narrow your options e.g. 
          if searching for a Bachelor of Science degree in Economics, 
          type Economics to narrow down your options in the picklist.
        </Form.Text>
        <Form.Label style={{width:"100%"}} aria-label="School" className="textSmall">          
         School
        </Form.Label>
         <AsyncSelect
          aria-label="School"
          autoFocus
          noOptionsMessage={() => 'No options'}
          components={{ LoadingMessage }}
          onChange={(e) => setSchoolValue(e.value)}
          placeholder={<div style={{color:"#1c1c1c"}}>Search School..</div>}
          cacheOptions
          folksonomyOptions
          loadOptions={promiseOptions}          
        />
        
        <Form.Text>
          <b>Search Tip:</b> Use the unique name of School to narrow your options e.g. 
            Tilburg or Harvard. Avoid starting your search with University, College or School.
        </Form.Text>
        <Form.Label aria-label="Country" className="textSmall">
          Country
        </Form.Label>
        <AsyncSelect
          aria-label="School"
          autoFocus
          noOptionsMessage={() => 'No options'}
          components={{ LoadingMessage }}
          onChange={(e) => setCountryValue(e.value)}
          placeholder={<div style={{color:"#1c1c1c"}}>Search Country..</div>}       
          cacheOptions
          CountryOptions
          loadOptions={promiseCountryOptions}
        />
        <Form.Text>
          <b>Search Tip:</b> Search the Country location of the listed school.
        </Form.Text>
        <div style={{ marginTop: "10px", fontSize:"14px" }}>
        Please delete highlighted entries, reselect the term from the dropdown list and save. If preferred term is unavailable, please <a className="addTermSetLink" title="Request new terms"  href="#" onClick={(e)=>setTermRequest(true)} >Request new terms</a> to be added to your profile. You will receive a confirmation email upon completion of your request.   
        </div>

        <div style={{ marginTop: "5%", fontSize:"14px", }}>


{termRequest&&
<div>

<br/>
       <Form.Label>
        Degree
       </Form.Label>
<CreatableSelect
 noOptionsMessage={() => null}
placeholder="Please type your new term here..."
components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null,Menu: () => null   }}
isMulti
onChange={handleTermDegree}
style={{marginTop: "1%"}}
/>
<br/>
<Form.Label>
        School
       </Form.Label>
<CreatableSelect
 noOptionsMessage={() => null}
placeholder="Please type your new term here..."
components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null,Menu: () => null }}
isMulti
onChange={handleTermSchool}
style={{marginTop: "1%"}}
/>
        
        </div>
}
          
        </div>
      </Form.Group>
    </Container>
  ) : (
    <div className="p-5 text-center">
      <Spinner animation="border" variant="info" />
    </div>
  );

 const addNewEducation = React.useCallback(async () => {
    if (degreeValue || schoolValue || countryValue) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const updateDegreeResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.degree.concat(id),              
              degreeValue ? degreeValue : ""
            )
          );

          const updateSchoolResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
                currentUser.LoginName,
                EDIT_CARD_PROPS.EducationCard.school.concat(id),
                schoolValue ? schoolValue : ""
            )
          );

          const updateCountryResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
                currentUser.LoginName,
                EDIT_CARD_PROPS.EducationCard.country.concat(id),
                countryValue ? countryValue : ""
            )
          );

        
          if (
            updateDegreeResponse.statusCode === 200 &&
            updateSchoolResponse.statusCode === 200 &&
            updateCountryResponse.statusCode === 200 &&            
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate({ 
               ...props,
               degree: degreeValue ? degreeValue : "",
               school: schoolValue ? schoolValue : "",
               country: countryValue ? countryValue : ""            
            });
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);            
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
          if(termsDegree!="")
          {
          await saveNewTerms({Title:currentUser.Email,Degree:termsDegree});
         
          }
    
          if(termsSchool!=""){
          await saveNewTerms({Title:currentUser.Email,School:termsSchool});
        
          }
        } else {
          setLoading(false);
          setShow(false);
          onUpdate({ 
               id:id,
               degree: degreeValue ? degreeValue : "",
               school: schoolValue ? schoolValue : "",
               country: countryValue ? countryValue : ""            
            });
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);        
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else 
    {setShow(false);
      if(termsDegree!="")
      {
        const UpdateDegreeResponse: SP.ResponseInfo =  await saveNewTerms({Title:currentUser.Email,Degree:termsDegree});
        if( UpdateDegreeResponse.statusCode === 201 )
        {
     showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      }

      if(termsSchool!=""){
        const UpdateSchoolResponse: SP.ResponseInfo =   await saveNewTerms({Title:currentUser.Email,School:termsSchool});
        if( UpdateSchoolResponse.statusCode === 201 )
        {
     showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      }
    }
  }, [
    degreeValue,
    schoolValue,
    countryValue ,
    termsDegree,termsSchool   
  ]);
const cancelHandler = () => {
    setShow(false); 
    setDegreeValue("");
    setSchoolValue("");
    setCountryValue("");
  };

 
return (
    <EditCard
      header={header}
      show
      loading={loading}
      update={addNewEducation}
      onHide={cancelHandler}
      body={main}
      showLarge={true}
      newTermRequest={termRequest}
    />
    )
}
export default AddEducation;