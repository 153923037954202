import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

import ViewCard from "../../components/ViewCard/ViewCard";
import EditExpertArea from "./EditExpertArea";
import SearchRedirect from '../../components/SearchRedirect';
/** Presentation */
import "../../components/ViewCard/ViewCard.scss";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";
import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

/** Constants */
import { EXPERTISE_AREA, CONST_EXPERTISE_AREA } from "../../utils/Consts";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Utils */
import helper, { getTaxonomySet } from "../../utils/Helper";

export const ExpertiseArea: React.FC<ICardComponentProps> = ({
  header,
  tooltip,
  edit,
  percentage
}) => {
  const { state, dispatch } = React.useContext(Context);
  const { getExpertiseAreas } = useTaxonomyHandler();
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(EXPERTISE_AREA, userProps);

  const [expertArea, setExpertArea] = React.useState({});
  const [expertiseArea, setExpertiseArea] = React.useState({});
  const [expertiseAreaDisplay, setExpertiseDisplay] = React.useState<string[]>([]);

  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);



  React.useEffect(() => updateSection(), [expertArea]);

  React.useEffect(() => displayExpertiseArea(), [expertiseArea,expertArea]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        const expertise = await getTaxonomySet(helper.functionFile);
        setExpertArea(props);
        setExpertiseArea(expertise);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const updateSection = () => {
    !expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION] &&
      expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION] === "" &&
      !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
        type: "ADD_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: true,
          handler: () => setShowModal(!showModal),
        },
      })
      : expertArea["EF-Function"] &&
      expertArea["EF-Function"] !== "" &&
      dispatch({
        type: "REMOVE_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
        },
      });
  };

  const displayExpertiseArea = () => {

    if (Object.keys(expertiseArea).length) {

      if (expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION]) {

        loadPrimaryExpertiseArea(0, [expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION]], expertiseArea)
      }

      if (expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION1]) {
        loadPrimaryExpertiseArea(1, [expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION1]], expertiseArea)

      }
      if (expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION2]) {
        loadPrimaryExpertiseArea(2, [expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION2]], expertiseArea)

      }

      if (expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION3]) {

        loadPrimaryExpertiseArea(3, [expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION3]], expertiseArea)
      }

    }

  };
  let expertiseDisplay = [];

  const loadPrimaryExpertiseArea = (number, selected, options) => {

    let functiondisciplines = [];
    let functionPTE = [];
    let functionSME = [];
    let functiondisciplinesDisplay = [];
    let functionPTEDisplay = [];
    let functionSMEDisplay = [];
   /* let function1disciplinesDisplay = [];
    let function1PTEDisplay = [];
    let function1SMEDisplay = [];
    let function2disciplinesDisplay = [];
    let function2PTEDisplay = [];
    let function2SMEDisplay = [];
    let function3disciplinesDisplay = [];
    let function3PTEDisplay = [];
    let function3SMEDisplay = [];
    let function1disciplines = [];
    let function1PTE = [];
    let function1SME = [];
    let function2disciplines = [];
    let function2PTE = [];
    let function2SME = [];
    let function3disciplines = [];
    let function3PTE = [];
    let function3SME = [];*/

    selected.forEach((o) => {
      if (o)

        Object.keys(options[o]).map((key) => {
          if (key !== "checked" && key !== "items" && key !== "ID") {
            if (number == 0) {
              functiondisciplines.push(
                o + ":" + key


              );
              Object.keys(options[o][key]).map((keyPte) => {
                if (keyPte !== "checked" && keyPte !== "items" && keyPte !== "ID") {

                  functionPTE.push(o + ":" + key + ":" + keyPte)

                  Object.keys(options[o][key][keyPte]).map((keysme) => {
                    if (keysme !== "checked" && keysme !== "items" && keysme !== "ID") {

                      functionSME.push(

                        o + ":" + key + ":" + keyPte + ":" + keysme,
                      )

                    }

                  });


                }
              });


            }
            if (number == 1) {
              functiondisciplines.push(o + ":" + key


              );
              Object.keys(options[o][key]).map((keyPte) => {
                if (keyPte !== "checked" && keyPte !== "items" && keyPte !== "ID") {

                  functionPTE.push(o + ":" + key + ":" + keyPte)

                  Object.keys(options[o][key][keyPte]).map((keysme) => {
                    if (keysme !== "checked" && keysme !== "items" && keysme !== "ID") {

                      functionSME.push(o + ":" + key + ":" + keyPte + ":" + keysme,
                      )

                    }

                  });


                }
              });
            }
            if (number == 2) {
              functiondisciplines.push(o + ":" + key);
              Object.keys(options[o][key]).map((keyPte) => {
                if (keyPte !== "checked" && keyPte !== "items" && keyPte !== "ID") {

                  functionPTE.push(o + ":" + key + ":" + keyPte,
                  )

                  Object.keys(options[o][key][keyPte]).map((keysme) => {
                    if (keysme !== "checked" && keysme !== "items" && keysme !== "ID") {

                      functionSME.push(o + ":" + key + ":" + keyPte + ":" + keysme,
                      )

                    }

                  });


                }
              });
            }
            if (number == 3) {
              functiondisciplines.push(o + ":" + key);
              Object.keys(options[o][key]).map((keyPte) => {
                if (keyPte !== "checked" && keyPte !== "items" && keyPte !== "ID") {

                  functionPTE.push(o + ":" + key + ":" + keyPte
                  )

                  Object.keys(options[o][key][keyPte]).map((keysme) => {
                    if (keysme !== "checked" && keysme !== "items" && keysme !== "ID") {

                      functionSME.push(o + ":" + key + ":" + keyPte + ":" + keysme)

                    }

                  });


                }
              });
            }


          }

        });

    });

    if (expertArea[CONST_EXPERTISE_AREA.EF_DISCIPLINE] != "") {
      let selecteddisciplines = expertArea[CONST_EXPERTISE_AREA.EF_DISCIPLINE].split("|");
      functiondisciplines &&
        (
          functiondisciplinesDisplay = functiondisciplines.filter(x => selecteddisciplines.includes(x.split(":")[1]))
        )

   /*   function1disciplines &&
        (
          function1disciplinesDisplay = function1disciplines.filter(x => selecteddisciplines.includes(x.split(":")[1]))
        )

      function2disciplines &&
        (
          function2disciplinesDisplay = function2disciplines.filter(x => selecteddisciplines.includes(x.split(":")[1]))
        )

      function3disciplines &&
        (
          function3disciplinesDisplay = function3disciplines.filter(x => selecteddisciplines.includes(x.split(":")[1]))
        )
        */

    }


    if (expertArea[CONST_EXPERTISE_AREA.EF_PROCESS_AREA] != "") {
      let selectedpte = expertArea[CONST_EXPERTISE_AREA.EF_PROCESS_AREA].split("|");
      functionPTE &&
        (
          functionPTEDisplay = functionPTE.filter(x => selectedpte.includes(x.split(":")[2]))
        )

    /*  function1PTE &&
        (
          function1PTEDisplay = function1PTE.filter(x => selectedpte.includes(x.split(":")[2]))
        )

      function2PTE &&
        (
          function2PTEDisplay = function2PTE.filter(x => selectedpte.includes(x.split(":")[2]))
        )
      function3PTE &&
        (
          function3PTEDisplay = function3PTE.filter(x => selectedpte.includes(x.split(":")[2]))
        )
*/
    }

    if (expertArea[CONST_EXPERTISE_AREA.EF_SUBJECTMATTEREXPERT] != "") {

      let selectedsme = expertArea[CONST_EXPERTISE_AREA.EF_SUBJECTMATTEREXPERT].split("|");
      functionSME &&
        (
          functionSMEDisplay = functionSME.filter(x => selectedsme.includes(x.split(":")[3]))
        )

    /*  function1SME &&
        (
          function1SMEDisplay = function1SME.filter(x => selectedsme.includes(x.split(":")[3]))
        )

      function2SME &&
        (
          function2SMEDisplay = function2SME.filter(x => selectedsme.includes(x.split(":")[3]))
        )
      function3SME &&
        (
          function3SMEDisplay = function3SME.filter(x => selectedsme.includes(x.split(":")[3]))
        )

        */
    }


    functionSMEDisplay.length > 0 &&
      (

        functionSMEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )

    /*function1SMEDisplay.length > 0 &&
      (

        function1SMEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )

    function2SMEDisplay.length > 0 &&
      (

        function2SMEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )

    function3SMEDisplay.length > 0 &&
      (

        function3SMEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )*/

    functionPTEDisplay.length > 0 &&
      (

        functionPTEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )


   /* function1PTEDisplay.length > 0 &&
      (

        function1PTEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )


    function2PTEDisplay.length > 0 &&
      (

        function2PTEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )


    function3PTEDisplay.length > 0 &&
      (

        function3PTEDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )*/


    functiondisciplinesDisplay.length > 0 &&
      (

        functiondisciplinesDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )


 /*   function1disciplinesDisplay.length > 0 &&
      (

        function1disciplinesDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )


    function2disciplinesDisplay.length > 0 &&
      (

        function2disciplinesDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )


    function3disciplinesDisplay.length > 0 &&
      (

        function3disciplinesDisplay.map((value) => {

          if (!expertiseDisplay.some(e => e.includes(value))) {

            expertiseDisplay.push(value);
          }
        }
        )
      )*/


      if (!expertiseDisplay.some(e => e.includes(selected[0]))) {

        expertiseDisplay.push(selected[0]);
      }

    setExpertiseDisplay(expertiseDisplay);


  };




  const handleUpdate = React.useCallback(
    (val) => {
      setExpertArea(val);
    },
    [expertArea]
  );
  // const main: JSX.Element =
  //   expertArea &&
  //     helper.IsObj(expertArea) &&
  //     expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION] !== "" ? (
  //     <div className="mb-4">
  //       <div className="subheaderNoPadding">Function</div>
  //       <div>
  //         <SearchRedirect
  //           index={1}
  //           searchKey={'ExpertiseAreasFunction'}
  //           keyword={expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION]}
  //           redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //           isTagSingle={false}
  //         />
  //         {expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION1] && (
  //           <SearchRedirect
  //             index={2}
  //             searchKey={'ExpertiseAreasFunction'}
  //             keyword={expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION1]}
  //             redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //             isTagSingle={false}
  //           />
  //         )}
  //         {expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION2] && (
  //           <SearchRedirect
  //             index={2}
  //             searchKey={'ExpertiseAreasFunction'}
  //             keyword={expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION2]}
  //             redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //             isTagSingle={false}
  //           />
  //         )}
  //         {expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION3] && (
  //           <SearchRedirect
  //             index={2}
  //             searchKey={'ExpertiseAreasFunction'}
  //             keyword={expertArea[CONST_EXPERTISE_AREA.EF_FUNCTION3]}
  //             redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //             isTagSingle={false}
  //           />
  //         )}
  //       </div>

  //       {expertArea[CONST_EXPERTISE_AREA.EF_DISCIPLINE] && expertArea[CONST_EXPERTISE_AREA.EF_DISCIPLINE].length > 0 && (
  //         <>
  //           <div className="subheader">
  //             Discipline / Community
  //           </div>
  //           {expertArea[CONST_EXPERTISE_AREA.EF_DISCIPLINE].split("|").map((discipline, i) => (
  //             <SearchRedirect
  //               index={i}
  //               searchKey={'ExpertiseAreasDiscipline'}
  //               keyword={discipline}
  //               redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //               isTagSingle={false}
  //             />
  //           ))}
  //         </>
  //       )}

  //       {expertArea[CONST_EXPERTISE_AREA.EF_PROCESS_AREA] &&
  //         expertArea[CONST_EXPERTISE_AREA.EF_PROCESS_AREA].length > 0 && (
  //           <>
  //             <div className="subheader">
  //               Principal Expertise Area
  //             </div>
  //             {expertArea[CONST_EXPERTISE_AREA.EF_PROCESS_AREA].split("|").map((pte, i) => (
  //               <SearchRedirect
  //                 index={3}
  //                 searchKey={'ExpertiseAreasProcessArea'}
  //                 keyword={pte}
  //                 redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //                 isTagSingle={false}
  //               />
  //             ))}
  //           </>
  //         )}

  //       {expertArea[CONST_EXPERTISE_AREA.EF_SUBJECTMATTEREXPERT] &&
  //         expertArea[CONST_EXPERTISE_AREA.EF_SUBJECTMATTEREXPERT].length > 0 && (
  //           <>
  //             <div className="subheader">
  //               Sub Expertise Area
  //             </div>
  //             {expertArea[CONST_EXPERTISE_AREA.EF_SUBJECTMATTEREXPERT].split("|").map((sme, i) => (
  //               <SearchRedirect
  //                 index={4}
  //                 searchKey={'ExpertiseAreasSME'}
  //                 keyword={sme}
  //                 redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
  //                 isTagSingle={false}
  //               />
  //             ))}
  //           </>
  //         )}
  //     </div>
  //   ) : null;

  const main: JSX.Element =
    expertArea &&
    helper.IsObj(expertArea) ? (
      <div>
  { expertiseAreaDisplay.map((expertisearea, i) => (
    <>


    {expertisearea.split(":").length == 4 && (
        
          <div className="mb-4">
            <div className="subheaderNoPadding">Function: 
              <SearchRedirect
                index={1}
                searchKey={'ExpertiseAreasFunction'}
                keyword={" "+expertisearea.split(":")[0]}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                isTagSingle={false}
              />
            </div>



            <div >
        Discipline / Community: 
             
              <SearchRedirect
                index={i}
                searchKey={'ExpertiseAreasDiscipline'}
                keyword={" "+expertisearea.split(":")[1]}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                isTagSingle={false}
              />
             
            </div>


            <div >
          Principal Expertise Area: 
             
              <SearchRedirect
                index={i}
                searchKey={'ExpertiseAreasProcessArea'}
                keyword={" "+expertisearea.split(":")[2]}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                isTagSingle={false}
              />
          
            </div>


            <div >
        Sub Expertise Area: 
              
              <SearchRedirect
                index={i}
                searchKey={'ExpertiseAreasSME'}
                keyword={" "+expertisearea.split(":")[3]}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                isTagSingle={false}
              />
              </div>
            </div>

          
     )
}

{expertisearea.split(":").length == 3 && (
        
        <div className="mb-4">
          <div className="subheaderNoPadding">Function: 
            <SearchRedirect
              index={1}
              searchKey={'ExpertiseAreasFunction'}
              keyword={" "+expertisearea.split(":")[0]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          </div>



          <div >
      Discipline / Community: 
            
            <SearchRedirect
              index={i}
              searchKey={'ExpertiseAreasDiscipline'}
              keyword={" "+expertisearea.split(":")[1]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          
</div>

<div >
           Principal Expertise Area: 
           
            <SearchRedirect
              index={i}
              searchKey={'ExpertiseAreasProcessArea'}
              keyword={" "+expertisearea.split(":")[2]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          </div>


        </div>

   )
}


{expertisearea.split(":").length == 2 && (
        
        <div className="mb-4">
          <div className="subheaderNoPadding">Function: 
          
            <SearchRedirect
              index={1}
              searchKey={'ExpertiseAreasFunction'}
              keyword={" "+expertisearea.split(":")[0]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          </div>



          <div >
    Discipline / Community: 
           
            <SearchRedirect
              index={i}
              searchKey={'ExpertiseAreasDiscipline'}
              keyword={" "+expertisearea.split(":")[1]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          </div>


          
        </div>

   )
}

{expertisearea.split(":").length == 1 && (
        
        <div className="mb-4">
          <div className="subheaderNoPadding">Function: 
            <SearchRedirect
              index={1}
              searchKey={'ExpertiseAreasFunction'}
              keyword={" "+expertisearea.split(":")[0]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          </div>



         
        </div>

   )
}
    </>

      








    )

    

    )
     }

</div>
     

    ):null;
    



  return showModal ? (
    <div className="bottomBorder">
      <EditExpertArea
        header={header}
        props={expertArea}
        show={showModal}
        setShow={setShowModal}
        onUpdate={handleUpdate}
      />
    </div>
  ) : main ? (
    <div className="bottomBorder">
      <ViewCard
        header={header}
        body={main}
        edit={edit}
        loading={loading}
        editHandler={() => setShowModal(!showModal)}
        toolTip={tooltip}
        percentage={percentage}
      />
    </div>
  ) : null;
};

export default ExpertiseArea;
