import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";
import ViewCard from "../../components/ViewCard/ViewCard";
import EditCurrentJob from "./EditCurrentJob";

/** Presentation */
import "../../components/ViewCard/ViewCard.scss";
import "./Career.scss";
import { Collapse } from "react-bootstrap";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

/** Constants */
import { CURRENT_JOB } from "../../utils/Consts";
import SearchRedirect from '../../components/SearchRedirect';
/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Utils */
import helper, { getDate, getDateFormat } from "../../utils/Helper";

export const CurrentJob: React.FC<ICardComponentProps> = ({ header,
  tooltip, edit, percentage }) => {
  const { state, dispatch } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(CURRENT_JOB, userProps);  
  const [currentJob, setCurrentJob] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [currentJob]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps(),
          i = 10;
        const job: any = {};
        if (props[`Title`] !== "" || props[`EF-PA-ProjectJobTitle${i}`] !== "") {
          job.id = i;
          job.company = props[`EF-PA-ProjectTitle${i}`];
          job.jobTitle = props[`Title`];
          job.contractorJobTitle = props[`EF-PA-ProjectJobTitle${i}`]
          job.description = props[`EF-PA-ResponsibilityArea${i}`];
          job.location = props[`EF-PA-ProjectLocation${i}`];
          job.startDate = new Date(getDateFormat(props[`EF-PA-ProjectStartDate${i}`]));
          job.endDate = props[`EF-PA-ProjectEndDate${i}`];
          job.asset = props[`EF-PA-Asset${i}`];
          job.role = props[`EF-PA-PivotalRole${i}`];
          job.capitalProject = props[`EF-PA-CapitalProjects${i}`];
          setCurrentJob(job);
          setLoading(false);
        }
        setLoading(false); 
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const handleUpdate = React.useCallback(
    (val) => {
      setCurrentJob(val);
    },
    [currentJob]
  );

  const updateSection = () => {
    if (!currentJob && !state.sectionProps.find((section) => section.title === header)) {
      dispatch({
        type: "ADD_SECTION",
        payload:
        {
          title: `${header}`,
          showPercentage: true,
          handler: () => setShowModal(!showModal),
        }
      })
    }
    else {
      dispatch({
        type: "REMOVE_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
        }
      })
    }
  };

  const main: JSX.Element = currentJob && (currentJob.contractorJobTitle.length > 0 ||
    currentJob.jobTitle.length > 0) && (
      <div>
        <div className="subheaderNoPadding" >
          <SearchRedirect
            index={1}
            searchKey={'CurrentWorkProjectRoleTitle'}
            keyword={currentJob.jobTitle?currentJob.jobTitle:currentJob.contractorJobTitle}
            redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
            isTagSingle={true}
          />
        </div>
        <p style={{ marginTop: "8px", marginBottom: "8px" }}>
          {currentJob.company && (
            <SearchRedirect
              index={1}
              searchKey={'CurrentWorkProjectTitle'}
              keyword={currentJob.company}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
          )}
          {getDate(currentJob.startDate) !== "Invalid Date" &&
            `${getDate(currentJob.startDate)} ${(getDate(currentJob.endDate) !== "Invalid Date" && getDate(currentJob.endDate)) ? `- ${getDate(currentJob.endDate)}` : `- Present*`
            }`
          }
        </p>
        {currentJob.asset.length ||
          currentJob.location.length ||
          currentJob.capitalProject.length ||currentJob.role.length? (
          <p>
            <span style={{ marginRight: "1rem" }}>
              {currentJob.asset && (
                <SearchRedirect
                  index={1}
                  searchKey={'CurentWorkAsset'}
                  keyword={currentJob.asset}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                  isTagSingle={false}
                />
              )}

{currentJob.role && (
                <SearchRedirect
                  index={1}
                  searchKey={'CurrentWorkProjectPivotalRole'}
                  keyword={currentJob.role}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                  isTagSingle={false}
                />
              )}

              {currentJob.location && (
                <SearchRedirect
                  index={1}
                  searchKey={'CurrentWorkProjectLocation'}
                  keyword={currentJob.location}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                  isTagSingle={false}
                />
              )}
            </span>
            {currentJob.capitalProject.length ? (
              <span className="project-badge">
                Capital Project :
                <strong>
                  <SearchRedirect
                    index={1}
                    searchKey={'CurrentWorkCapitalProject'}
                    keyword={currentJob.capitalProject}
                    redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                    isTagSingle={true}
                  />
                </strong>
              </span>
            ) : (
              ``
            )}
          </p>
        ) : null}
        {currentJob.description.trim().length ? (
          <>
            <Collapse in={open}>
              <div
                id="collapse-text"
                dangerouslySetInnerHTML={{
                  __html: currentJob.description,
                }}
              />
            </Collapse>
            <a className="showLink"
              onClick={() => setOpen(!open)}
            >
              {!open ? "View description" : "Hide description"}
            </a>
          </>
        ) : null}
      </div>
    );
  return showModal ? (
    <div className="bottomBorder">
      <EditCurrentJob
        props={currentJob}
        show={showModal}
        setShow={setShowModal}
        onUpdate={handleUpdate}
        isCurrentJob={true}
      />
    </div>
  ) : main ? (
    <div className="bottomBorder">
      <ViewCard
        header="Current Job"
        loading={loading}
        body={main}
        edit={edit}
        editHandler={() => setShowModal(!showModal)}
        percentage={percentage}
      />
    </div>
  ) : null;
};

export default CurrentJob;
