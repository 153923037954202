import * as React from "react";
import { ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import CloseButton from 'react-bootstrap/CloseButton';
/** Presentation */
import Modal from "react-bootstrap/Modal";

import ReactPlayer from "react-player";

import helpers,{getProfileViews,checkProfileViews} from "../../utils/Helper"

export const Banner = (props) => {

    const [show, setShow] = React.useState(false);
    const [showBanner, setBanner] = React.useState(false);
    const [userEmail, setEmail] = React.useState<string>("");
    const [userId, setId] = React.useState<string>("");

    React.useEffect(() => {
        onLoad();
      }, []);

      const onLoad = async () => {

        const loggedInUser=await helpers.getLoggedInUser();  
        
        setEmail(loggedInUser["Email"]);
        setId(loggedInUser["Id"]);

       const banner = await getProfileViews(loggedInUser["Email"],"true");

       banner=="No"?setBanner(false):setBanner(true);

      };



    const showVideo = () => {
        setShow(true);
      };

      const hideBanner = () => {
        setBanner(false);
      saveBannerInformation();

      };


      const saveBannerInformation = async () => {

if(userEmail!=""&&userId!="")
{

    await checkProfileViews(userEmail,userId,"true");
}
      }


    return (showBanner&&

        <div style={{backgroundColor:"#f4f4f4"}}>

<div style={{backgroundColor:"#0097BB",color:"#fff",width:"50%",marginLeft:"25%",display:"flex",padding:"1%"}} >

    <span style={{fontSize:"14px",fontFamily:"ShellMedium, sans-serif"}} >Did you know? Data in your and other profiles are hyperlinked to help you quickly find and explore profiles of colleagues within the selected term.    <a href="#" onClick={showVideo} style={{color:"#fff"}} >Click here to see how.</a></span>

    <CloseButton style={{paddingBottom:"3%"}} onClick={hideBanner}></CloseButton>

    </div>


<Modal
show={show}
aria-labelledby="example-modal-sizes-title-sm"
      centered
      animation={true}
      backdrop="static"    
      onHide={() => setShow(false)}  

>

    <ModalHeader closeButton>


    </ModalHeader>

    <ModalBody>

    <ReactPlayer
    playing
    //prod
    url="https://eu001-sp.shell.com/sites/AAAAB0586/TaxonomyTerms/Hyperlink%20Video.mp4"
    //acceptance
   //url="https://eu023-sp.shell.com/sites/SPOAA1153/TaxonomyTerms/Hyperlink%20Video.mp4"
    playIcon={<button>Play</button>}
    controls
          width="100%"
          height="calc(100vh - 100px)"
    >


    </ReactPlayer>

    </ModalBody>
</Modal>


</div>
    );

}

export default Banner;