/** import custom types */
import { CONST_LOCAL_USER } from "./Consts";
import {DaysOfWeek,IOption} from '../custom-types'
/** imports PnP components*/
import { SPFetchClient } from '@pnp/nodejs'
import '@pnp/sp/webs'
import "@pnp/sp/site-users/web";

/* PROD Environment we need to import the below modules*/
import { sp, SPRequestExecutorClient, setup } from 'sp-pnp-js'
import { encode } from "querystring";


export const helpers = {
	appweburl: process.env.REACT_APP_WEB_URL,
	hostweburl: process.env.REACT_APP_HOST_URL,
	configFile:process.env.REACT_APP_CONFIG_FILE,
	clientid: process.env.REACT_APP_CLIENT_ID,
	clientsecret: process.env.REACT_APP_CLIENT_SECRET,
	taxonomyAppWebURL: process.env.REACT_APP_TAXONOMY_APPWEBURL,	
	searchRedirectURL: process.env.REACT_APP_SEARCH_API_ENDPOINT,
	profileViewList:process.env.REACT_APP_ProfileViewList,
	TermRequestList:process.env.REACT_APP_TermRequestList,
	functionFile:process.env.REACT_APP_EXPERTISE_TAXONOMY_URL,
	termRequestsAskmeAbout:process.env.REACT_APP_TermRequestAskmeabout,
	termRequestsAccrediation:process.env.REACT_APP_TermRequestAccrediation,
	termRequests:process.env.REACT_APP_TermRequestMembership,
	//appinsightsinstrumentationKey:process.env.REACT_APP_AppInsights,

	 delay :async (ms, result) => {
  return new Promise(resolve => setTimeout(resolve(result), ms));
},
 
	getProfileUser: async (email: string): Promise<any> => {
		const user = window.location.href.toLowerCase().indexOf(CONST_LOCAL_USER.LOCALHOST) !== -1 ? 
		await helpers.delay(5000, {Email: CONST_LOCAL_USER.Email,Title: CONST_LOCAL_USER.Title, LoginName: CONST_LOCAL_USER.LoginName, Img: "" }) : 
		await window[CONST_LOCAL_USER.SP_PROPERTYWEB].siteUsers.getByEmail(email).get()
		return Promise.resolve(user)
	},

	getLoggedInUser: async (): Promise<any> => {
		const user = window.location.href.toLowerCase().indexOf(CONST_LOCAL_USER.LOCALHOST) !== -1 ? 
		await helpers.delay(5000, {Email: CONST_LOCAL_USER.Email,Title: CONST_LOCAL_USER.Title, LoginName: CONST_LOCAL_USER.LoginName, Img: "" }) : 
		await window[CONST_LOCAL_USER.SP_PROPERTYWEB].currentUser.get()
		return Promise.resolve(user)
	},

	getUser: async (loginName: string): Promise<any> => {
		try {
			const user = window.location.href.toLowerCase().indexOf(CONST_LOCAL_USER.LOCALHOST) !== -1 ? 
			await helpers.delay(5000, {data: {Email: CONST_LOCAL_USER.Email,Title: CONST_LOCAL_USER.Title, LoginName: CONST_LOCAL_USER.LoginName, Img: "" }}) : 
			await window[CONST_LOCAL_USER.SP_PROPERTYWEB].ensureUser(loginName);
		return Promise.resolve(user)
		} catch (error) {
			throw Error("Could not fetch properties of the user " + loginName);
		}	
	},

	getUserByLoginName: async (loginName: string): Promise<any> => {
		const user = window.location.href.toLowerCase().indexOf(CONST_LOCAL_USER.LOCALHOST) !== -1 ? 
		await helpers.delay(5000, {data: {Email: CONST_LOCAL_USER.Email,Title: CONST_LOCAL_USER.Title, LoginName: CONST_LOCAL_USER.LoginName, Img: "" }}) : 
		await window[CONST_LOCAL_USER.SP_PROPERTYWEB].siteUsers.getByLoginName(loginName);
		return Promise.resolve(user);
	},

	getUserById: async (id: string): Promise<any> => {
		const user = window.location.href.toLowerCase().indexOf(CONST_LOCAL_USER.LOCALHOST) !== -1 ? 
		await helpers.delay(5000, {data: {Email: CONST_LOCAL_USER.Email,Title: CONST_LOCAL_USER.Title, LoginName: CONST_LOCAL_USER.LoginName, Img: "" }}) : 
		await window[CONST_LOCAL_USER.SP_PROPERTYWEB].getUserById(id);
		return Promise.resolve(user)
	},

	getUserInfo: async (id: string): Promise<any> => {			

		const reqUrl=`${helpers.taxonomyAppWebURL}/_api/web/siteuserinfolist/items(${id})`;
			
			return new Promise(

				(
					resolve: (response: any) => void,
					reject: (error: SP.ResponseInfo) => void
				) => {
						const executor = new SP.RequestExecutor(helpers.appweburl)
						executor.executeAsync({
							url: reqUrl,
							method: 'GET',
							headers: { Accept: 'application/json; odata=verbose' },
				success: (data: any) => {
					try {
						const response = JSON.parse(data.body)
						const jobTitle=response.d.JobTitle;
						jobTitle!=null?resolve(jobTitle):resolve("")
						
					} catch (err2) {
						reject(err2)
					}
				},
				error: (err) => {
					reject(err)
				},	
			})
		})		
			
			
	},			
			
		
		
		

	IsObj: (obj) : boolean => Object.keys(obj).length && obj.constructor === Object
}

/**
	* @param accountName - account name to get properties from
	
 */
/**
 *
 * API Request handler
 * @param url - api endpoint
 * @param method - http method
 * @param bodyParams - body parameters of request
 */


export const updateProperty = (
	user: string,
	propName: string,
	propVal: string
) => ({
	accountName: user,
	propertyName: propName,
	propertyValue: propVal,
})

export const updateProperties = (
	user: string,
	propName: string,
	propVal: Array<string>
) => ({
	accountName: user,
	propertyName: propName,
	propertyValues: propVal,
})

/**
 * Update User Profile Properties of a logged in user
 * @param apiUrl url where the user profile properties are getting updated to
 * @param userData Object containing Logged In User Accountname and property to update
 */
export const updateProfile = async (
	apiUrl: string,
	userData: Object
): Promise<any> => {
	return new Promise(
		(
			resolve: (success: SP.ResponseInfo) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
			try {
				
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: apiUrl,
					method: 'POST',
					headers: {
						Accept: 'application/json;odata=nometadata',
						'Content-type': 'application/json;odata=verbose',
						'odata-version': '',
					},
					body: JSON.stringify(userData),
					success: (data: SP.ResponseInfo) => {
						resolve(data)
						
					},
					error: (err) => {
						
						
					 errorLogging(err.body,userData)
					 //console.error(err)
						reject(err)
					},
				})
			} catch (error) {
				
				
				errorLogging(error.body,userData)
				//console.error(error)
				reject(error)
			}
		}
	)
}
/*
error logging code
*/ 

export const errorLogging = async(error:any,accountName:any): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web//lists/GetByTitle('Error Logs')/Items`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'POST',
					headers: { 
						Accept: "application/json;odata=nometadata",
              "Content-type": "application/json;odata=nometadata",
              "odata-version": "",
					},
					
					//body: JSON.stringify({ '__metadata':{ 'type': 'SP.Data.Error_x0020_LogsItem' },'Title':'testlog','Error Details':'testlog'}"), 
					body: JSON.stringify({						
						Details:error

					  }), 
					success: (data: any) => {
						console.log("success");
						
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}
export const getProfileViews = async(email:any,bannerInfo:any): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web//lists/GetByTitle('${helpers.profileViewList}')/Items?$filter=Title eq '${email}'`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					
					//body: JSON.stringify({ '__metadata':{ 'type': 'SP.Data.Error_x0020_LogsItem' },'Title':'testlog','Error Details':'testlog'}"), 
					
					success: (data: any) => {						
					
						var body=JSON.parse(data.body)

						if(body.d.results.length>0)
						{	
							bannerInfo=="true"?
							resolve(body.d.results[0].ShowBanner)	:			 
								
						resolve(body.d.results[0].ProfileViews)			
								
								 
								 
							}

							else
							{
								resolve(null)
							}
					
						
					},
					error: (err) => {
						reject(err)
					},
			
				})
		}
	)
}
export const checkProfileViews = async(email:any,loggedInUser:any,banner:any): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web/lists/GetByTitle('${helpers.profileViewList}')/Items?$filter=Title eq '${email}'`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					
					//body: JSON.stringify({ '__metadata':{ 'type': 'SP.Data.Error_x0020_LogsItem' },'Title':'testlog','Error Details':'testlog'}"), 
					
					success: (data: any) => {						
					
						var body=JSON.parse(data.body)

						if(body.d.results.length>0)
						{							 
								
									
									saveProfileViews(body.d.results[0].ID,body.d.results[0].ProfileViews,loggedInUser,banner)
								 
								 
							}
					else
					{
						addProfileViewEntry(email,loggedInUser,banner)
					}
						
					},
					error: (err) => {
						reject(err)
					},
			
				})
		}
	)
}
export const addProfileViewEntry = async(email:any,loggedInUser:any,banner:any): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web//lists/GetByTitle('${helpers.profileViewList}')/Items`

	const metadata= (banner=="true")?
	JSON.stringify({
		Title:email,						
		ShowBanner:"No"
	  }): JSON.stringify({
		Title:email,						
		ProfileViews:new Date().toLocaleDateString("en-US")+":"+loggedInUser
	  })
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'POST',
					headers: { 
						Accept: "application/json;odata=nometadata",
              "Content-type": "application/json;odata=nometadata",
              "odata-version": "",
					},
					
					//body: JSON.stringify({ '__metadata':{ 'type': 'SP.Data.Error_x0020_LogsItem' },'Title':'testlog','Error Details':'testlog'}"), 
					body: metadata,
					success: (data: any) => {
						console.log("success");
						
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}
export const saveProfileViews = async(id:any,views:any,loggedInUser:any,banner:any): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web//lists/GetByTitle('${helpers.profileViewList}')/Items(${id})`
	const metadata= (banner=="true")?
	JSON.stringify({											
		ShowBanner:"No"

	  })
:
	JSON.stringify({											
		ProfileViews:views+";"+new Date().toLocaleDateString("en-US")+":"+loggedInUser

	  })

	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'POST',
					headers: { 
						Accept: "application/json;odata=nometadata",
              "Content-type": "application/json;odata=nometadata",
              "odata-version": "",
			 
            "IF-MATCH": "*",  
            "X-HTTP-Method": "MERGE"
					},
					
					//body: JSON.stringify({ '__metadata':{ 'type': 'SP.Data.Error_x0020_LogsItem' },'Title':'testlog','Error Details':'testlog'}"), 
					body: metadata, 
					success: (data: any) => {
						console.log("success");
						
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const saveNewTerms = async(data:any): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web//lists/GetByTitle('${helpers.TermRequestList}')/Items`
	const metadata=
	JSON.stringify(data)


	return new Promise(
		(
			resolve: (success: SP.ResponseInfo) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'POST',
					headers: { 
						Accept: "application/json;odata=nometadata",
              "Content-type": "application/json;odata=nometadata",
              "odata-version": "",
					},
					
					//body: JSON.stringify({ '__metadata':{ 'type': 'SP.Data.Error_x0020_LogsItem' },'Title':'testlog','Error Details':'testlog'}"), 
					body: metadata,
					success: (data: SP.ResponseInfo) => {
						resolve(data)
						
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

/**
 * Wrap long text into smaller pieces over multiple lines
 * @param str - input text to be wrapped
 * @param width - total number of max characters to be supported
 * @param spaceReplacer - HTML element responsible for line break
 */
export const wrappedText = (
	str: string,
	width: number,
	spaceReplacer: string
) => {
	if (str.length > width) {
		let p = width
		for (; p > 0 && str[p] !== ' '; p--) {}
		if (p > 0) {
			const left = str.substring(0, p)
			const right = str.substring(p + 1)
			return left + spaceReplacer + wrappedText(right, width, spaceReplacer)
		}
	}
	return str
}

/**
 *
 * @param inputDate input date in the DD/MM/YYYY HH:MM:SS format(as stored in User Profiles) to be converted into DD MMM YYYY format
 */


export const getWorkingDays = (workingdays: string): string => {
	let workingDays: string
	const workingDaysObj: DaysOfWeek = JSON.parse(workingdays),
		workingDaysArr: string[] = Object.keys(workingDaysObj).filter(
			(e) => workingDaysObj[e]
		)
	if (workingDaysArr.length >= 2) {
		workingDays = `${workingDaysArr
			.slice(0, -1)
			.join(', ')} & ${workingDaysArr.slice(-1)}`
	} else if (workingDaysArr.length === 1) {
		workingDays = workingDaysArr[0]
	}
	return workingDays
}

export const getProfileProps = async (loginName: string): Promise<any> => {
	const reqUrl = `${
		helpers.appweburl
	}/_api/SP.UserProfiles.PeopleManager/GetPropertiesFor(accountName=@v)?@v='${loginName}'`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
			const executor = new SP.RequestExecutor(helpers.appweburl)
			executor.executeAsync({
				url: reqUrl,
				method: 'GET',
				headers: { Accept: 'application/json; odata=verbose' },
				success: (data: any) => {
					try {
						const response = JSON.parse(data.body)
						resolve(response)
					} catch (err2) {
						reject(err2)
					}
				},
				error: (err) => {
					reject(err)
				},
			})
		}
	)
}

export const getTaxonomySet = async (taxonomyUrl: string): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web/getFileByServerRelativeUrl('${taxonomyUrl}')/$value?binaryStringResponseBody=true`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							if(taxonomyUrl.includes("taxonomyProjects.txt"))
							{
							resolve(processTaxonomyProjects(JSON.parse(data.body)))
							}
							else
							{
								resolve(processTaxonomy(JSON.parse(data.body)))
							}
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const getFolksonomySet = async (taxonomyUrl: string): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web/getFileByServerRelativeUrl('${taxonomyUrl}')/$value?binaryStringResponseBody=true`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(processFolksonomy(data.body))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}
export const getURL= async (UrlFile: string): Promise<any> => {
	const reqUrl = `${helpers.taxonomyAppWebURL}/_api/web/getFileByServerRelativeUrl('${UrlFile}')/$value?binaryStringResponseBody=true`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: reqUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(JSON.parse(data.body))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}
export const processFolksonomy = (data: any) => {
	let results = {}
	results=data.split("\"");
	return results;
}
export const processTaxonomy = (data: any) => {
	let results = {}
	data.map((o, i) => {
		if (o.Parent) {
			const categories = o.PathOfTerm.split(';')

		

			if (categories[0] && results[categories[0]] === undefined) {
				
				results[categories[0]] = {}
				results[categories[0]].checked = false
				results[categories[0]].items = []
			}

			if (
				categories[1] &&
				results[categories[0]][categories[1]] === undefined
			) {
				
				results[categories[0]][categories[1]] = {}
				results[categories[0]][categories[1]].items = []
				results[categories[0]][categories[1]].checked = false
			}

			if (
				categories[2] &&
				results[categories[0]][categories[1]][categories[2]] === undefined
			) {
				
				results[categories[0]][categories[1]][categories[2]] = {}
				results[categories[0]][categories[1]][categories[2]].items = []
				results[categories[0]][categories[1]][categories[2]].checked = false
			}

			if (
				categories[3] &&
				results[categories[0]][categories[1]][categories[2]][categories[3]] ===
					undefined
			) {
				
				results[categories[0]][categories[1]][categories[2]][categories[3]] = {}
				results[categories[0]][categories[1]][categories[2]][
					categories[3]
				].items = []
				results[categories[0]][categories[1]][categories[2]][
					categories[3]
				].checked = false
			}

			if (categories.length && categories.length === 1) {
				o.checked = false
				results[categories[0]].items.push(o)
				results[categories[0]].ID = o.Id
			} else if (categories.length && categories.length === 2) {
				o.checked = false
				results[categories[0]][categories[1]].items.push(o)
				results[categories[0]][categories[1]].ID = o.Id
			} else if (categories.length && categories.length === 3) {
				o.checked = false
				results[categories[0]][categories[1]][categories[2]].items.push(o)
				results[categories[0]][categories[1]][categories[2]].ID = o.Id
			} else if (categories.length && categories.length === 4) {
				o.checked = false
				results[categories[0]][categories[1]][categories[2]][
					categories[3]
				].items.push(o)
				results[categories[0]][categories[1]][categories[2]][categories[3]].ID =
					o.Id
			}
		} else {
			if (results[o.Name] === undefined) {
				results[o.Name] = {}
				results[o.Name].ID = o.Id
				results[o.Name].items = []
				results[o.Name].checked = false
			}
			results[o.Name].ID = o.Id
		}
	})
JSON.stringify(results)
	return results
}

export const processTaxonomyProjects = (data: any) => {
	let results = {}
	data.map((o, i) => {
		if (o.Parent) {
			const categories = o.PathOfTerm.split(';');			

			if (
				categories[2] &&
				results[categories[2]] === undefined
			) {
				results[categories[2]] = {}
				results[categories[2]].items = []
				results[categories[2]].checked = false
			}
			

		 if (categories.length && categories.length === 3) {
				o.checked = false
				results[categories[2]].items.push(o)
				results[categories[2]].ID = o.Id
			} 
		} else {
			if (results[o.Name] === undefined) {
				results[o.Name] = {}
				results[o.Name].ID = o.Id
				results[o.Name].items = []
				results[o.Name].checked = false
			}
			results[o.Name].ID = o.Id
		}
	})
	return results
}

export const arrayToObject = (array, keyField) =>
array.reduce((obj, item) => {
  obj[item[keyField]] = item
  return obj
}, {})


export const getDate = (val, lastUpdated?) => {	
	const dateOptions:Intl.DateTimeFormatOptions = lastUpdated ? {
		year: "numeric",
		month: "short",
		day: "numeric",
	} : {
		year: "numeric",
		month: "short",
	}	
	const date = new Date(val).toLocaleDateString("en-US", dateOptions).replace(/ /g, ' ');
	//const date = new Date(val).toLocaleDateString();	

	return date; 
}

export const getTodaysDateTimeString = () =>{
	let today = new Date();
	let currentMonth = today.getMonth() + 1	
	let dateString:string = `${currentMonth}/${today.getDate()}/${today.getFullYear()}, ${today.toLocaleTimeString("en-US")}`;
	return dateString;
}
export const getDateFormat = (dateVal) =>{
	const dateVales:string[] = dateVal.split('/'),
	day = dateVales[0],   
    month = dateVales[1],    
    year = dateVales[2];    
	return `${month}/ ${day} ${year}`;
 }

export const getProjectDate = (val :Date) => {
	if(val){
		return new Date(val.toLocaleString('en-US', { timeZone: "UTC", month: 'short', year: 'numeric' }))
	} else {
		return ''
	}
}

export const getProjectDuration = (val) => {
const date = new Date(val.split(' ')[0].replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/,"$1$2$3"))
const formattedDate = date.toLocaleDateString('en-GB', {
  month: 'short', year: 'numeric'
}).replace(/ /g, ' ');
return formattedDate
}

export const getSelectedOption = (option:string): IOption => {
	return {value: option, label: option, color:'black'}
}

export const getDefaultOptions = (options: Array<string>): Array<IOption> => {
	return options.map(option => getSelectedOption(option))
}

if (window.location.href.toLowerCase().indexOf(CONST_LOCAL_USER.LOCALHOST) !== -1) {
	//Dev SP Setup
	setup({
		sp: {
			fetchClientFactory: () => {
				return new SPFetchClient(
					helpers.hostweburl,
					helpers.clientid,
					helpers.clientsecret
				)
			},
		},
	})
	window['SP_Propertyweb'] = sp.web
} else {
	//PROD SP Setup
	setup({
		sp: {
			fetchClientFactory: () => new SPRequestExecutorClient(),
		},
	})
	window['SP_Propertyweb'] = sp.crossDomainWeb(
		helpers.appweburl,
		helpers.hostweburl
	)
}

// Activities Supported functions

export const searchLFEActivities = async (user): Promise<any> => {
	const searchUrl = `${helpers.appweburl}/_api/search/query?querytext='
	path:"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/learnings"+AND+CreatedBy:${user.Title}'
	&selectproperties='Title,CreatedBy,LFELearningID,Created'&sortlist='Created:descending'&rowlimit=3`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
			const executor = new SP.RequestExecutor(helpers.appweburl)
			executor.executeAsync({
				url: searchUrl,
				method: 'GET',
				headers: { Accept: 'application/json; odata=verbose' },
				success: (data: any) => {
					try {
						resolve(processSearchResults(JSON.parse(data.body)))
					} catch (err2) {
						reject(err2)
					}
				},
				error: (err) => {
					reject(err)
				},
			})
		}
	)
}
export const searchLFEPlaylistActivities = async (user): Promise<any> => {	
	const searchUrl = `${helpers.appweburl}/_api/search/query?querytext=
	'path:"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/KMPlaylist"+AND+CreatedBy:${user.Title}
	'&selectproperties='Title,CreatedBy,LFEPlaylistId,Created'&sortlist='Created:descending'&rowlimit=3`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: searchUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(processSearchPlaylistResults(JSON.parse(data.body)))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const searchReplications = async (user): Promise<any> => {
	const searchUrl = `${helpers.appweburl}/_api/search/query?querytext=
	'path:"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/Tasks"+AND+CreatedBy:${user.Title}+
	AND+LFETaskDecision:adoptYes'&selectproperties='Title,CreatedBy,LFELearningID,LFETaskLearningID,Created,LFELNTitle,LFETaskDecision'&sortlist='Created:descending'&rowlimit=3`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: searchUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(processReplicationResults(JSON.parse(data.body)))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const searchEmphasizes = async (user): Promise<any> => {
	const searchUrl = `${helpers.appweburl}/_api/search/query?querytext=
	'path:"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/KMLearningSocialEmphasize"+AND+CreatedBy:
	${user.Title}'&selectproperties='Title,CreatedBy,Created,LFELNTitle'&sortlist='Created:descending'&rowlimit=3`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: searchUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(processEmphasizeResults(JSON.parse(data.body)))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const searchSignActivities = async (user): Promise<any> => {
const searchUrl = `${helpers.appweburl}/_api/search/query?querytext=
'path:"https://eu001-sp.shell.com/sites/AAAAA5493"+contentclass:STS_ListItem_DiscussionBoard+ModifiedBy:
${user.Title} +ReplyCount>0'&selectproperties='Title,Path,CreatedBy,Created,ModifiedBy,LastModifiedTime,ReplyCount,ContentType,ParentLink'&sortlist='LastModifiedTime:descending'&rowlimit=3`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: searchUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(processSignResults(JSON.parse(data.body)))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const searchSignPostActivities = async (user): Promise<any> => {
const searchUrl = `${helpers.appweburl}/_api/search/query?querytext=
'path:"https://eu001-sp.shell.com/sites/AAAAA5493"+contentclass:STS_ListItem_DiscussionBoard+CreatedBy:
${user.Title} '&selectproperties='Title,Path,CreatedBy,Created,ModifiedBy,LastModifiedTime,
ContentType,ParentLink'&sortlist='LastModifiedTime:descending'&rowlimit=3`
	return new Promise(
		(
			resolve: (response: any) => void,
			reject: (error: SP.ResponseInfo) => void
		) => {
				const executor = new SP.RequestExecutor(helpers.appweburl)
				executor.executeAsync({
					url: searchUrl,
					method: 'GET',
					headers: { Accept: 'application/json; odata=verbose' },
					success: (data: any) => {
						try {
							resolve(processSignPostResults(JSON.parse(data.body)))
						} catch (err2) {
							reject(err2)
						}
					},
					error: (err) => {
						reject(err)
					},
				})
		}
	)
}

export const processSearchResults = (data: any) => {
	let results = []
	if (data.d.query.PrimaryQueryResult !== null) {
		const jsonresults = data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
		if (jsonresults !== null && jsonresults.length > 0) {
			for (let i = 0; i < jsonresults.length; i++) {
				results.push(
					{
					"Title" : jsonresults[i].Cells.results[0]["Value"],
					"CreatedBy" : jsonresults[i].Cells.results[1]["Value"],
					"LearningId": jsonresults[i].Cells.results[2]["Value"],
					"Created":jsonresults[i].Cells.results[3]["Value"],					
					"ActivityText": "LFE - Created new learning",
					"link":"https://lfe.shell.com/#/ViewLearning/"+jsonresults[i].Cells.results[2]["Value"]
					}
				)
			}
		}
	}
	return results
}

export const processSearchPlaylistResults = (data: any) => {
	let results = []
	if (data.d.query.PrimaryQueryResult !== null) {
		const jsonresults = data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
		if (jsonresults !== null && jsonresults.length > 0) {
			for (let i = 0; i < jsonresults.length; i++) {
				results.push(
					{
					"Title" : jsonresults[i].Cells.results[0]["Value"],
					"CreatedBy" : jsonresults[i].Cells.results[1]["Value"],
					"PlaylitId": jsonresults[i].Cells.results[2]["Value"],
					"Created":jsonresults[i].Cells.results[3]["Value"],
					"ActivityText": "LFE - Created new Playlist",
					"link":"https://lfe.shell.com/#/ViewPlaylist/"+jsonresults[i].Cells.results[2]["Value"]
					}
				)
			}
		}
	}
	return results
}

export const processReplicationResults = (data: any) => {
	let results = []
	if (data.d.query.PrimaryQueryResult !== null) {
		const jsonresults = data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
		if (jsonresults !== null && jsonresults.length > 0) {
			for (let i = 0; i < jsonresults.length; i++) {
				results.push(
					{
					"Title" : jsonresults[i].Cells.results[6]["Value"],
					"CreatedBy" : jsonresults[i].Cells.results[3]["Value"],
					"LearningId": jsonresults[i].Cells.results[4]["Value"],
					"Created":jsonresults[i].Cells.results[5]["Value"],
					"ActivityText": "LFE - Replicated the learning",
					"link":"https://lfe.shell.com/#/ViewLearning/"+jsonresults[i].Cells.results[4]["Value"]
					}
				)
			}
		}
	}
	return results
}

export const processEmphasizeResults = (data: any) => {
	let results = []
	if (data.d.query.PrimaryQueryResult !== null) {
		const jsonresults = data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
		if (jsonresults !== null && jsonresults.length > 0) {
			for (let i = 0; i < jsonresults.length; i++) {
				results.push(
					{

					"Title" : jsonresults[i].Cells.results[3]["Value"],
					"CreatedBy" : jsonresults[i].Cells.results[1]["Value"],
					"LearningId": jsonresults[i].Cells.results[0]["Value"],
					"Created":jsonresults[i].Cells.results[2]["Value"],
					"ActivityText": "LFE - Emphasized the learning",
					"link":"https://lfe.shell.com/#/ViewLearning/"+jsonresults[i].Cells.results[0]["Value"]
					}


				)

			}
		}
	}

	

	return results
}

export const processSignResults = (data: any) => {
	let results = []
	if (data.d.query.PrimaryQueryResult !== null) {
		const jsonresults = data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
		if (jsonresults !== null && jsonresults.length > 0) {
			for (let i = 0; i < jsonresults.length; i++) {
				let activityTxt, title="";
				if(jsonresults[i].Cells.results[6]["Value"] === "Message"){
					activityTxt="SIGN - Replied to";
					const parentLink = jsonresults[i].Cells.results[7]["Value"];
					title= parentLink.substring(parentLink.lastIndexOf("/")+1);
				}
				else{
					activityTxt="SIGN - Created new Discussion";					
					title= jsonresults[i].Cells.results[0]["Value"];
				}						

				results.push(
					{
					"Title" : title,
					"CreatedBy" : jsonresults[i].Cells.results[2]["Value"],					
					"Created":jsonresults[i].Cells.results[3]["Value"],
					"ActivityText": activityTxt,
					"link":jsonresults[i].Cells.results[1]["Value"]
					}
				)

			}
		}
	}
	return results
}
export const processSignPostResults = (data: any) => {
	let results = []
	if (data.d.query.PrimaryQueryResult !== null) {
		const jsonresults = data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;

		if (jsonresults !== null && jsonresults.length > 0) {
			for (let i = 0; i < jsonresults.length; i++) {
				let activityTxt, title="";
				if(jsonresults[i].Cells.results[6]["Value"] === "Message"){
					activityTxt="SIGN - Replied to";
					const parentLink = jsonresults[i].Cells.results[7]["Value"];
					title= parentLink.substring(parentLink.lastIndexOf("/")+1);
				}
				else{
					activityTxt="SIGN - Created new Discussion";					
					title= jsonresults[i].Cells.results[0]["Value"];
				}	
				results.push(
					{
					"Title" : title,
					"CreatedBy" : jsonresults[i].Cells.results[2]["Value"],					
					"Created":jsonresults[i].Cells.results[3]["Value"],
					"ActivityText": activityTxt,
					"link":jsonresults[i].Cells.results[1]["Value"]
					}
				)
			}
		}
	}
	return results
}
export const removeHTML = (inputText: string) => {
	let str = inputText.toString()
	str = str.replace(
		/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&lt;br&gt;|&lt;\/div&gt;|&lt;div&gt;|&quot;/g,
		''
	)
	const newStr = str
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&quot;/g, '"')
	return `${newStr}`
}

export const getCharLength = (
	inputText: string,
	maxLength: number,
) => {
	const textLenght: number = inputText.length
	if (textLenght < maxLength) {
		return `${inputText}`
	} else {
		return `${inputText.substr(0, maxLength).concat('...')}`
	}
}

export default helpers