import * as React from 'react'
import Modal from 'react-bootstrap/Modal'

const SuccessMessageContainer: React.FC<{ successMessage: string | null }> = ({
	successMessage,
}) => {
	return (
		<Modal
			show={true}
			style={{
				color: 'green',
				textAlign: 'center',
				fontSize: '1.25rem',
			}}
		>
			<Modal.Body>{successMessage}</Modal.Body>
		</Modal>
	)
}
export default SuccessMessageContainer
