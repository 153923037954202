import * as React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import EditCard from "../../components/EditCard";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

import {
  NOTIFICATION_MESSAGE,
  EDIT_CARD_PROPS,
  UpdateProperty,
} from "../../utils/Consts";

import helpers, {
  updateProfile,
  updateProperties,
  updateProperty,
  getTodaysDateTimeString
} from "../../utils/Helper";


const EditActivity: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const [loading, setLoading] = React.useState(false);
  const [isInvalid, setInvalid] = React.useState(false);
  const [yammerID, setyammerID] = React.useState(
    props
      ? props
      : "" 
  );

  const updateContact = React.useCallback(async () => {
    try {

      let URLRegex = yammerID.match(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/);
      //to accept clearing yammerID or matched Regex      
      if(yammerID == "" || URLRegex !=null){
        setInvalid(false);     
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );       

        

        const yammerLinksUpdateResponse: SP.ResponseInfo = await updateProfile(
          helpers.appweburl + UpdateProperty.single,
          updateProperty(
            currentUser.LoginName,
            "EF-MyYammerId",
            yammerID
          )
        );

        if (
          yammerLinksUpdateResponse.statusCode === 200 &&          
          lastUpdatedResponse.statusCode === 200
         
        ) {
          setLoading(false);
          setShow(false);
          onUpdate(yammerID);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        } else {
          setLoading(false);
          setShow(false);
          setyammerID(props);
          showError(NOTIFICATION_MESSAGE.PreferenceError);
        }
      } else {
        onUpdate(yammerID);
        setLoading(false);
        setShow(false);
        showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
      }
    }
    else{
      setInvalid(true);
    }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setyammerID(props);
      showError(NOTIFICATION_MESSAGE.Error);
    } 
  }, [yammerID]);

  const cancelHandler = () => {
    setShow(false);
    setyammerID(props);
  };

  const main: JSX.Element = (
    <Container role="main">
      {loading && (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Form.Group>
      <Form.Label
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
          >
           Enter your Yammer Discussion page url.
          </Form.Label>
      <Form.Control
            aria-label="Yammer Discussion"
            type="input"
            value={yammerID} 
            onChange={(val) =>{
              if(val){
                setyammerID(val.currentTarget.value);
                setInvalid(false);
              }else{
                setyammerID("");
              }
            }
          }
          isValid={!isInvalid}
          isInvalid={isInvalid}          
          />   
           <Form.Control.Feedback type="invalid">
            Please provide a valid URL
          </Form.Control.Feedback>         
      </Form.Group>
    </Container>
  );
  const clearHandler =(e) =>{
   setyammerID("");
  }
  return (
    <EditCard
      header={yammerID?"Edit Yammer ID":"Add Yammer ID"}
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateContact}
      body={main}
      onClear={clearHandler}
      showClear ={true}
    />
  );
};

export default EditActivity;


