import styled from "styled-components";
import { theme } from "../styles/theme";

export const Img = styled.img`
  // padding: 5%;
  max-width: 20%;
  height: auto;
  object-fit: cover;
`;

export const SiteLogo = styled.div`
	display: flex;
	margin: 2%
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	& span {
    color: #58595B;
		font-size: 20px;
    font-family: ShellBook;
    &:hover {
    cursor: pointer;
    text-decoration: none;
	}
	}
`;

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: top;
  justify-content: center;
  background: #f4f4f4;
  overflow: auto;
  flex-wrap: wrap;
`;

export const ContactContainer = styled.div`
	display: flex;
	background-color: white;
	padding: 2% 5% 1% 5%;
	flex-wrap: wrap
	justify-content: center;
	align-items: top;
	width:100%;
	height: fit-content;
	overflow: auto;
	-webkit-box-shadow: 0 5px 5px -7px #777;
 	-moz-box-shadow: 0 5px 5px -7px #777;
 	box-shadow: 0 5px 5px -7px #777;
`;

export const Persona = styled.section`
  display: flex;
  flex: 5%;
  justify-content: flex-end;
  align-content: top;
  padding-left: 5%;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 20%;
  padding-left: 1%;
`;

export const ProfileInfo = styled.section`
  display: flex;
  flex-direction: column;
  flex: 90%;
`;
export const ProfileEdit = styled.section`
	justify-content: flex-end;
	flex:10%
	align-content: top;

	&:hover {
		cursor: pointer;
	}
`;

export const ContactInfo = styled.section`
  flex: 20%;
  padding-left: 4%;
  flex-direction: column;
`;

export const Col = styled.div`
  margin: 2px;
  color: #58595b;
`;
export const Country = styled.div`
  font-family: Arial Regular;
  color: #1C1C1C;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  padding-top: 0.5rem;
`;
export const ProfileCompletion = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #808285;
  padding-top: 1rem;
  
`;

export const Phone = styled.div`
  font-family: Arial;  
  color: #1C1C1C;  
  font-size: 16px;  
  letter-spacing: 0.05em;  
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.color.primaryDark};
  border-radius: ${theme.radius.lg};
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease-in-out;
  width: 100%;
  min-height: 100px;

  span:last-of-type {
    color: #fff;
    padding: ${theme.pad.md};
  }

  &:hover {
    position: relative;
    background: ${theme.color.primaryLight};
    transform: scale(1.125);
    z-index: 1000;
    box-shadow: ${theme.shadow.lg};

    span:last-of-type {
      color: ${theme.color.primaryDark};
      padding: ${theme.pad.md};
    }
  }
`;

export const ProfileImg = styled(Img)`
  border-radius: 50%;
  opacity: 1;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  transition: 0.5s ease;
`;

export const EditIcon = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

export const ImgContainer = styled.div`
  position: relative;

  &:hover ${Img} {
    opacity: 0.7;
    background: black;
  }
  &:hover ${EditIcon} {
    opacity: 1;
    filter: alpha(opacity=100);
    cursor: pointer;
    z-index: 1;
  }
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // width: 50%;
  padding: 0.7% 0 0 16%;
`;

export const CardRightColumn = styled.div`
  // display: flex;
  // flex-direction: column;
  flex-basis: 100%;
  // flex:1;
  //width: 250px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 2% 0 2% 5%;
  display: flex;
  // flex: 20%;
  flex-direction: column;
`;

export const CardLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  width: 50%;
  height: fit-content;
  padding: 2% 0 2% 5%;
`;

export const CardInfoSection = styled.section`
  // display: flex;
  flex: 20%;
  flex-direction: column;
  background-color: white;
`;

export const CardHeading = styled.div`
 // font-family: ShellBold;
  font-style: normal;
  font-weight: 500;
  //font-size: 20px;
  color: #000000;
  // line-height: 27px;
  // margin-bottom: 3%;
`;

export const Mentoring = styled(CardHeading)`
  color: ${(props: { isMentor?: boolean }) =>
    props.isMentor ? "#1C1C1C" : "#58595B"};
`;

export const Separator = styled.hr`
  margin: 1rem -1rem 1rem;
  border: 0;
  border-top: 1px solid #dddddd;
`;

export const CardSubHeading = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #58595b;
  margin-top: 18px;
  margin-bottom: 15px;
`;

export const CardSubHeadingContent = styled.div`
  font-family: ShellBook;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
  color: #58595b;
`; 

export const ShowMoreLess = styled.a`
  font-family: Roboto;
  color: #a7a9ac;
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: 220%;
  text-transform: none;
  cursor: pointer;
`;

export const AboutMeContent = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #58595b;
  overflow-wrap: break-word;
  margin-bottom: 8px;
`;

export const AskMeAboutContent = styled.a`
  font-family: ShellBook;
  line-height: 1.4rem;
  font-size: 1rem;
  line-height: 180%;
  overflow-wrap: break-word;
  color: #58595b;
  text-decoration: none;
  &: hover {
    color: #0099ba;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 75% 25%);
  margin: 0 auto;
  max-width: 80em;
  max-height: 80em;
  grid-gap: 1em;
`;

export const LeftGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: fit-content;
  grid-gap: 1em;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 150px;
`;

export const RightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: fit-content;
  grid-gap: 1em;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 150px;
`;

export const LeftColumn = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 1em;
  width: 33em;
  margin-top: 30px;
`;

export const RightColumn = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 1em;
  width: 15.7em;
  height: fit-content;
  margin-left: -35px;
  margin-top: 30px;
`;

//CSS Expertise Role and Certification card
export const RoleCard = styled.div`
  height: fit-content;
  background: white;
  padding: 2% 1% 3% 3%;
  flex: 10%;
  flex-direction: column;
  font-size: 0.9rem;
  width: 33rem;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  margin-top: 30px;
`;

export const Roles = styled(Col)`
  font-family: ShellBook;
  color: #58595b;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 180%;
`;
export const RoleTitle = styled.div`
  font-size: 0.8rem;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Certification = styled(Col)`
  font-family: ShellLight;
  color: #000000;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-transform: Capitalize;
  margin: -1%;
  margin-bottom: 3px;
`;
// End of Expertise Role and Certification card

//Skill Card
export const Skill = styled.div`
  font-family: ShellBook;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 180%;
  height: fit-content;
  background: white;
  padding: 2% 1% 3% 3%;
  color: #58595b;
  width: 33rem;
  border: 1px solid #dddddd;
  margin-top: 30px;
`;
//Skill Card end

//Top Section Logo, Heading, Searchbar & icon
export const TopHeader = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 40px;
`;

export const Logo = styled.div`
  margin-top: 15px;
  margin-left: 25px;
  height: 30px;
`;

export const HeaderHeading = styled.div`
  position: absolute;
  width: 260px;
  margin-left: 80px;
  margin-top: -36px;
  font-family: shellbook;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 41px;
  color: #58595b;
`;
export const SearchBar = styled.div`
  margin-left: 590px;
  margin-top: -30px;
  margin-bottom: 1rem;
  text-align: center;
`;
export const SearchIcn = styled.div`
  margin-left: 215px;
  margin-top: -31px;
  cursor: pointer;
`;
//Top Section Logo, Heading, Searchbar & icon
