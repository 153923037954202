import * as React from "react";
import "./ViewCard.scss";
import { Card } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { ToolTip } from "../Tooltip";
import helpers from "../../utils/Helper";
import AddJob from "../../containers/Career/AddJob";
import {ReactComponent as Yourssvg} from "../../assets/images/percentage.svg";

interface JobCardProps {
  header;
  body: JSX.Element;
  edit?: boolean;
  percentage?: boolean;
  toolTip?: string | React.ReactNode;
  job: any;
  loading: any;
  addHandler?: (job) => void;
}

export const JobCard: React.FC<JobCardProps> = ({
  header,
  body,
  job,
  edit,
  toolTip,
  percentage,
  addHandler,
  loading,
}: JobCardProps) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    !loading && (
      <>
        <Card.Body>
          <Card.Title className="header">
            <div>
              {header}              
              {percentage&&(<Yourssvg/>)} 
              {toolTip && <ToolTip tooltipMessage={toolTip} />}
            </div>
          </Card.Title>
          {edit && job && helpers.IsObj(job) && (
            <Plus
              className="edit icon-add"
              onClick={() => setShowModal(!showModal)}
            />
          )}
          <Card.Text
            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
          >
            {body}
          </Card.Text>
        </Card.Body>
        {showModal && (
          <AddJob
            header={header}
            props={job}
            show={showModal}
            setShow={setShowModal}
            onUpdate={addHandler}
          />
        )}
      </>
    )
  );
};

export default JobCard;
