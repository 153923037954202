/** Import types */
import {
	DaysOfWeek,
	IContact,
	IRolesAndCerts,
	IOrgChart,
	IFeaturedContent,
	IOption,
	IAccrediation,
	IInterest,
	ICareer,
	IMentoring,
	IUser,
	IMembership,
	IEducation,
	IAskMeAbout,
	IRoles
} from '../custom-types'

import helpers from '../utils/Helper'

export const DEFAULT_WORKING_DAYS: DaysOfWeek = {
	Sun: false,
	Mon: true,
	Tue: true,
	Wed: true,
	Thu: true,
	Fri: true,
	Sat: false,
}

export const CONTACT = ['PreferredName','WorkEmail','Title','Office','SPS-Location','Country','EF-LastUpdated','EF-WorkDays','CellPhone','WorkPhone','EF-MyYammerId']


export const CONST_CONTACT = {
	PREFERRED_NAME: 'PreferredName',
	WORK_EMAIL: 'WorkEmail',
	TITLE: 'Title',
	OFFICE: 'Office',
	SPS_LOCATION: 'SPS-Location',
	COUNTRY: 'Country',
	EF_LASTUPDATED:'EF-LastUpdated',
	EF_WORKDAYS:'EF-WorkDays',
	CELL_PHONE:'CellPhone',
	WORK_PHONE:'WorkPhone'
}
export const EXPERTISE_AREA = ['EF-Function','EF-Function1','EF-Function2','EF-Function3','EF-Discipline','EF-ProcessArea','EF-SubjectMatterExpert']
export const CONST_EXPERTISE_AREA = {
	EF_FUNCTION: 'EF-Function',
	EF_FUNCTION1: 'EF-Function1',
	EF_FUNCTION2: 'EF-Function2',
	EF_FUNCTION3: 'EF-Function3',
	EF_DISCIPLINE: 'EF-Discipline',
	EF_PROCESS_AREA: 'EF-ProcessArea',
	EF_SUBJECTMATTEREXPERT:'EF-SubjectMatterExpert'
}
export const BUSINESS_INFO = ['EF-Business','EF-LOB','EF-LOBCode','Department']

export const ROLES = Array.from({ length: 15 }, (title, idx) => `EF-DE-RoleTitle${++idx}`)
.concat(...Array.from({ length: 15 }, (discipline, idx) => `EF-DE-Discipline${++idx}`))
.concat(...Array.from({ length: 15 }, (expertArea, idx) => `EF-DE-ExpertArea${++idx}`))
.concat(...Array.from({ length: 15 }, (region, idx) => `EF-DE-Region${++idx}`))
.concat(...Array.from({ length: 15 }, (location, idx) => `EF-DE-Location${++idx}`))
.concat(...Array.from({ length: 15 }, (country, idx) => `EF-DE-Country${++idx}`))

export const CERTIFICATIONS = Array.from({ length: 10}, (certification, idx) => `EF-DE-Certification${++idx}`)

export const PROJECTS = Array.from({ length: 9 }, (company, idx) => `EF-PA-ProjectTitle${++idx}`)
.concat(...Array.from({ length: 9}, (jobTitle, idx) => `EF-PA-ProjectJobTitle${++idx}`))
.concat(...Array.from({ length: 9 }, (description, idx) => `EF-PA-ResponsibilityArea${++idx}`))
.concat(...Array.from({ length: 9 }, (country, idx) => `EF-PA-ProjectLocation${++idx}`))
.concat(...Array.from({ length: 9 }, (startDate, idx) => `EF-PA-ProjectStartDate${++idx}`))
.concat(...Array.from({ length: 9 }, (endDate, idx) => `EF-PA-ProjectEndDate${++idx}`))
.concat(...Array.from({ length: 9 }, (asset, idx) => `EF-PA-Asset${++idx}`))
.concat(...Array.from({ length: 9 }, (capital, idx) => `EF-PA-CapitalProjects${++idx}`))
.concat(...Array.from({ length: 9 }, (capital, idx) => `EF-PA-PivotalRole${++idx}`))

export const CURRENT_JOB = ['Title', 'EF-PA-ProjectJobTitle10', 'EF-PA-ProjectTitle10', 'EF-PA-ResponsibilityArea10', 'EF-PA-ProjectLocation10', 'EF-PA-ProjectStartDate10', 'EF-PA-Asset10', 'EF-PA-CapitalProjects10','EF-PA-PivotalRole10']

export const DELEGATE =['Assistant']
export const ORGCHART = []
const DEFAULT_USER: IUser = {
	Title: '',
	LoginName: '',
	Email: '',
	Img: '',	
}

export const LOCAL_USER: IUser = {
	Title: 'Nagesh KS',
	LoginName: 'i:0#.f|membership|n.shivalingegowda@shell.com',
	Email: 'n.shivalingegowda@shell.com',
	Img: `https://eu001-sp.shell.com/sites/AAAAB0586/_layouts/15/userphoto.aspx?size=L&accountname=n.shivalingegowda@shell.com`	
}
export const CONST_LOCAL_USER = {
	Title: 'Nagesh KS',
	LoginName: 'i:0#.f|membership|n.shivalingegowda@shell.com',
	Email: 'n.shivalingegowda@shell.com',
	Img: `https://eu001-sp.shell.com/sites/AAAAB0586/_layouts/15/userphoto.aspx?size=L&accountname=n.shivalingegowda@shell.com`,
	LOCALHOST:'localhost',
	SP_PROPERTYWEB:'SP_Propertyweb'
}

export const DEFAULT_ORGCHART = {
	CurrentUser: DEFAULT_USER,
	LineManager: DEFAULT_USER,
	Reportees: [DEFAULT_USER]
}

export const LOCAL_ORGCHART = {
	CurrentUser: LOCAL_USER,
	LineManager: LOCAL_USER,
	Reportees: [LOCAL_USER]
}

export const EDUCATION = Array.from({ length: 5 }, (degree, idx) => `EF-ED-Degree${++idx}`)
.concat(...Array.from({ length: 5 }, (school, idx) => `EF-ED-SchoolName${++idx}`))
.concat(...Array.from({ length: 5 }, (country, idx) => `EF-ED-Country${++idx}`))

export const NOTIFICATION_MESSAGE = {
	Success: 'Your update(s) has been saved successfully',
	Error: 'Your update(s) cannot be saved at this moment, Please try again.',
	PreferenceSuccess: 'Your preference has been set successfully.Thank you for your submission.',
	PreferenceError:
		'Your preference cannot be set at this moment, Please try again',
	LinkCopied:'Profile link has been copied to clipboard',
}

export const EDIT_CARD_PROPS = {
	ContactCard: {
		WorkingDays: 'EF-WorkDays',
		OfficeLocation: 'SPS-Location',
		phone:'CellPhone',
	},
	AboutMeCard: {
		isMentoring: 'EF-MentoringAvailable',
		aboutMe: 'AboutMe',
	},
	BusinessCard: {
		function: 'EF-Function',
		function1: 'EF-Function1',
		function2: 'EF-Function2',
		function3: 'EF-Function3',
		discipline: 'EF-Discipline',
		pte: 'EF-ProcessArea',
		sme: 'EF-SubjectMatterExpert'
	},
	JobCard: {
		jobTitle: 'EF-PA-ProjectJobTitle',
		company: 'EF-PA-ProjectTitle',
		description: 'EF-PA-ResponsibilityArea',
		country: 'EF-PA-ProjectLocation',
		startDate: 'EF-PA-ProjectStartDate',
		endDate: 'EF-PA-ProjectEndDate',
		asset: 'EF-PA-Asset',
		capitalProject: 'EF-PA-CapitalProjects',
		role: 'EF-PA-PivotalRole'
	},
	EducationCard: {
		degree: 'EF-ED-Degree',
		school: 'EF-ED-SchoolName',
		country: 'EF-ED-Country'		
	},
	SkillsCard: {
		skills: 'SPS-Skills'
	},
	FeaturedContentCard: {
		featuredContent : 'EF-FeaturedContent1'
	},
	InterestsCard: {
		interests: "SPS-Interests"
	},
	AskMeAboutCard: {
		askMeAbout: "SPS-Responsibility"
	},
	AccreditationCard: {
		accreditation: "EF-CertsAndCredits"
	},
	MembershipCard: {
		membership: "EF-ProfessionalMemberships"
	},
	LanguageCard: {
		languages: "EF-KnownLanguages"
	},
	OrgChart: {
		Manager: 'Manager',
		Reportees: 'DirectReports',
		
	},
	LastUpdated: 'EF-LastUpdated',
	IsTourViewed : 'EF-Mentoring',
	Delegate:'Assistant',
}

export enum UpdateProperty {
	single = '/_api/SP.UserProfiles.PeopleManager/SetSingleValueProfileProperty',
	multiple = '/_api/SP.UserProfiles.PeopleManager/SetMultiValuedProfileProperty',
}

export enum ContactActionType {
	profileReady = 'PROFILE_READY',
	reload = 'RELOAD_PROFILE',
	setContact = 'UPDATE_CONTACT',
}

export const themes = {
	color: {
		dark: '#1d2326',
		med: '#373b3e',
		light: '#f2fafe',
	},
	pad: {
		sm: '0.25rem',
		md: '0.5rem',
		lg: '1rem',
		xl: '2rem',
	},
	radius: {
		sm: '0.25rem',
		md: '0.5rem',
		lg: '1rem',
		xl: '2rem',
	},
	width: {
		sm: '50%',
		lg: '100%',
	},
	shadow: {
		lg:
			'0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
	},
}

//Start - Business information card
export const DEFAULT_BUSINESSINFO = {
	'EF-Function': '',
	'EF-Function1': '',
	'EF-Function2': '',
	'EF-Discipline': '',
	'EF-Business': '',
	'EF-ProcessArea': '',
	'EF-SubjectMatterExpert': '',
	'EF-LOB': '',
	'EF-LOBCode': '',
	Department: '',
}

export const LOCAL_BUSINESSINFO = {
	'EF-Function': 'Development',
	'EF-Function1': 'Exploration',
	'EF-Function2': 'Projects',
	'EF-Discipline': 'Technical Data Management|Development|Worker Welfare',
	'EF-Business': 'Information Technology',
	'EF-ProcessArea': 'Knowledge Management',
	'EF-SubjectMatterExpert': 'Building',
	'EF-LOB': 'Information Technology',
	'EF-LOBCode': 'ITY',
	Department: 'Lead Microsoft',
}

export enum BusinessInfoActionType {
	businessInfoReady = 'BUSINESSINFORMATION_READY',
	businessInfoReload = 'BUSINESSINFORMATION_RELOAD',
	setDisciplineCommunity = 'UPDATE_DISCIPLINE_COMMUNITY',
}
// End - Business information card

// Start - Featured Content
export const DEFAULT_FEATURED_CONTENT: IFeaturedContent = {
	'EF-Publications': '',
}

export const LOCAL_FEATURED_CONTENT: IFeaturedContent = {
	'EF-Publications':
		'Test.pdf|Publication Requests|word doc.docx|A little lengthy Length lengthy sentence',
}

export const DEFAULT_ACCREDITATION_MEMBERSHIP: IAccrediation = {
	'EF-CertsAndCredits': '',
	'EF-ProfessionalMemberships': '',
}

export const LOCAL_ACCREDITATION_MEMBERSHIP: IAccrediation = {
	'EF-CertsAndCredits':
		'Microsoft Certification - Developing Microsoft SharePoint Server 2013 Core Solutions -|AZURE AZ 900|React Js|Certificatoin with date - 16 June 2020|https://eu023-sp.shell.com/sites/SPOAA1145/SitePages/Home.aspx',
	'EF-ProfessionalMemberships':
		'Pro Mem 1|Pro Mem 2|Pro mebership for comma separated|converts comma to semicolon by default|https://eu023-sp.shell.com/sites/SPOAA1145/SitePages/Home.aspx',
}

export const DEFAULT_ACCREDITATION: IAccrediation = {
	'EF-CertsAndCredits': '',
}

export const LOCAL_ACCREDITATION: IAccrediation = {
	'EF-CertsAndCredits':
		'Microsoft Certification - Developing Microsoft SharePoint Server 2013 Core Solutions -|AZURE AZ 900|React Js|Certificatoin with date - 16 June 2020|https://eu023-sp.shell.com/sites/SPOAA1145/SitePages/Home.aspx',
}

export const DEFAULT_MEMBERSHIP = {
	'EF-ProfessionalMemberships': ''
}

export const LOCAL_MEMBERSHIP = {
	'EF-ProfessionalMemberships': 'Test1|Test2|Test3'
}

export const DEFAULT_AKEMEABOUT: IAskMeAbout = {
	'SPS-Responsibility': '',
}

export const LOCAL_ASKMEABOUT: IAskMeAbout = {
	'SPS-Responsibility': 'Test1|Test2|Test3',
}

export const DEFAULT_EDUCATION: IEducation = {
	'EF-ED-Degree1': '',
	'EF-ED-SchoolName1': '',
	'EF-ED-Country1': '',

	'EF-ED-Degree2': '',
	'EF-ED-SchoolName2': '',
	'EF-ED-Country2': '',

	'EF-ED-Degree3': '',
	'EF-ED-SchoolName3': '',
	'EF-ED-Country3': '',
}

export const LOCAL_EDUCATION: IEducation = {
	'EF-ED-Degree1': 'High School',
	'EF-ED-SchoolName1': 'SVM',
	'EF-ED-Country1': 'INDIA',

	'EF-ED-Degree2': 'BE',
	'EF-ED-SchoolName2': 'SRM',
	'EF-ED-Country2': 'INDIA',

	'EF-ED-Degree3': 'MBA',
	'EF-ED-SchoolName3': 'SRM',
	'EF-ED-Country3': 'INDIA',
}

export const DEFAULT_INTEREST = {
	'SPS-Interests': '',
}

export const LOCAL_INTEREST = {
	'SPS-Interests': 'Team Management|Exploring',
}

//End - Interest Card

export const DEFAULT_LANGUAGE = {
	'EF-KnownLanguages': '',
}

export const LOCAL_LANGUAGE = {
	'EF-KnownLanguages': 'English|Tamil',
}

// Start - Career
export const DEFAULT_CAREER: ICareer = {
	Title: '',
	'EF-PA-ProjectTitle1': '',
	'EF-PA-ProjectJobTitle1': '',
	'EF-PA-ResponsibilityArea1': '',
	'EF-PA-ProjectLocation1': '',
	'EF-PA-ProjectStartDate1': '',
	'EF-PA-ProjectEndDate1': '',
	'EF-PA-Asset1': '',
	'EF-PA-CapitalProjects1': '',

	'EF-PA-ProjectTitle2': '',
	'EF-PA-ProjectJobTitle2': '',
	'EF-PA-ResponsibilityArea2': '',
	'EF-PA-ProjectLocation2': '',
	'EF-PA-ProjectStartDate2': '',
	'EF-PA-ProjectEndDate2': '',
	'EF-PA-Asset2': '',
	'EF-PA-CapitalProjects2': '',

	'EF-PA-ProjectTitle3': '',
	'EF-PA-ProjectJobTitle3': '',
	'EF-PA-ResponsibilityArea3': '',
	'EF-PA-ProjectLocation3': '',
	'EF-PA-ProjectStartDate3': '',
	'EF-PA-ProjectEndDate3': '',
	'EF-PA-Asset3': '',
	'EF-PA-CapitalProjects3': '',

	'EF-PA-ProjectTitle4': '',
	'EF-PA-ProjectJobTitle4': '',
	'EF-PA-ResponsibilityArea4': '',
	'EF-PA-ProjectLocation4': '',
	'EF-PA-ProjectStartDate4': '',
	'EF-PA-ProjectEndDate4': '',
	'EF-PA-Asset4': '',
	'EF-PA-CapitalProjects4': '',

	'EF-PA-ProjectTitle5': '',
	'EF-PA-ProjectJobTitle5': '',
	'EF-PA-ResponsibilityArea5': '',
	'EF-PA-ProjectLocation5': '',
	'EF-PA-ProjectStartDate5': '',
	'EF-PA-ProjectEndDate5': '',
	'EF-PA-Asset5': '',
	'EF-PA-CapitalProjects5': '',

	'EF-PA-ProjectTitle6': '',
	'EF-PA-ProjectJobTitle6': '',
	'EF-PA-ResponsibilityArea6': '',
	'EF-PA-ProjectLocation6': '',
	'EF-PA-ProjectStartDate6': '',
	'EF-PA-ProjectEndDate6': '',
	'EF-PA-Asset6': '',
	'EF-PA-CapitalProjects6': '',

	'EF-PA-ProjectTitle7': '',
	'EF-PA-ProjectJobTitle7': '',
	'EF-PA-ResponsibilityArea7': '',
	'EF-PA-ProjectLocation7': '',
	'EF-PA-ProjectStartDate7': '',
	'EF-PA-ProjectEndDate7': '',
	'EF-PA-Asset7': '',
	'EF-PA-CapitalProjects7': '',
}
export enum CareerActionType {
	profileReady = 'PROFILE_READY',
	reload = 'RELOAD_PROFILE',
	setCareer = 'UPDATE_CAREER',
}
// End - Career
