import * as React from "react";
import Edit from "./EditFeaturedContent";

import "../FeaturedContent/FeaturedContent.scss";
import SearchRedirect from '../../components/SearchRedirect';
/** Context */
import { Context } from "../../contexts/Context";
import ViewCard from "../../components/ViewCard/ViewCard";

/** Constants */
import { EDIT_CARD_PROPS } from "../../utils/Consts";

/** Utils */
import helpers,{ removeHTML } from "../../utils/Helper";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

export const FeaturedContent: React.FC<ICardComponentProps> = ({
  header,
  edit,
  tooltip,
}) => {
  const { state, dispatch } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(
    [EDIT_CARD_PROPS.FeaturedContentCard.featuredContent],
    userProps
  );
  const [showModal, setShowModal] = React.useState(false);
  const [featuredContent, setFeaturedContent] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  React.useEffect(() => updateSection(), [featuredContent]);

  const onLoad = async () => {
    try {
      if (helpers.IsObj(userProps)) {
        const props = await getCardProps();
        setFeaturedContent(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const handleUpdate = React.useCallback(
    (val) => {
      setFeaturedContent(val);
    },
    [featuredContent]
  );

  const updateSection = () => {
    !featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] &&
    featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] ===
      "" &&
    !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
          type: "ADD_SECTION",
          payload: {
            title: `${header}`,
            showPercentage:false,
            handler: () => setShowModal(!showModal),
          },
        })
      : featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] &&
      featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] !==
      "" && 
      removeHTML(featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]) !==
          "" &&
        dispatch({
          type: "REMOVE_SECTION",
          payload: { 
            title: `${header}`,
          showPercentage:false,
          },
        });
  };

  const main: JSX.Element = featuredContent &&
    featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] &&
    featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent] !==
      "" && 
      removeHTML(featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent]) !==
      ""&&(
       
        <div className="hyperlink"
        dangerouslySetInnerHTML={{
          __html: featuredContent[EDIT_CARD_PROPS.FeaturedContentCard.featuredContent],
        }}
        ></div>
       
    );

  return showModal ? (
    <Edit
      header={header}
      props={featuredContent}
      show={showModal}
      setShow={setShowModal}
      onUpdate={handleUpdate}
    />
  ) : main ? (
    <ViewCard
      header={header}
      body={main}
      edit={edit}
      loading={loading}
      editHandler={() => setShowModal(!showModal)}
      toolTip={tooltip}
    />
  ) : null;
};

export default FeaturedContent;
