import * as React from "react";
/** Context */
import { Context } from "../../contexts/Context";

import { useCallback } from "react";

/** Local taxonomy store */
import expertiseAreas from "../Taxonomy.json";
import officeLocations from "../OfficeLocations.json";
import jobTitles from "../jobTitles.json";
import assets from "../Assets.json";
import capitalProjects from "../CapitalProjects.json";
import language from "../Language.json";

import helpers, {
  getTaxonomySet,
  getFolksonomySet
} from "../Helper";

const useTaxonomyHandler = (initialState?: any) => {
  const { state } = React.useContext(Context);

  const getExpertiseAreas = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "ExpertiseArea")[0].Value);
    return taxonomy;
  }, []);
  const getSkills = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Skills")[0].Value);
    return taxonomy;
  }, []);
  const getSchools = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getFolksonomySet(state.config.filter(obj => obj.Key === "Schools")[0].Value);
    return taxonomy;
  }, []);
  const getDegree = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getFolksonomySet(state.config.filter(obj => obj.Key === "Degree")[0].Value);
    return taxonomy;
  }, []);
  const getProf = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Membership")[0].Value);
    return taxonomy;
  }, []);
  const getCred = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Certs")[0].Value);
    return taxonomy;
  }, []);
  const getResp = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Responsibility")[0].Value);
    return taxonomy;
  }, []);
  const getInterests = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Interesets")[0].Value);
    return taxonomy;
  }, []);

  const getOfficeLocations = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? officeLocations
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Office")[0].Value);
    return taxonomy;
  }, []);

   const getJobTitles = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? jobTitles
        : await getFolksonomySet(state.config.filter(obj => obj.Key === "JobTitles")[0].Value);
    return taxonomy;
  }, []);

  const getRoles = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? expertiseAreas
        : await getFolksonomySet(state.config.filter(obj => obj.Key === "Roles")[0].Value);
    return taxonomy;
  }, []);

  const getAssets = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? assets
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Assets")[0].Value);
    return taxonomy;
  }, []);

  const getCountry = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? assets
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Country")[0].Value);
    return taxonomy;
  }, []);

  const getCapitalProjects = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? capitalProjects
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "CapitalProjects")[0].Value);
    return taxonomy;
  }, []);

  const getLanguage = useCallback(async () => {
    const taxonomy =
      window.location.href.toLowerCase().indexOf("localhost") !== -1
        ? language
        : await getTaxonomySet(state.config.filter(obj => obj.Key === "Languages")[0].Value);
    return taxonomy;
  }, []);  

  return {
    getExpertiseAreas,
    getOfficeLocations,
    getJobTitles,
    getAssets,
    getCapitalProjects,
    getLanguage,
    getSkills,getCred,getInterests,getProf,getResp,getSchools,getCountry,getDegree,getRoles
  };
};

export default useTaxonomyHandler;
