import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

import ViewCard from "../../components/ViewCard/ViewCard";
import SearchRedirect from '../../components/SearchRedirect';
/** card resize handler */
import helper from "../../utils/Helper";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Constants */
import { CERTIFICATIONS } from "../../utils/Consts";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

export const Certifications: React.FC<ICardComponentProps> = ({ tooltip }) => {
  const { state } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(CERTIFICATIONS, userProps);
  const [certifications, setCertifications] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setCertifications(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const main: JSX.Element = (
    <ul className="bulletPoint">
      {CERTIFICATIONS.map((certId,index) =>
          certifications[certId] &&
          certifications[certId].length && (
            <li key={certId}>
              <SearchRedirect
                index={index}
                searchKey={'FormalCertification'}
                keyword={certifications[certId]}
                redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                isTagSingle={true}       
              />
            </li>
          )
      )}
    </ul>
  );

  const toolTip = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="info-tooltip">
          Certified by Shell Commercial Academy, Shell Project Academy &amp;
          Maritime
        </Tooltip>
      }
    >
      <InfoCircle className="info-circle-tooltip-icon" />
    </OverlayTrigger>
  );

  return (
    CERTIFICATIONS.filter((cert) => certifications[cert]).length > 0 && (
      <ViewCard
        loading={loading}
        header="Expertise Certification"
        body={main}
        toolTip={tooltip}
      />
    )
  );
};

export default Certifications;
