import { useState, useEffect, useCallback } from "react";
import helpers, { getProfileProps,getURL } from "../Helper";
import UserProfile from "../UserProfile.json";
import Configuration from "../Configuration.json";
const useFetchHandler = (initialState: string) => {
  const [userProps, setUserProps] = useState({});
  const [config, setConfig] = useState({});  

  const getUserProps = useCallback(async () => {
    try { 
      const user = initialState
        ? await helpers.getProfileUser(initialState)
        : await helpers.getLoggedInUser();

      const response =
        window.location.href.toLowerCase().indexOf("localhost") !== -1
          ? UserProfile
          : await getProfileProps(encodeURIComponent(user.LoginName));
      setUserProps(response);
      const configResponse =
        window.location.href.toLowerCase().indexOf("localhost") !== -1
          ? Configuration
          : await getURL(helpers.configFile);
      setConfig(configResponse);
           
    } catch (err) {
      console.log({ err });
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    getUserProps();
  }, [initialState]);

  return { userProps, getUserProps,config,setConfig };
};

export default useFetchHandler;
