import React from 'react'

function Cam(props) {
	const title = props.title || 'Edit Photo'

	return (
		<svg
			height="24"
			width="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{title}</title>
			<g fill="#fcfcfc" stroke="#fcfcfc" strokeWidth="2">
				<line
					fill="none"
					stroke="#fcfcfc"
					strokeLinecap="square"
					x1="3"
					x2="6"
					y1="2"
					y2="2"
				/>
				<polygon
					fill="none"
					points="1,22 23,22 23,5 19,5 17,2 11,2 9,5 1,5 "
					stroke="#fcfcfc"
					strokeLinecap="square"
				/>
				<circle cx="14" cy="13" fill="none" r="5" strokeLinecap="square" />
				<circle cx="5" cy="9" r="1" stroke="none" strokeLinecap="square" />
			</g>
		</svg>
	)
}

export default Cam
