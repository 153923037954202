import * as React from "react";

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Add from "../../assets/icons/Add";
import Delete from "../../assets/icons/Delete";
import EditCard from "../../components/EditCard";

import Select, { components } from "react-select";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom hooks */
import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import { EDIT_CARD_PROPS,NOTIFICATION_MESSAGE, UpdateProperty } from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperty,
  getSelectedOption,
  getDefaultOptions,
  updateProperties,
  arrayToObject,
  getTodaysDateTimeString
} from "../../utils/Helper";

const EditExpertArea: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const [selectedFunction, setSelectedFunction] = React.useState<string>(
    props[EDIT_CARD_PROPS.BusinessCard.function] || null
  );
  const [selectedFunction1, setSelectedFunction1] = React.useState<string>(
    props[EDIT_CARD_PROPS.BusinessCard.function1] || null
  );
  const [selectedFunction2, setSelectedFunction2] = React.useState<string>(
    props[EDIT_CARD_PROPS.BusinessCard.function2] || null
  );
  const [selectedFunction3, setSelectedFunction3] = React.useState<string>(
    props[EDIT_CARD_PROPS.BusinessCard.function3] || null
  );
  const [selectedDisciplines, setSelectedDisciplines] = React.useState<
    string[]
  >(
    props[EDIT_CARD_PROPS.BusinessCard.discipline]
      ? props[EDIT_CARD_PROPS.BusinessCard.discipline].split("|")
      : []
  );
  const [selectedPTE, setSelectedPTE] = React.useState<string[]>(
    props[EDIT_CARD_PROPS.BusinessCard.pte]
      ? props[EDIT_CARD_PROPS.BusinessCard.pte].split("|")
      : []
  );
  const [selectedSME, setSelectedSME] = React.useState<string[]>(
    props[EDIT_CARD_PROPS.BusinessCard.sme]
      ? props[EDIT_CARD_PROPS.BusinessCard.sme].split("|")
      : []
  );

  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const { getExpertiseAreas } = useTaxonomyHandler();
  const [functionRow, setFunctionRow] = React.useState(() => []);
  const [expertiseArea, setExpertiseArea] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [functions, loadFunctions] = React.useState<string[]>([]);
  const [disciplineOptions, loadDisciplineOptions] = React.useState<string[]>(
    []
  );
  const [pteOptions, loadPteOptions] = React.useState<string[]>([]);
  const [smeOptions, loadSmeOptions] = React.useState<string[]>([]);
  const [disciplineData, setDisciplineData] = React.useState([]);
  const [PTEData, setPTEData] = React.useState([]);

  React.useEffect(() => {
    onLoad();
  }, []);

  React.useEffect(() => {
    Object.keys(expertiseArea).length &&
      loadFunctions(Object.keys(expertiseArea).sort());
  }, [expertiseArea]);

  React.useEffect(() => {
    if (functions.length && Object.keys(expertiseArea).length) {
      const disciplines = loadPrimaryExpertiseArea(
        [selectedFunction, selectedFunction1, selectedFunction2,selectedFunction3],
        expertiseArea
      );
      setDisciplineData(disciplines);
      loadDisciplineOptions(disciplines.map((o) => o.value).sort());
      const disciplineLoaded = disciplines.map((o) => o.value).sort();
      setSelectedDisciplines(
        selectedDisciplines.filter((discipline) =>
          disciplineLoaded.includes(discipline)
        )
      );
    }
  }, [
    functions,
    expertiseArea,
    selectedFunction,
    selectedFunction1,
    selectedFunction2,
    selectedFunction3,
  ]);

  React.useEffect(() => {
    if (disciplineData.length && disciplineOptions.length) {
      const ptes = loadSecondaryExpertiseArea(
        selectedDisciplines.filter((discipline) =>
          disciplineOptions.includes(discipline)
        ),
        disciplineData
      );
      setPTEData(ptes);
      loadPteOptions(ptes.map((o) => o.value).sort());
      const pteLoaded = ptes.map((o) => o.value).sort();
      setSelectedPTE(selectedPTE.filter((pte) => pteLoaded.includes(pte)));
    } else {
      loadPteOptions([]);
      loadSmeOptions([]);
      if (selectedDisciplines.length === 0) {
        setSelectedPTE([]);
        setSelectedSME([]);
      }
    }
  }, [disciplineData, selectedDisciplines]);

  React.useEffect(() => {
    if (PTEData.length && pteOptions.length) {
      const smes = loadSecondaryExpertiseArea(
        selectedPTE.filter((pte) => pteOptions.includes(pte)),
        PTEData
      );
      loadSmeOptions(smes.map((o) => o.value).sort());
      const smeLoaded = smes.map((o) => o.value).sort();
      setSelectedSME(selectedSME.filter((sme) => smeLoaded.includes(sme)));
    } else {
      loadSmeOptions([]);
      if (selectedDisciplines.length === 0) setSelectedSME([]);
    }
  }, [PTEData, selectedPTE]);

   const loadPrimaryExpertiseArea = (selected, options) => {
    let temp = [];
    selected.forEach((o) => {
      if (o)
        Object.keys(options[o]).map((key) => {
          if (key !== "checked" && key !== "items" && key !== "ID") {
            temp.push({
              key: options[o][key].ID,
              value: key,
              label: key,
              items: options[o][key],
            });
          }
        });
    });
    return temp;
  };

  const loadSecondaryExpertiseArea = (selected, options) => {
    let temp = [];
    const expertiseArea = Array.isArray(options)
      ? arrayToObject(options, "label")
      : options;
    selected.forEach((o) => {
      Object.keys(expertiseArea[o].items).map((key) => {
        if (key !== "items" && key !== "checked" && key !== "ID") {
          temp.push({
            key: expertiseArea[o].items[key].ID,
            value: key,
            label: key,
            items: expertiseArea[o].items[key],
          });
        }
      });
    });
    return temp;
  };

  const onLoad = async () => {
    try {
      const expertiseAreas = await getExpertiseAreas();
      setExpertiseArea(expertiseAreas);
      selectedFunction1 && handleAddFunction("additionalFunction1");
      selectedFunction2 && handleAddFunction("additionalFunction2");
      selectedFunction3 && handleAddFunction("additionalFunction3");
      setLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const additionalFunction1 = (
    <Form.Group className="mb-4" controlId="function1">
      <Form.Label className="shellSmallText" >
        Function
      </Form.Label>
      <Select 
        style={{
          fontFamily: "ShellBook",
          fontSize: "small",
        }}
        options={getDefaultOptions(functions).filter(
          (option) =>
            ![selectedFunction, selectedFunction2].includes(option.value)
        )}
        value={selectedFunction1 && getSelectedOption(selectedFunction1)}
        onChange={(e) =>
          e ? setSelectedFunction1(e.value) : setSelectedFunction1(null)
        }
        isClearable
        isSearchable
        components={{
          Placeholder: ({ children, ...props }) => {
            return (
              <components.Placeholder {...props}>
                {"Click to select"}
              </components.Placeholder>
            );
          },
        }}
      />
    </Form.Group>
  );

  const additionalFunction2 = (
    <Form.Group className="mb-4" controlId="function2">
      <Form.Label className="shellSmallText">
        Function
      </Form.Label>
      <Select
        style={{
          fontFamily: "ShellBook",
          fontSize: "small",
        }}
        options={getDefaultOptions(functions).filter(
          (option) =>
            ![selectedFunction, selectedFunction1].includes(option.value)
        )}
        value={selectedFunction2 && getSelectedOption(selectedFunction2)}
        onChange={(e) =>
          e ? setSelectedFunction2(e.value) : setSelectedFunction2(null)
        }
        isClearable
        isSearchable
        components={{
          Placeholder: ({ children, ...props }) => {
            return (
              <components.Placeholder {...props}>
                {"Click to select"}
              </components.Placeholder>
            );
          },
        }}
      />
    </Form.Group>
  );

  const additionalFunction3 = (
    <Form.Group className="mb-4" controlId="function3">
      <Form.Label className="shellSmallText">
        Function
      </Form.Label>
      <Select
        style={{
          fontFamily: "ShellBook",
          fontSize: "small",
        }}
        options={getDefaultOptions(functions).filter(
          (option) =>
            ![selectedFunction, selectedFunction1,selectedFunction2].includes(option.value)
        )}
        value={selectedFunction3 && getSelectedOption(selectedFunction3)}
        onChange={(e) =>
          e ? setSelectedFunction3(e.value) : setSelectedFunction3(null)
        }
        isClearable
        isSearchable
        components={{
          Placeholder: ({ children, ...props }) => {
            return (
              <components.Placeholder {...props}>
                {"Click to select"}
              </components.Placeholder>
            );
          },
        }}
      />
    </Form.Group>
  );

  const handleAddFunction = (value) => {
    setFunctionRow((prevState) => {
      const functionToAdd = { content: value };
      return [...prevState, functionToAdd];
    });
  };

  const handleRemoveFunction = (value) => {
    value === "additionalFunction3"
      ? setSelectedFunction3(null)
      : (value === "additionalFunction2"?setSelectedFunction2(null):setSelectedFunction1(null));
      setFunctionRow((prevState) => {
      return [...prevState.slice(0, -1)];
    });
  };

  const handleDisciplineChange = (value) => {
    if (value) {
      value = value.length > 4 ? selectedDisciplines : value;
      setSelectedDisciplines(value.map((item) => item.value));
      pteOptions.length &&
        setSelectedPTE(selectedPTE.filter((pte) => pteOptions.includes(pte)));
    } else {
      setSelectedDisciplines([]);
    }
  };

  const handlePTEChange = (value) => {
    if (value) {
      value = value.length > 4 ? selectedPTE : value;
      setSelectedPTE(value.map((item) => item.value));
      smeOptions.length &&
        setSelectedSME(selectedSME.filter((sme) => smeOptions.includes(sme)));
    } else {
      setSelectedPTE([]);
    }
  };

  const handleSMEChange = (value) => {
    if (value) {
      value = value.length > 4 ? selectedSME : value;
      setSelectedSME(value.map((item) => item.value));
    } else {
      setSelectedSME([]);
    }
  };

  const main: JSX.Element =
    functions && functions.length && expertiseArea ? (
      <Container role="main">
        <Form.Group className="mb-4" controlId="function">
          <Form.Label className="shellSmallText" >
            Function
          </Form.Label>
          <Select
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
            options={getDefaultOptions(functions).filter(
              (option) =>
                ![selectedFunction1, selectedFunction2,selectedFunction3].includes(option.value)
            )}
            value={selectedFunction && getSelectedOption(selectedFunction)}
            onChange={(val) =>
              val ? setSelectedFunction(val.value) : setSelectedFunction(null)
            }
            isSearchable
            required={true}
            components={{
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Click to select"}
                  </components.Placeholder>
                );
              },
            }}
          />
          <Form.Text className="textMuted">
            Please select your Global or Technical Function. For example:
            Finance, Human Resources, Discipline Engineering or Wells. Maximum of 4 functions can be added.
          </Form.Text>
        </Form.Group>

        {functionRow.map((row) => {
          return row.content.indexOf("additionalFunction1") !== -1 ? (
            <React.Fragment>{additionalFunction1}</React.Fragment>
          ) : (row.content.indexOf("additionalFunction2") !== -1 ?
            <React.Fragment>{additionalFunction2}</React.Fragment>:<React.Fragment>{additionalFunction3}</React.Fragment>
          );
        })}

        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          {functionRow.length < 3 && (
            <Form.Label>
              <span
                style={{ color: "#17a2b8", cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  functionRow.length > 0
                    ? (functionRow.length > 1?handleAddFunction("additionalFunction3"):handleAddFunction("additionalFunction2"))
                    : handleAddFunction("additionalFunction1");
                }}
              >
                <Add /> Add new
              </span>
            </Form.Label>
          )}
          {functionRow.length !== 0 && (
            <Form.Label style={{ marginLeft: "1vw" }}>
              {Boolean(functionRow.length) && (
                <span
                  style={{ color: "#E58271", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    functionRow.length > 1
                      ? (functionRow.length > 2?handleRemoveFunction("additionalFunction3"):handleRemoveFunction("additionalFunction2"))
                      : handleRemoveFunction("additionalFunction1");
                  }}
                >
                  <Delete /> Remove
                </span>
              )}
            </Form.Label>
          )}
        </Form.Group>

        <Form.Group className="mb-4" controlId="disciplines">
          <Form.Label className="shellSmallText" >
            Discipline / Community
          </Form.Label>
          <Select
            isMulti
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
            options={
              selectedDisciplines &&
              selectedDisciplines.filter(
                (discipline) => disciplineOptions.indexOf(discipline) !== -1
              ).length === 4
                ? []
                : getDefaultOptions(disciplineOptions)
            }
            noOptionsMessage={() =>
              selectedDisciplines && selectedDisciplines.length === 4
                ? `You've reached the maximum selection`
                : "No options available"
            }
            value={
              selectedDisciplines &&
              getDefaultOptions(
                selectedDisciplines.filter(
                  (discipline) => disciplineOptions.indexOf(discipline) !== -1
                )
              )
            }
            onChange={handleDisciplineChange}
            isClearable
            isSearchable
            components={{
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Search / Select"}
                  </components.Placeholder>
                );
              },
            }}
          />
          <Form.Text className="textMuted">
            Please enter your current Disciplines and/or Professional
            Communities. You can enter a maximum of four. Your Disciplines and
            Communities appear on your Profile page and help others to find you
            when searching for Discipline or Community related expertise.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="ptes">
          <Form.Label className="shellSmallText" >
            Principal Expertise Area
          </Form.Label>
          <Select
            isMulti
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
            options={
              selectedDisciplines.length &&
              selectedPTE.filter((pte) => pteOptions.indexOf(pte) !== -1)
                .length === 4
                ? []
                : getDefaultOptions(pteOptions)
            }
            noOptionsMessage={() =>
              selectedPTE && selectedPTE.length === 4
                ? `You've reached the maximum selection`
                : "No options available"
            }
            value={
              selectedPTE &&
              getDefaultOptions(
                selectedPTE.filter((pte) => pteOptions.indexOf(pte) !== -1)
              )
            }
            onChange={handlePTEChange}
            isClearable
            isSearchable
            components={{
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Search / Select"}
                  </components.Placeholder>
                );
              },
            }}
          />
          <Form.Text className="textMuted">
            Please enter your current Expertise and/or Process Area. You can
            enter a maximum of four. Your Expertise and Process Area appear on
            your Profile page and help others to find you when searching for
            Expertise or Process Area related expertise.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-4" controlId="ptes">
          <Form.Label className="shellSmallText">
            Sub Expertise Area
          </Form.Label>
          <Select
            isMulti
            style={{
              fontFamily: "ShellBook",
              fontSize: "small",
            }}
            options={
              selectedPTE.length &&
              selectedSME.filter((sme) => smeOptions.indexOf(sme) !== -1)
                .length === 4
                ? []
                : getDefaultOptions(smeOptions)
            }
            noOptionsMessage={() =>
              selectedSME && selectedSME.length === 4
                ? `You've reached the maximum selection`
                : "No options available"
            }
            value={
              selectedSME &&
              getDefaultOptions(
                selectedSME.filter((sme) => smeOptions.indexOf(sme) !== -1)
              )
            }
            onChange={handleSMEChange}
            isClearable
            isSearchable
            components={{
              Placeholder: ({ children, ...props }) => {
                return (
                  <components.Placeholder {...props}>
                    {"Search / Select"}
                  </components.Placeholder>
                );
              },
            }}
          />
          <Form.Text className="textMuted">
            Please enter your current Subject Matter Expertise. You can enter a
            maximum of four. Your Subject Matter Expertise appear on your
            Profile page and help others to find you when searching for Subject
            Matter Expertise related expertise.
          </Form.Text>
        </Form.Group>
      </Container>
    ) : (
      <div className="p-5 text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );

  const updateExpertArea = React.useCallback(async () => {
    if (
      selectedFunction &&
      (props[EDIT_CARD_PROPS.BusinessCard.function] !== selectedFunction ||
        props[EDIT_CARD_PROPS.BusinessCard.function1] !== selectedFunction1 ||
        props[EDIT_CARD_PROPS.BusinessCard.function2] !== selectedFunction2 ||
        props[EDIT_CARD_PROPS.BusinessCard.function3] !== selectedFunction3 ||
        props[EDIT_CARD_PROPS.BusinessCard.discipline] !==
          selectedDisciplines ||
        props[EDIT_CARD_PROPS.BusinessCard.pte] !== selectedPTE ||
        props[EDIT_CARD_PROPS.BusinessCard.sme] !== selectedSME)
    ) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const updateFunctionResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.function,
              selectedFunction ? selectedFunction : ""
            )
          );

          const updateFunction1Response: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.function1,
              selectedFunction1 ? selectedFunction1 : ""
            )
          );

          const updateFunction2Response: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.function2,
              selectedFunction2 ? selectedFunction2 : ""
            )
          );
          const updateFunction3Response: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.function3,
              selectedFunction2 ? selectedFunction3 : ""
            )
          );

          const updateDisciplineResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.multiple,
            updateProperties(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.discipline,
              selectedDisciplines && selectedDisciplines.length
                ? selectedDisciplines.filter(
                    (discipline) => disciplineOptions.indexOf(discipline) !== -1
                  )
                : []
            )
          );

          const updatePTEResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.multiple,
            updateProperties(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.pte,
              selectedPTE && selectedPTE.length
                ? selectedPTE.filter((pte) => pteOptions.indexOf(pte) !== -1)
                : []
            )
          );

          const updateSMEResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.multiple,
            updateProperties(
              currentUser.LoginName,
              EDIT_CARD_PROPS.BusinessCard.sme,
              selectedSME && selectedSME.length
                ? selectedSME.filter((sme) => smeOptions.indexOf(sme) !== -1)
                : []
            )
          );

          if (
            updateFunctionResponse.statusCode === 200 &&
            updateFunction1Response.statusCode === 200 &&
            updateFunction2Response.statusCode === 200 &&
            updateFunction3Response.statusCode === 200 &&
            updateDisciplineResponse.statusCode === 200 &&
            updatePTEResponse.statusCode === 200 &&
            updateSMEResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated=true;
            setLoading(false);
            setShow(false);
            onUpdate({
              ...props,
              [EDIT_CARD_PROPS.BusinessCard.function]: selectedFunction,
              [EDIT_CARD_PROPS.BusinessCard.function1]: selectedFunction1 || "",
              [EDIT_CARD_PROPS.BusinessCard.function2]: selectedFunction2 || "",
              [EDIT_CARD_PROPS.BusinessCard.function3]: selectedFunction3 || "",
              [EDIT_CARD_PROPS.BusinessCard.discipline]: selectedDisciplines
                ? selectedDisciplines.join("|")
                : "",
              [EDIT_CARD_PROPS.BusinessCard.pte]: selectedPTE
                ? selectedPTE.join("|")
                : "",
              [EDIT_CARD_PROPS.BusinessCard.sme]: selectedSME
                ? selectedSME.join("|")
                : "",
            });
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setSelectedFunction(props[EDIT_CARD_PROPS.BusinessCard.function]);
            setSelectedFunction1(
              props[EDIT_CARD_PROPS.BusinessCard.function1] || ""
            );
            setSelectedFunction2(
              props[EDIT_CARD_PROPS.BusinessCard.function2] || ""
            );
            setSelectedFunction3(
              props[EDIT_CARD_PROPS.BusinessCard.function3] || ""
            );
            setSelectedDisciplines(
              props[EDIT_CARD_PROPS.BusinessCard.discipline]
                ? props[EDIT_CARD_PROPS.BusinessCard.discipline].split("|")
                : ""
            );
            setSelectedPTE(
              props[EDIT_CARD_PROPS.BusinessCard.pte]
                ? props[EDIT_CARD_PROPS.BusinessCard.pte].split("|")
                : ""
            );
            setSelectedSME(
              props[EDIT_CARD_PROPS.BusinessCard.sme]
                ? props[EDIT_CARD_PROPS.BusinessCard.sme].split("|")
                : ""
            );
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          debugger;
          onUpdate({
            ...props,
            [EDIT_CARD_PROPS.BusinessCard.function]: selectedFunction,
            [EDIT_CARD_PROPS.BusinessCard.function1]: selectedFunction1 || "",
            [EDIT_CARD_PROPS.BusinessCard.function2]: selectedFunction2 || "",
            [EDIT_CARD_PROPS.BusinessCard.function3]: selectedFunction3 || "",
            [EDIT_CARD_PROPS.BusinessCard.discipline]: selectedDisciplines
              ? selectedDisciplines.join("|")
              : "",
            [EDIT_CARD_PROPS.BusinessCard.pte]: selectedPTE
              ? selectedPTE.join("|")
              : "",
            [EDIT_CARD_PROPS.BusinessCard.sme]: selectedSME
              ? selectedSME.join("|")
              : "",
          });
          setLoading(false);
          setShow(false);
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setSelectedFunction(props[EDIT_CARD_PROPS.BusinessCard.function]);
        setSelectedFunction1(
          props[EDIT_CARD_PROPS.BusinessCard.function1] || null
        );
        setSelectedFunction2(
          props[EDIT_CARD_PROPS.BusinessCard.function2] || null
        );
        setSelectedFunction3(
          props[EDIT_CARD_PROPS.BusinessCard.function3] || null
        );
        setSelectedDisciplines(
          props[EDIT_CARD_PROPS.BusinessCard.discipline]
            ? props[EDIT_CARD_PROPS.BusinessCard.discipline].split("|")
            : []
        );
        setSelectedPTE(
          props[EDIT_CARD_PROPS.BusinessCard.pte]
            ? props[EDIT_CARD_PROPS.BusinessCard.pte].split("|")
            : []
        );
        setSelectedSME(
          props[EDIT_CARD_PROPS.BusinessCard.sme]
            ? props[EDIT_CARD_PROPS.BusinessCard.sme].split("|")
            : []
        );
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else setShow(false);
  }, [
    selectedFunction,
    selectedFunction1,
    selectedFunction2,
    selectedFunction3,
    selectedDisciplines,
    selectedPTE,
    selectedSME,
  ]);

  const cancelHandler = () => {
    setShow(false);
    setSelectedFunction(props[EDIT_CARD_PROPS.BusinessCard.function]);
    setSelectedFunction1(props[EDIT_CARD_PROPS.BusinessCard.function1] || null);
    setSelectedFunction2(props[EDIT_CARD_PROPS.BusinessCard.function2] || null);
    setSelectedFunction3(props[EDIT_CARD_PROPS.BusinessCard.function3] || null);
    setSelectedDisciplines(
      props[EDIT_CARD_PROPS.BusinessCard.discipline]
        ? props[EDIT_CARD_PROPS.BusinessCard.discipline].split("|")
        : []
    );
    setSelectedPTE(
      props[EDIT_CARD_PROPS.BusinessCard.pte]
        ? props[EDIT_CARD_PROPS.BusinessCard.pte].split("|")
        : []
    );
    setSelectedSME(
      props[EDIT_CARD_PROPS.BusinessCard.sme]
        ? props[EDIT_CARD_PROPS.BusinessCard.sme].split("|")
        : []
    );
  };

  return (
    <EditCard
      header={
        props[EDIT_CARD_PROPS.BusinessCard.function]
          ? `Edit ${header}`
          : `Add ${header}`
      }
      show
      loading={loading}
      update={updateExpertArea}
      onHide={cancelHandler}
      body={main}
    />
  );
};

export default EditExpertArea;
