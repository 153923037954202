import * as React from "react";

/** Context */
import { Context } from "../../contexts/Context";

import ViewCard from "../../components/ViewCard/ViewCard";
import SearchRedirect from '../../components/SearchRedirect';
/** Presentation */
import "../../components/ViewCard/ViewCard.scss";

/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";

/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";

/** Constants */
import { BUSINESS_INFO } from "../../utils/Consts";

/** Utils */
import helper from "../../utils/Helper";

const BusinessInfo: React.FC<ICardComponentProps> = ({ tooltip }) => {
  const { state } = React.useContext(Context);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(BUSINESS_INFO, userProps);
  const [businessInformation, setBusinessInformation] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, [userProps]);

  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        setBusinessInformation(props);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
    //  finally {
    //   setLoading(false);
    // }
  };

  const main: JSX.Element = (
    <>
      {businessInformation[BUSINESS_INFO[0]] &&
        businessInformation[BUSINESS_INFO[0]].length > 0 && (
          <>
            <div className="subheaderNoPadding">Business</div>
            <SearchRedirect
              index={1}
              searchKey={'Business'}
              keyword={businessInformation[BUSINESS_INFO[0]]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={true}
            />
          </>
        )}

      {businessInformation[BUSINESS_INFO[1]] &&
        businessInformation[BUSINESS_INFO[1]].length > 0 && (
          <>
            <div className="subheader">Line of Business</div>
            <SearchRedirect
              index={1}
              searchKey={'LineofBusiness'}
              keyword={businessInformation[BUSINESS_INFO[1]]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={false}
            />
            {businessInformation[BUSINESS_INFO[2]] &&
              businessInformation[BUSINESS_INFO[2]].length > 0 && (
                <SearchRedirect
                  index={1}
                  searchKey={'LOB'}
                  keyword={businessInformation[BUSINESS_INFO[2]]}
                  redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                  isTagSingle={false}
                />
              )}
          </>
        )}

      {businessInformation[BUSINESS_INFO[3]] &&
        businessInformation[BUSINESS_INFO[3]].length > 0 && (
          <>
            <div className="subheader">Department</div>
            <SearchRedirect
              index={1}
              searchKey={'BusinessDepartment'}
              keyword={businessInformation[BUSINESS_INFO[3]]}
              redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
              isTagSingle={true}
            />
          </>
        )}
    </>
  );

 // return Object.values(businessInformation).length > 0 ? (
    return businessInformation[BUSINESS_INFO[0]] || businessInformation[BUSINESS_INFO[1]] ||businessInformation[BUSINESS_INFO[2]]||businessInformation[BUSINESS_INFO[3]] ? (
    <ViewCard
      header="Business Information"
      toolTip={tooltip}
      body={main}
      loading={loading}
    />
  ) : null;
};

export default BusinessInfo;
