export const theme = {
	typo: {
		text: {
			heading: 'ShellMedium',
			list: 'ShellBook',
			paragraph: 'Arial Regular',
		},
		numbers: {
			heading: 'Roboto',
		},
		size: {
			xxs: '14px',
			xs: '16px',
			sm: '18px',
			md: '20px',
			lg: '26px',
			xl: '28px',
			xxl: '30px',
		},
		lineHeight: {
			list: '39.6px'
		}
	},
	color: {
		primaryDark: '#0099BA',
		primaryMid: '#66C2D6',
		primaryLight: '#99D6E3',
		primaryExtraLight: '#CCEBF1',
		darkGrey: '#58595B',
		MidGrey: '#808285',
		lightGrey: '#A7A9AC',
		extraLightGrey: '#DDDDDD',
		error: '#E58271',
		link: '#007FFF',
		background: '#F4F4F4',
	},
	pad: {
		sm: '0.25rem',
		md: '0.5rem',
		lg: '1rem',
		xl: '2rem',
	},
	radius: {
		sm: '0.25rem',
		md: '0.5rem',
		lg: '1rem',
		xl: '2rem',
	},
	width: {
		sm: '50%',
		md: '75%',
		lg: '100%',
	},
	shadow: {
		lg:
			'0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
	},
}
