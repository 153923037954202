import React from "react";

import "./AddSection.scss";

/** Section Context */
import { Context } from "../../contexts/Context";

/**constants */

import {
  UpdateProperty,
} from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,

  updateProperty,

} from "../../utils/Helper";

export const Percentage: React.FC<{}> = () => {
  const { state,edit } = React.useContext(Context);
  const { currentUser, showError } = state

  React.useEffect(() => {

    let active = true;
    state.percentage = 100;

    if (state.sectionProps.length) {
      if (state.sectionProps.find((section) => section.title === "Job History")) {
        state.percentage = state.percentage - 10;
      }
      if (state.sectionProps.find((section) => section.title === "Skills")) {
        state.percentage = state.percentage - 15;
      }
      if (state.sectionProps.find((section) => section.title === "Education Details")) {
        state.percentage = state.percentage - 5;
      }
      if (state.sectionProps.find((section) => section.title === "About Me")) {
        state.percentage = state.percentage - 10;
      }
      if (state.sectionProps.find((section) => section.title === "Ask Me About")) {
        state.percentage = state.percentage - 15;
      }
      // if(state.sectionProps.find((section) => section.title === "Accreditation & Other Certification")){
      //   state.percentage= state.percentage-5;
      // }
      // if(state.sectionProps.find((section) => section.title === "Industry Body & Professional Membership")){
      //   state.percentage= state.percentage-5;
      // }
      if (state.sectionProps.find((section) => section.title === "Interests")) {
        state.percentage = state.percentage - 5;
      }
      if (state.contactPercentage[1].value === 0) {
        state.percentage = state.percentage - 5;
      }
      if (state.sectionProps.find((section) => section.title === "Current Job")) {
        state.percentage = state.percentage - 10;
      }
      if (state.sectionProps.find((section) => section.title === "Expertise Areas")) {
        state.percentage = state.percentage - 20;
      }
      if (state.sectionProps.find((section) => section.title === "Language Spoken")) {
        state.percentage = state.percentage - 5;
      }
    }


    const updatePercentage = async () => {
      setTimeout(async () => {
        try {
          if (window.location.href.toLowerCase().indexOf("localhost") === -1 && active) {
            const profilePercentageResponse: SP.ResponseInfo = await updateProfile(
              helpers.appweburl + UpdateProperty.single,
              updateProperty(
                currentUser.LoginName,
                "EF-ProfileCompletion",
                String(state.percentage)
    
              )
            );
    
            if (profilePercentageResponse.statusCode == 200) {
              console.log("success");
            } else {
              console.log("fail");
            }
          }
        }
    
        catch (err) {
          showError(err.message);
    
        }
      }, Math.round(Math.random() * 12000));
    }
  
    if(edit)
    {
     
      updatePercentage();

      return () => {
        active = false;
      };
     
    }

  }, [state.sectionProps, state.contactPercentage,state.percentage]);

 

 
  return (
    <div></div>)
};