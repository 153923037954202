import * as React from "react";
/** Context */
import { Context } from "../../contexts/Context";
import "./Education.scss";
/** Types & Interfaces */
import { ICardComponentProps } from "../../custom-types";
/** Constants */
import { EDUCATION } from "../../utils/Consts";
/** Utils */
import helper from "../../utils/Helper";
/** Custom hooks */
import useCardHandler from "../../utils/hooks/CardHandler";
import Card from "react-bootstrap/Card";
import { ToolTip } from "../../components/Tooltip";
import { Plus, PencilFill } from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Skeleton from "react-loading-skeleton";

import SearchRedirect from '../../components/SearchRedirect';
import AddEducation from './AddEducationt'
import EditEducation from "./EditEducation";
import { ReactComponent as Yourssvg } from "../../assets/images/percentage.svg";

export const Educations: React.FC<ICardComponentProps> = ({
  header,
  tooltip,
  edit,
}) => {
  const { state, dispatch } = React.useContext(Context);
  const [loading, setLoading] = React.useState(true);
  const [showModalAdd, setShowModalAdd] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = React.useState(false);
  const { userProps, showError } = state;
  const { getCardProps } = useCardHandler(EDUCATION, userProps);
  const [educations, setEducations] = React.useState([]);
  const [educationToAdd, setEducationToAdd] = React.useState([]);
  const [educationToEdit, setEducationToEdit] = React.useState([]);
  const [index, setIndex] = React.useState<number>();
  React.useEffect(() => {
    onLoad();
  }, [userProps]);
  React.useEffect(() => updateSection(), [educations]);
  const onLoad = async () => {
    try {
      if (helper.IsObj(userProps)) {
        const props = await getCardProps();
        const educationData = [];
        const blankEducationData = [];
        const propCount = 3;
        for (let i = 1; i <= EDUCATION.length / propCount; i++) {
          const temp: any = {};
          const blankEdu: any = {};
          if (
            props[`EF-ED-Degree${i}`] === "" &&
            props[`EF-ED-SchoolName${i}`] === "" &&
            props[`EF-ED-Country${i}`] === ""
          ) {
            blankEdu.id = i;
            blankEdu.degree = props[`EF-ED-Degree${i}`];
            blankEdu.school = props[`EF-ED-SchoolName${i}`];
            blankEdu.country = props[`EF-ED-Country${i}`];
            blankEducationData.push(blankEdu);
          } else {
            temp.id = i;
            temp.degree = props[`EF-ED-Degree${i}`];
            temp.school = props[`EF-ED-SchoolName${i}`];
            temp.country = props[`EF-ED-Country${i}`];
            educationData.push(temp);
          }
        }
        setEducations(educationData);
        setEducationToAdd(blankEducationData);
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };

  const updateSection = () => {
    !educations.length &&
      !state.sectionProps.find((section) => section.title === header)
      ? dispatch({
        type: "ADD_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: true,
          handler: () => setShowModalAdd(!showModalAdd),
        },
      })
      : educations.length &&
      state.sectionProps.find((section) => section.title === header) &&
      dispatch({
        type: "REMOVE_SECTION",
        payload: {
          title: `${header}`,
          showPercentage: false,
        },
      });
  };

  const addEducation = React.useCallback(
    (val) => {
      setEducations([...educations, val]);
      setEducationToAdd(educationToAdd.filter((education) => education.id !== val.id));
    },
    [educations, educationToAdd]
  );
  const handleDelete = React.useCallback(
    (val) => {
      educations[index] = val;
      setEducations(educations.filter((education) => education.id !== val.id));
      setEducationToAdd([...educationToAdd, val]);
    },
    [educations, educationToAdd]
  );
  const handleUpdate = React.useCallback(
    (val) => {
      educations[index] = val;
      setEducations(educations);
      setEducationToEdit(val);
    },
    [educations, educationToEdit]
  );
  return (
    showModalAdd ? (
      <AddEducation
        header={"Add Education"}
        props={educationToAdd.find((noEducation) =>
          educations.filter((education) => education.id !== noEducation.id)
        )}
        show={showModalAdd}
        setShow={setShowModalAdd}
        onUpdate={addEducation}
      />
    ) : showModalEdit ? (
      <EditEducation
        header={"Education"}
        index={index}
        props={educationToEdit}
        show={showModalEdit}
        setShow={setShowModalEdit}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    ) : loading ? (
      <Container className="loading-container">
        <Skeleton count={5} />
      </Container>
    ) : educations.length > 0 ? (
      <Card.Body>
        <Card.Title className="header">
          <div>
            {header}
            {edit && <Yourssvg />}
            {tooltip && <ToolTip tooltipMessage={tooltip} />}
          </div>
        </Card.Title>
        {edit && educations.length < 5 && (
          <Plus
            className="edit icon-add"
            onClick={() => setShowModalAdd(!showModalAdd)}
          />
        )}
        {educations.map((education, index) =>
          (education.degree || education.school || education.country) && (
            <div>
              <div className="education">
                <div className="educationCard">
                  <div className="educationCol1">Education {index + 1}</div>
                  <div className="educationCol2">
                    <div className="job-actions-container">
                      {edit && <PencilFill
                        className="icon-default"
                        onClick={() => { setShowModalEdit(!showModalEdit); setIndex(index); setEducationToEdit(education) }}
                      />
                      }&nbsp;
                    </div>
                  </div>
                </div>
                <div style={{ paddingBottom: "15px", fontSize: "14px" }}>
                  {education.degree && (
                    <div style={{ paddingBottom: "10px" }}>
                      <SearchRedirect
                        index={index + 1}
                        searchKey={'EducationDegree'}
                        keyword={education.degree}
                        redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                        isTagSingle={false}
                      />
                    </div>
                  )}
                  {education.school && (
                    <div style={{ paddingBottom: "10px" }}>
                      <SearchRedirect
                        index={index + 1}
                        searchKey={'EducationSchoolName'}
                        keyword={education.school}
                        redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                        isTagSingle={false}
                      />
                    </div>
                  )}
                  {education.country && (
                    <div style={{ paddingBottom: "10px" }}>
                      <SearchRedirect
                        index={index + 1}
                        searchKey={'EducationCountry'}
                        keyword={education.country}
                        redirectUrl={process.env.REACT_APP_SEARCH_API_ENDPOINT}
                        isTagSingle={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </Card.Body>
    ) : null
  );
}
export default Educations;