import React from "react";
import "./_main.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Context from "./contexts/Context";
import RootContainer from "./containers/RootContainer";

function App() {
  return (
    <Router>
      <Context>
        <RootContainer />
      </Context>
    </Router>
  );
}
export default App;
