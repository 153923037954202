import * as React from "react";

/** Presentation */
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import DeleteEducation from "./../containers/Education/DeleteEducation"
import {Context} from "../contexts/Context"

export const EditCard = (props) => {
  const { header, loading, onHide, update, body, onDelete, showDelete, onClear, showClear,showLarge,newTermRequest } = props;
  const [showEnsureDialog, setShowEnsureDialog] = React.useState(false);
  const [showLargePopup,setshowLargePopup] = React.useState(!showLarge?"lg":"xl");
  const { state } = React.useContext(Context);
 
  state.isInvalidTerm = false;

  return (<div>
    <Modal     
      {...props}
      size={showLargePopup}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
      animation={true}
      backdrop="static"           
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontFamily: "ShellBook",
            fontSize: "medium",
            color: "#1C1C1C",
            marginBottom:"-5px"
          }}
        >
        <h1 style={{fontSize:"16px", lineHeight:"1.2", marginTop:"2px"}}>{header}</h1>
        </Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
          e.preventDefault();
          update();
        }}
      >
        <Modal.Body style={{padding:"15px 10px 0 10px", fontFamily: "ShellBook"}}>
          {loading ? (
            <div className="p-5 text-center">
              <Spinner animation="border" variant="info" />
            </div>
          ) : (
            body
          )}
        </Modal.Body>
        <Form.Group style={{display: "flex",justifyContent: "flex-end"}}>
          <div style={{width:"50%", textAlign:"left", paddingLeft:"2.8%"}}>
             {(showClear && !loading) &&
              <a onClick={onClear} className="feedbackLink" style={{color:"#D93638"}}>
                Clear All
              </a>
            }
            {(showDelete && !loading) &&
            <a className="feedbackLink" onClick={() => setShowEnsureDialog(!showEnsureDialog)}>
              Delete
            </a>
          }
          </div>
          <div style={{width:"50%", textAlign:"right"}}>
             <Button
              style={{fontWeight:"bold",fontFamily: "Arial",fontSize:"16px",marginRight: "1rem", width: "7vw", padding:"2px"}}
              variant="outline-secondary"
              disabled={loading}
              size="sm"
              onClick={onHide}
              >
              Cancel
            </Button>   
            {   !newTermRequest &&
            <Button
              style={{fontWeight:"bold",fontFamily: "Arial",fontSize:"16px", marginRight: "1.5rem", width: "7vw", padding:"2px"}}
              variant="info"
              size="sm"
              disabled={loading}
              onClick={update}
            >
              Save
            </Button> 
}
{   newTermRequest &&
            <Button
              style={{fontWeight:"bold",fontFamily: "Arial",fontSize:"16px", marginRight: "1.5rem",  padding:"2px"}}
              variant="info"
              size="sm"
              disabled={loading}
              onClick={update}
            >
              Save and Request New Terms
            </Button>   
}         
          </div>
        </Form.Group>
      </Form>
    </Modal>
    {showEnsureDialog &&
      <DeleteEducation
        props={props}
        show={showEnsureDialog}
        setShow={setShowEnsureDialog}
        onUpdate={onDelete}
      />
    }
  </div>
  );
};

export default EditCard;
